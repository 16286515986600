<template>
  <el-row>
    <main-header></main-header>
    <el-col :span="4" :lg="4">
      <back-menu></back-menu>
    </el-col>
    <el-col :span="20" :lg="20">
      <router-view></router-view>
    </el-col>
  </el-row>
</template>

<script>
import BackMenu from '../../components/back-menu.vue'
import MainHeader from '../../components/main-header.vue'

export default {
  components: { BackMenu , MainHeader},
  data () {
    return {
    }
  }
}
</script>

<style>
</style>