<template>
  <div class="back-menu">
    <el-menu
      :default-active="currRoute"
      class="el-menu-vertical-demo border"
      @open="handleOpen"
      @close="handleClose"
      router
      @select="handleSelect">
      <el-menu-item index="update" route="/cid/update">
        <span slot="title">CID情報取得・更新</span>
      </el-menu-item>
      <el-menu-item index="init" route="/cid/init">
        <span slot="title">CID初期化</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
import Vue from "vue"

Vue.prototype.$BACK_MENU_COMPONENT_NAME = 'BackMenu'

export default {
  name: 'BackMenu',
  data() {
    return {
      currRoute: "update",
    }
  },
  mounted() {
    if (this.$route.path.includes("init")){
      this.currRoute = "init"
    }
    if (this.$route.path.includes("update")){
      this.currRoute = "update"
    }
  },
  methods: {
    handleOpen() {
      // console.log(key, keyPath)
    },
    handleClose() {
      // console.log(key, keyPath)
    },
    handleSelect(key) {
      this.currRoute = key
    }
  }
}
</script>
<style lang="scss" scoped>
.back-menu {
  height: 100%;
  padding-top: 10px;
  font-size: 14px;
  background: rgba(255, 255, 255, 1);
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  height: 100%;
}
.border {
  border: 1px solid #eee;
}
.el-menu-item {
  border-bottom: 1px solid #eee;
  display: flex;
  align-items: center;
  padding-right: 20px!important;
}
.el-menu-item span {
  white-space: normal;
  word-break: break-all;
  line-height: 20px;
  flex: 1;
}
.el-menu-item.is-active {
  background-color: #CAE1FF !important;
  color: #fff;
  span {
    color: #1E90FF !important;
  }
}
</style>