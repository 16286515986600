export const messages = {
  // --------------------------------prodツール--------------------------------
  // gitlab api error
  API_CONF_FILE_NOT_EXIST_ERROR: '404 File Not Found',
  API_NO_ACCESS_ERROR: 'Network Error',
  API_ACCESS_TOKEN_UNAUTH_ERROR: '401 Unauthorized',
  API_ACCESS_TOKEN_INVALID_ERROR: 'invalid_token',
  API_BRANCH_NOT_EXIST_ERROR: 'You can only create or edit files when you are on a branch',
  API_FILE_NOT_EXIST_ERROR: 'A file with this name doesn\'t exist',

  // gitlab repositoryアクセス失敗場合
  REPOSITORY_ACCESS_ERROR: 'repositoryへのアクセスに失敗しました。確認して再実行して下さい。',
  CONF_FILE_NOT_EXIST_ERROR: '[0]設定ファイルが存在しません。確認して再実行して下さい。',
  BRANCH_NOT_EXIST_ERROR: 'repositoryに[0]*ブランチがありません。[0]*ブランチを作成して再実行して下さい。',
  CONF_FILE_ACCESS_ERROR: '設定ファイル取得失敗。原因: [0]。',

  // チェック
  // JSON形式チェック
  INVALID_JSON_CHECK_ERROR: '[0]ファイルは有効なjsonファイルではないです。設定ファイルを確認してください。',
  // ver重複チェック
  DOUBLE_CHECK_ERROR: '[0]配下に重複となっているverが存在します。 Ver番号：[1]',
  // ver順番チェック
  ORDER_CHECK_ERROR: '[0]配下のver順番は昇順になっていないです。Ver番号：[1]',
  // オブジェクト存在チェック
  OBJECTS_NO_EXISTS_ERROR: '「[0]」オブジェクトが存在しません。',
  // json構造チェック(maps)
  MAPS_ITEMS_MISSING_ERROR: 'maps配下エントリの構造が正しくないです。Ver番号：[1]　[0]が存在しません。',
  // json構造チェック(test_entry、diffs)
  TEST_ENTRY_AND_DIFFS_ITEMS_MISSING_ERROR: '[0]配下エントリの構造が正しくないです。Ver番号：[2]　[1]が存在しません。',
  // json構造チェック(maps)
  MAPS_ITEMS_EXTRA_ERROR: 'maps配下エントリの構造が正しくないです。Ver番号：[1]　[0]が余計な項目です。',
  // json構造チェック(test_entry、diffs)
  TEST_ENTRY_AND_DIFFS_ITEMS_EXTRA_ERROR: '[0]配下エントリの構造が正しくないです。Ver番号：[2]　[1]が余計な項目です。',
  // 各値チェック（maps）
  MAPS_ITEM_VALUES_WRONG_ERROR: 'maps配下エントリの値が正しくないです。Ver番号：[1]　エラー項目：[0]',
  // 各値チェック(test_entry、diffs)
  TEST_ENTRY_AND_DIFFS_ITEM_VALUES_WRONG_ERROR: '[3]配下エントリの値が正しくないです。Ver番号：[1]　エラー項目：[0]',
  // リリース対象が入っていない場合に備えたチェック（maps、test_entry）
  NO_COPY_DATA_ERROR: '[0]配下にcopy対象データが存在しません。',

  // 警告情報
  SIZE_OVER_WARNING_MESSAGE: 'pp confファイルに下記エントリのsize値が30000000を超えています。\n値に間違いがないか、ご確認のうえcopyボタンを押下してください。',
  DIFFERENCE_WARNING_MESSAGE: '下記verのエントリはpp confとprod confの両方に存在しますが、エントリの内容が異なっています。\nエントリに間違いがないか、ご確認のうえcopyボタンを押下してください。',

  // チェックがエラーない場合
  CHECK_JSON_STATUS_SUCCESS: '[0]ファイルチェックがOKです。',

  // ブランチが選択されていません
  BRANCH_SELECTION_STATUS_INVILID: 'ブランチ名を選択してください。',

  // PUSH関連
  PUSH_PROD_CONF_SUCCESS: '対象ブランチへのpushが成功しました。',
  PUSH_API_NO_ACCESS_ERROR: 'GitLabのapiにアクセス出来ません。確認して再度お試しください。',
  PUSH_API_ACCESS_TOKEN_INVALID_ERROR: 'アクセストークンが失効しました。システム管理者にお問い合わせください。',
  PUSH_API_BRANCH_NOT_EXIST_ERROR: '対象ブランチが存在しません。対象ブランチを確認してください。',
  PUSH_API_FILE_NOT_EXIST_ERROR: 'pushファイルが対象ブランチ上に存在しません。設定ファイルを確認してください。',
  PUSH_API_OTHER_ERROR: '対象ブランチへのpushが失敗しました。原因: [0]。',
  PUSH_COMMIT_MESSAGE_LENGTH_ERROR: '1~[0]桁の文字を入力してください。',
  // --------------------------------prodツール--------------------------------


  // --------------------------------ppツール----------------------------------
  DIFFS_NO_UNIQ_ERROR: 'diffs配下のデータ件数が1件のみにしてください。',
  // 警告情報
  SIZE_OVER_WARNING_MESSAGE_UPDATE: 'pp confファイルに下記エントリのsize値が[0]を超えています。\n値に間違いがないか、ご確認のうえ更新ボタンを押下してください。',
  // 更新対象チェック（test_entry）
  NO_UPDATE_DATA_ERROR: '[0]配下に更新対象データが存在しません。',
  // --------------------------------ppツール----------------------------------


  // --------------------------------CIDツール---------------------------------
  CID_SEARCH_NO_DATA_ERROR: '該当データが存在しません。対象CIDを確認してください。',
  CID_VALUE_BLANK_ERROR: 'CIDを入力してください。',
  CID_VALUE_LENGTH_OVER_ERROR: 'CIDは128文字以内に入力してください。',
  UPDATE_FOR_CONFIRM: '更新します。よろしいですか？',
  INIT_FOR_CONFIRM: '初期化します。よろしいですか？',
  INIT_STATUS_CANCEL: '初期化がキャンセルされました。',
  UPDATE_STATUS_CANCEL: '更新がキャンセルされました。',
  UPDATE_DB_SUCCESS: '更新処理が成功しました。(table: [0])',
  STRING_IS_NOT_BASE64_ERROR: '入力されたエンコード済が不正です。',
  STRING_IS_NOT_HEX_ERROR: 'CIDをバイナリ形式で入力してください。',
  CLEAR_FOR_CONFIRM: 'クリアします。よろしいでしょうか？',
  CLEAR_STATUS_SUCCESS: 'クリアが成功しました。',
  CLEAR_STATUS_CANCEL: 'クリアがキャンセルされました。',
  DELETE_CID_SUCCESS:'初期化処理が成功しました。(table: [0])',
  // 各値チェック
  CID_ITEM_VALUES_WRONG_ERROR: '[0]配下項目の値が正しくないです。エラー項目：[1]',
  CID_S3_CONFIG_ERROR: 'configurations.jsonファイルが存在しない、またはCS_suppressterm項目が存在しません。'
  // --------------------------------CIDツール---------------------------------
}