<template>
  <div>
    <div id="files-select">
      <!-- repository -->
      <div>
        <div class="label-branch-box">
          <p class="label-branch">repository</p>
        </div>
        <el-input
          id="repository-path"
          v-model="repository.name"
          class="input-repository-path"
          style="display:inline"
          readonly>
        </el-input>
      </div>

      <!-- ブランチ -->
      <div>
        <div class="label-branch-box">
          <p class="label-branch">branch</p>
        </div>
        <el-input
          id="branch-name"
          v-model="branch.name"
          class="input-branch"
          style="display:inline"
          readonly>
        </el-input>
      </div>

      <!-- pp confファイル -->
      <div>
        <div class="label-conf-box">
          <p class="label-branch">prod conf</p>
        </div>
        <el-input
          id="prod-file-path"
          v-model="prodFile.name"
          class="input-prod-path"
          style="display:inline"
          readonly>
        </el-input>
      </div>
    </div>

    <!-- コミット内容入力画面 -->
    <FilePush
      :visible="prodConfPush.visible"
      @pushCallback="pushCallback"
      @cancelCallback="cancelCallback"
      v-if="prodConfPush.visible"
      :branches="prodConfPush.branches">
    </FilePush>

    <!-- 更新前/更新後 -->
    <el-row>
      <el-col :span="11" :lg="12" type="flex" align="left">
        <div>
           <label class="label-update">更新前</label>
        </div>
      </el-col>
      <el-col :span="13" :lg="12" type="flex" align="left">
        <div>
          <label style="margin: 0px -20px" class="label-update">更新後</label>
        </div>
      </el-col>
    </el-row>

    <!-- テキストエディター -->
    <div>
      <monaco
        ref="monaco"
        :opts="opts"
        :isDiff="isDiff"
        :height="68"
        :width="100"
      ></monaco>
    </div>

    <el-row :gutter="15">
      <!-- 戻るボタン -->
      <el-col :span="12" :lg="12" type="flex" align="right">
        <div>
          <el-button
            style="margin: 10px 20px"
            type="warning"
            size="small"
            @click="back"
            >戻る<i class="el-icon-back el-icon--right"></i></el-button>
        </div>
      </el-col>

      <!-- ダウンロードボタン -->
      <el-col :span="12" :lg="12" type="flex" align="left">
        <div>
          <el-button
            style="margin: 10px 0px"
            type="primary"
            size="small"
            @click="push"
            :disabled="btnPushDisable"
            >push<i class="el-icon-upload2 el-icon--right"></i></el-button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Vue from "vue"
import axios from 'axios'
import monaco from '@/components/MonacoDiffEditor'
import { constants } from '@/common/constants'
import { messages } from '@/common/messages'
import FilePush from '@/components/FilePush.vue'

Vue.prototype.$PROD_CONFIRM_COMPONENT_NAME = 'ProdConfirm'

export default {
  components: { monaco, FilePush },
  data () {
    return {
      // monacoEditor配置項
      opts: {
        value: '',
        readOnly: false,
        language: 'json',
        theme: 'vs-dark',
        automaticLayout: true
      },
      isDiff: true,
      oldValue: '',
      newValue: '',

      // pushボタンが活性
      btnPushDisable: false,

      // POP画面を表示フラグ
      prodConfPush: {
        visible: false,
        branches: []
      },

      // repository URL
      repository: {'name': constants.REPOSITORY_URL},
      // ブランチ名
      branch: {'name': ''},
      // prod conf
      prodFile: {'name': ''},

      // push apiデータ
      query: {
        branch: this.$route.params.branchName,
        commit_message: '',
        actions: [{
          action: 'update',
          file_path: this.$route.params.prodFilePath,
          content: this.$route.params.rightEditorNewValue
        }],
      },

      // CSS
      float: 'float: none'
    }
  },
  mounted () {
    // branch名
    this.branch.name = this.$route.params.branchName

    // prod confファイル名
    this.prodFile.name = this.$route.params.prodFilePath

    // pushボタン状態
    this.btnPushDisable = this.$route.params.btnPushDisable
  },
  methods: {
    // キャンセルをボタン押した後コールバック方法
    cancelCallback() {
      // push用POP画面を閉じる
      this.prodConfPush.visible = false
    },
    // コミット内容を入力した後コールバック方法
    pushCallback(commitMessage) {
      // コミット内容
      this.query.commit_message = commitMessage

      // push api
      const pushApiUrl = constants.PUSH_FILE_API_URL.replace('[0]', this.$route.params.projectId)

      // gitlab apiサービスコール
      axios.post(pushApiUrl, this.query, {
        headers: {
          'Content-Type': constants.CONTENT_TYPE,
          'PRIVATE-TOKEN': constants.GITLAB_ACCESS_TOKEN
        }
      })
      .then((response) => {
        Vue.$log.info(response.data)

        // push成功の場合
        this.$alert(messages.PUSH_PROD_CONF_SUCCESS, '提示', {
          confirmButtonText: 'OK',
          type: 'success'
        }).then(() => {
          // push用POP画面を閉じる
          this.prodConfPush.visible = false

          // pushボタンが非活性になること
          this.btnPushDisable = true
        }).catch(() => {
        })
      })
      .catch((error) => {
      // push失敗の場合

        Vue.$log.info('-----------------push失敗の場合---------------------')
        Vue.$log.info(error.response.data)

        let errorMessage = messages.PUSH_API_OTHER_ERROR.replace('[0]', error.response.data.message)

        // gitlabのapiにアクセスできない
        if (error.response.data.message == messages.API_NO_ACCESS_ERROR) {
          errorMessage = messages.PUSH_API_NO_ACCESS_ERROR
        }

        // アクセストークン失効しました
        if (error.response.data.message == messages.API_ACCESS_TOKEN_UNAUTH_ERROR
          || error.response.data.error == messages.API_ACCESS_TOKEN_INVALID_ERROR) {
          errorMessage = messages.PUSH_API_ACCESS_TOKEN_INVALID_ERROR
        }

        // 対象ブランチが存在しない
        if (error.response.data.message == messages.API_BRANCH_NOT_EXIST_ERROR) {
          errorMessage = messages.PUSH_API_BRANCH_NOT_EXIST_ERROR
        }

        // pushファイルが対象ブランチ上に存在しない
        if (error.response.data.message == messages.API_FILE_NOT_EXIST_ERROR) {
          errorMessage = messages.PUSH_API_FILE_NOT_EXIST_ERROR
        }

        this.$alert(`${errorMessage}`, '提示', {
          confirmButtonText: 'OK',
          dangerouslyUseHTMLString: true,
          type: 'error'
        }).then(() => {
        }).catch(() => {
        })
      })
    },
    // pushボタン押下時
    push() {
      // push用POP画面を表示する
      this.prodConfPush.visible = true
    },
    // 戻るボタンを押下
    back() {
      Vue.$log.info('Confirm.vue')

      // 画面遷移
      this.$router.push({ name: this.$PROD_UPDATE_COMPONENT_NAME, params: {
        leftEditorValue: this.$route.params.leftEditorValue,
        rightEditorNewValue: this.$route.params.rightEditorNewValue,
        rightEditorOldValue: this.$route.params.rightEditorOldValue,
        branchName: this.branch.name,
        ppDecoratorOptions: this.$route.params.ppDecoratorOptions,
        prodDecoratorOptions: this.$route.params.prodDecoratorOptions,
        isShowSizeOver: this.$route.params.isShowSizeOver,
        isShowNotSame: this.$route.params.isShowNotSame,
        sizeOverWarning: this.$route.params.sizeOverWarning,
        sameVerOthersNotSameWarning: this.$route.params.sameVerOthersNotSameWarning,
        btnPushDisable: this.btnPushDisable,
        ppFilePath: this.$route.params.ppFilePath,
        prodFilePath: this.$route.params.prodFilePath} })
    }
  }
}
</script>

<style>
#push {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 10px 0;
}
.label-repository {
  display: inline-block;
  margin-left: 2px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 16px;
  background-color: #ffbf00;
}
.label-branch-confirm {
  display: inline-block;
  margin-left: 2px;
  margin-top: 15px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 16px;
  background-color: #ffbf00;
}
.input-prod-path>.el-input__inner {
  display: inline;
  width: 400px;
  height: 31px;
  margin-left: 5px;
  margin-top: 7px;
  font-size: 16px;
}
</style>