<template>
  <div>
    <div id="files-select">
      <!-- サイズオーバー警告情報と同じverのエントリ差異警告情報が存在すること -->
      <div v-show="isShowSizeOver && isShowNotSame" class="wrapper">
        <div class="label-warning-box">
          <p class="label-branch">【警告】サイズオーバー</p>
        </div>
        <div></div>
        <div class="label-warning-box">
          <p class="label-branch">【警告】同じverのエントリ差異</p>
        </div>
        <el-input type="textarea" v-model="sizeOverWarning" class="sizeover-textarea-style" size="large" :rows="4" disabled readonly></el-input>
        <div></div>
        <el-input type="textarea" v-model="sameVerOthersNotSameWarning" class="difference-textarea-style" size="large" :rows="4" disabled readonly></el-input>
      </div>

      <!-- サイズオーバー警告情報が存在すること、同じverのエントリ差異警告情報が存在しない -->
      <div v-show="isShowSizeOver && !isShowNotSame" class="wrapper">
        <div class="label-warning-box">
          <p class="label-branch">【警告】サイズオーバー</p>
        </div>
        <div></div>
        <div></div>
        <el-input type="textarea" v-model="sizeOverWarning" class="sizeover-textarea-style" size="large" :rows="4" disabled readonly></el-input>
        <div></div>
        <div></div>
      </div>

      <!-- サイズオーバー警告情報が存在しない、同じverのエントリ差異警告情報が存在すること -->
      <div v-show="!isShowSizeOver && isShowNotSame" class="wrapper">
        <div class="label-warning-box">
          <p class="label-branch">【警告】同じverのエントリ差異</p>
        </div>
        <div></div>
        <div></div>
        <el-input type="textarea" v-model="sameVerOthersNotSameWarning" class="difference-textarea-style" size="large" :rows="4" disabled readonly></el-input>
        <div></div>
        <div></div>
      </div>

      <!-- 要素間の空白を増やす -->
      <div class="div-margin-top" v-show="isShowSizeOver || isShowNotSame">
      </div>

      <!-- repository -->
      <div>
        <div class="label-branch-box">
          <p class="label-branch">repository</p>
        </div>
        <el-input
          id="repository-path"
          v-model="repository.name"
          class="input-repository-path"
          style="display:inline"
          readonly>
        </el-input>
      </div>

      <!-- ブランチ -->
      <div>
        <div class="label-branch-box">
          <p class="label-branch">branch</p>
        </div>
        <el-input
          id="branch-name"
          v-model="branch.name"
          class="input-branch"
          style="display:inline"
          readonly>
        </el-input>
        <el-button
            style="margin: 0px 20px"
            size="small"
            type="primary"
            @click="doSelect"
            >select<i class="custom-icon el-icon-folder-opened el-icon--right"></i></el-button>
      </div>

      <!-- ブランチ選択画面 -->
      <SelectPopUp
        :visible="branchSelect.visible"
        @selectedBranch='selectedBranch'
        @closePopUp='closePopUp'
        v-if="branchSelect.visible"
        :branches="branchSelect.branches">
      </SelectPopUp>

      <div class="wrapper">
        <!-- pp confファイル -->
        <div>
          <div class="label-conf-box">
            <p class="label-branch">pp conf</p>
          </div>
          <el-input
            id="pp-file-path"
            v-model="ppFile.name"
            class="input-file-path"
            style="display:inline"
            readonly>
          </el-input>
        </div>

        <!-- 空 -->
        <div></div>

        <!-- prod confファイル -->
        <div>
          <div class="label-conf-box">
            <p class="label-branch">prod conf</p>
          </div>
          <el-input
            id="prod-file-path"
            v-model="prodFile.name"
            class="input-file-path"
            style="display:inline"
            readonly>
          </el-input>
        </div>
      </div>

      <!-- チェックエラー情報 -->
      <el-table :data="allErrorMessages" :show-header="false" empty-text=" " :style="styleType">
        <el-table-column
          prop="leftError"
          min-width="47.3%"
          label="">
        </el-table-column>
         <el-table-column
          prop="noError"
          min-width="5.4%"
          label="">
        </el-table-column>
        <el-table-column
          prop="rightError"
          min-width="47.3%"
          label="">
        </el-table-column>
      </el-table>

    </div>

    <div class="wrapper">
      <!-- 左テキストエディター -->
      <div>
        <LeftMonacoEditor
          ref="LeftMonacoEditor"
          :opts="leftOpts"
          :isDiff="false"
          :height="71"
          :width="100"
          :float="float"
        ></LeftMonacoEditor>
      </div>

      <!-- copyボタン -->
      <div class="btn-copy" style="text-align: center; padding: 280% 0;">
        <el-button
          style="vertical-align: middle;"
          type="success"
          size="small"
          :disabled="btnCopyDisable"
          @click="merge"
          >copy</el-button>
      </div>

      <!-- 右テキストエディター -->
      <div>
        <RightMonacoEditor
          ref="RightMonacoEditor"
          :opts="rightOpts"
          :isDiff="false"
          :height="71"
          :width="100"
          :float="float"
        ></RightMonacoEditor>
      </div>

    </div>

    <!-- 確認画面へボタン -->
    <el-row :gutter="0">
      <el-col :span="24" :lg="24" type="flex" align="center">
        <div>
          <el-button
            style="margin: 10px 0px"
            type="warning"
            size="small"
            :disabled="btnConfirmDisable"
            @click="goToConfirm"
            >確認画面へ</el-button>
        </div>
      </el-col>
    </el-row>
  </div>

</template>

<script>
import Vue from "vue"
import axios from 'axios'
import * as monaco from 'monaco-editor'
import LeftMonacoEditor from '@/components/MonacoNoDiffEditor.vue'
import RightMonacoEditor from '@/components/MonacoNoDiffEditor.vue'
import { constants } from '@/common/constants'
import { messages } from '@/common/messages'
import commonUtil from '@/common/commonUtil'
import SelectPopUp from '@/components/SelectPopUp.vue'

Vue.prototype.$PROD_UPDATE_COMPONENT_NAME = 'ProdUpdate'

export default {
  components: { LeftMonacoEditor, RightMonacoEditor, SelectPopUp },
  data () {
    return {
      // monacoEditor
      decorator: [],
      isDiff: true,
      oldValue: '',
      newValue: '',
      // 左側のmonacoEditor配置項
      leftOpts: {
        value: '',
        readOnly: true,
        language: 'json',
        theme: 'myCustomTheme',
        cursorStyle: 'block',
        contextmenu: false,
        scrollbar:{
          arrowSize: 15,
          horizontal: false,
          useShadows: false,
          vertical: true,
          verticalScrollbarSize: 15
        },
        minimap: {
          enabled: false
        },
        automaticLayout: true
      },
      // 右側のmonacoEditor配置項
      rightOpts: {
        value: '',
        readOnly: true,
        language: 'json',
        theme: 'myCustomTheme',
        cursorStyle: 'block',
        contextmenu: false,
        scrollbar:{
          arrowSize: 15,
          horizontal: false,
          useShadows: false,
          vertical: true,
          verticalScrollbarSize: 15
        },
        minimap: {
          enabled: false
        },
        automaticLayout: true
      },

      // repositoryのid
      projectId: 0,
      // repository URL
      repository: {'name': constants.REPOSITORY_URL},
      // ブランチ名
      branch: {'name': ''},
      // pp conf
      ppFile: {'name': ''},
      // prod conf
      prodFile: {'name': ''},

      // ブランチ選択画面表示のブランチ名
      branchSelect: {
        visible: false,
        branches: []
      },

      // ----------------------error----------------------------
      errorMessagesArray: [],
      allErrorMessages: [],
      seqs: JSON.parse(JSON.stringify(constants.ERROR_MESSAGES_SEQ_FOR_ORDER)),
      stepSize: 0.001,
      // ppファイルまたはprodファイルが存在しない
      fileNoExist: false,
      // ----------------------error----------------------------

      // ppファイルのステータス(commonUtilで使う)
      ppCheckStatus: false,
      // prodファイルのステータス(commonUtilで使う)
      prodCheckStatus: false,

      // ----------------------ハイライト表示----------------------------
      // maps配下のハイライト表示番号
      mapsHighlightedlines: [],
      // test_entry配下のハイライト表示番号
      testEntryHighlightedlines: [],
      // ppファイルのハイライトカラー
      ppDecoratorOptions: [],
      // prodファイルのハイライトカラー
      prodDecoratorOptions: [],
      // ----------------------ハイライト表示----------------------------

      // ----------------------警告情報----------------------------
      // サイズオーバー警告情報
      sizeOverWarning: '',
      // 同じverのエントリ差異警告情報
      sameVerOthersNotSameWarning: '',
      // 同じverのエントリ差異のデータ
      ppDifferenceLines: [],
      // サイズオーバー警告情報表示フラグ
      isShowSizeOver: false,
      // 同じverのエントリ差異警告情報表示フラグ
      isShowNotSame: false,
      // ----------------------警告情報----------------------------

      // ----------------------ボタン----------------------------
      btnCopyDisable: true,
      btnConfirmDisable: true,
      // 確認画面のpushボタン状態初期化: 活性
      btnPushDisable: false,
      styleType: 'display: none',
      float: 'float: left'
      // ----------------------ボタン----------------------------
    }
  },
  created () {
    Vue.$log.info('Update.vue')

    const leftEditorErrors = []
    const rightEditorErrors = []

    // 初期化
    this.allErrorMessages = [{
      leftError: leftEditorErrors.join(constants.LINE_BREAK),
      rightError: rightEditorErrors.join(constants.LINE_BREAK)
    }]
  },
  mounted () {
    Vue.$log.info('Update.vue')

    // commonUtilとデータを共有
    commonUtil.sendThis(this)

    // 更新画面へ戻るときに更新内容を保持する
    if (Object.keys(this.$route.params).length > 0) {
      this.oldValue = this.$route.params.rightEditorOldValue
      this.newValue = this.$route.params.rightEditorNewValue
      this.branch.name = this.$route.params.branchName

      this.$refs.LeftMonacoEditor.monacoEditor.setValue(this.$route.params.leftEditorValue)
      this.$refs.RightMonacoEditor.monacoEditor.setValue(this.newValue)

      // copyボタンが非活性化にする
      this.btnCopyDisable = true
      // 確認画面へボタンが活性化にする
      this.btnConfirmDisable = false
      this.ppCheckStatus = true
      this.prodCheckStatus = true
      // 確認画面のpushボタン状態
      this.btnPushDisable = this.$route.params.btnPushDisable

      // pp confファイルのコピー対象データ行はハイライト表示
      this.decorator = this.$refs.LeftMonacoEditor.monacoEditor.deltaDecorations(this.decorator,
        this.$route.params.ppDecoratorOptions)
      // 更新データ
      this.ppDecoratorOptions = this.$route.params.ppDecoratorOptions
      // prod confに変更されたデータ行はハイライト表示
      this.decorator = this.$refs.RightMonacoEditor.monacoEditor.deltaDecorations(this.decorator,
        this.$route.params.prodDecoratorOptions)
      // 更新データ
      this.prodDecoratorOptions = this.$route.params.prodDecoratorOptions

      // 警告情報
      this.isShowSizeOver = this.$route.params.isShowSizeOver
      this.isShowNotSame = this.$route.params.isShowNotSame
      this.sizeOverWarning = this.$route.params.sizeOverWarning
      this.sameVerOthersNotSameWarning = this.$route.params.sameVerOthersNotSameWarning

      // 選択したファイルの名前を取得する
      this.$set(this.ppFile, 'name', this.$route.params.ppFilePath)
      this.$set(this.prodFile, 'name', this.$route.params.prodFilePath)
    }
  },
  methods: {
    // gitlabリポジトリIDを取得する
    async getRepositoryId() {
      const repositoryArray = constants.REPOSITORY_URL.split('/')

      // リポジトリ名
      const repositoryName = repositoryArray[repositoryArray.length - 1]

      // gitlabリポジトリURL
      const projectApiUrl = constants.PROJECT_API_URL.replace('[0]', repositoryName)

      // gitlab apiサービスコール
      await axios.get(projectApiUrl, {
        headers: {
          'Content-Type': constants.CONTENT_TYPE,
          'PRIVATE-TOKEN': constants.GITLAB_ACCESS_TOKEN
        }
      })
      .then((response) => {
        const projects = response.data

        for (const project of projects) {
          // 使用したリポジトリ名とリポジトリURLで結果内を完全一致に検索する
          if (project.name === repositoryName && project.web_url === constants.REPOSITORY_URL) {
            // リポジトリIDを取得する
            this.projectId = project.id

            return
          }
        }
      })
      .catch((error) => {
        Vue.$log.info('-----------------gitlabリポジトリID取得失敗の場合---------------------')
        Vue.$log.info(error.message)
      })
    },
    // データ初期化
    initData() {
      // 過去に表示されたエラー情報を消去する
      this.errorMessagesArray = []
      this.allErrorMessages = [{
        leftError: '',
        rightError: ''
      }]
      this.styleType = 'display: none'

      // ファイル内容領域(左側と右側)が空白であること
      this.$refs.LeftMonacoEditor.monacoEditor.setValue('')
      this.$refs.RightMonacoEditor.monacoEditor.setValue('')

      // maps配下のハイライト表示番号
      this.mapsHighlightedlines = []
      // test_entry配下のハイライト表示番号
      this.testEntryHighlightedlines = []
      // ppファイルのハイライトカラー
      this.ppDecoratorOptions = []
      // prodファイルのハイライトカラー
      this.prodDecoratorOptions = []

      // サイズオーバー警告情報
      this.sizeOverWarning = ''
      // 同じverのエントリ差異警告情報
      this.sameVerOthersNotSameWarning = ''
      // 同じverのエントリ差異のデータ
      this.ppDifferenceLines = []
      // サイズオーバー警告情報表示フラグ
      this.isShowSizeOver = false
      // 同じverのエントリ差異警告情報表示フラグ
      this.isShowNotSame = false

      this.btnCopyDisable = true
      this.btnConfirmDisable = true
      // pushボタンが活性になること
      this.btnPushDisable = false
      this.seqs = JSON.parse(JSON.stringify(constants.ERROR_MESSAGES_SEQ_FOR_ORDER))

      // ppファイルまたはprodファイルが存在しない
      this.fileNoExist = false
    },
    // ブランチ選択画面のキャンセルボタをンクリックした後コールバック方法
    closePopUp() {
      // ブランチ選択画面を閉じる
      this.branchSelect.visible = false
    },
    // ブランチを選択した後コールバック方法
    async selectedBranch(selectedBranchName) {
      // データ初期化
      this.initData()

      // 現在のUnixタイムスタンプを取得する
      let nowTimestamp = new Date().getTime()
      // release日付有効期限: releaseの値（UNIX時間）が「現在日時の2週間後まで
      const validTimestamp = nowTimestamp + constants.RELEASE_PLUS_DAYS * constants.MILLISECOND_OF_ONE_DAY

      const ppConfPath = constants.PP_CONF_PATH.replaceAll('/', '%2F')
      const prodConfPath = constants.PROD_CONF_PATH.replaceAll('/', '%2F')
      const branchName = selectedBranchName.replaceAll('/', '%2F')

      let ppApiUrl = constants.FILE_API_URL.replace('[0]', this.projectId)
        .replace('[1]', ppConfPath)
        .replace('[2]', branchName)
      let prodApiUrl = constants.FILE_API_URL.replace('[0]', this.projectId)
        .replace('[1]', prodConfPath)
        .replace('[2]', branchName)

      // ブランチ選択画面を閉じる
      this.branchSelect.visible = false

      this.branch.name = selectedBranchName
      this.ppFile.name = constants.PP_CONF_PATH
      this.prodFile.name = constants.PROD_CONF_PATH

      // gitlab apiアクセスに失敗しました
      let isApiError = false

      // エラーダイアログが出るかどうか
      let isPopupError = false

      // prod conf設定ファイル: gitlab apiサービスコール
      await axios.get(prodApiUrl, {
        headers: {
          'Content-Type': constants.CONTENT_TYPE,
          'PRIVATE-TOKEN': constants.GITLAB_ACCESS_TOKEN
        }
      })
      .then((response) => {
      // 成功の場合

        const responseBase64Content = response.data.content
        const responseContent = new Buffer.from(responseBase64Content, 'base64')

        // prod conf内容
        let rightEditorContent = responseContent.toString()

        // データを正常に表示する
        this.$refs.RightMonacoEditor.monacoEditor.setValue(rightEditorContent)

        // エラー情報を表示する
        commonUtil.showErrorMessages(constants.CHECK_FILE_NAME[1], rightEditorContent)

        this.oldValue = this.$refs.RightMonacoEditor.monacoEditor.getValue()
      })
      .catch((error) => {
        Vue.$log.info('-----------------prod設定ファイル取得失敗の場合---------------------')
        Vue.$log.info(error)
        Vue.$log.info(error.response)

        isApiError = true

        if (typeof(error.response) == 'undefined') {
          isPopupError = true

          let errorMessage = messages.CONF_FILE_ACCESS_ERROR.replace('[0]', error)

          this.$alert(`${errorMessage}`, '提示', {
            confirmButtonText: 'OK',
            dangerouslyUseHTMLString: true,
            type: 'error'
          }).then(() => {
          }).catch(() => {
          })
        } else {
          // 設定ファイルが存在しない
          if (error.response.data.message == messages.API_CONF_FILE_NOT_EXIST_ERROR) {
            commonUtil.showFileExistErrorMessages(constants.CHECK_FILE_NAME[1])

            return
          }

          let errorMessage = messages.CONF_FILE_ACCESS_ERROR.replace('[0]', error.response.data.message)
          if (error.response.data.error.length > 0) {
            errorMessage = messages.CONF_FILE_ACCESS_ERROR.replace('[0]', error.response.data.error)
          }

          this.$alert(`${errorMessage}`, '提示', {
            confirmButtonText: 'OK',
            dangerouslyUseHTMLString: true,
            type: 'error'
          }).then(() => {
          }).catch(() => {
          })
        }

        return
      })

      // pp conf設定ファイル: gitlab apiサービスコール
      await axios.get(ppApiUrl, {
        headers: {
          'Content-Type': constants.CONTENT_TYPE,
          'PRIVATE-TOKEN': constants.GITLAB_ACCESS_TOKEN
        }
      })
      .then((response) => {
        const responseBase64Content = response.data.content
        const responseContent = new Buffer.from(responseBase64Content, 'base64')

        // pp conf内容
        let leftEditorContent = responseContent.toString()

        // エディタにデータを正常に表示する
        this.$refs.LeftMonacoEditor.monacoEditor.setValue(leftEditorContent)

        // 左側のエディタを取得する
        let leftEditor = this.$refs.LeftMonacoEditor.monacoEditor
        // 左側エディタのモデルを取得する
        let leftModel = leftEditor.getModel()

        // サイズオーバー警告情報を設定する
        commonUtil.setWarningMessages(leftEditorContent, leftModel)

        // エラー情報初期化
        this.errorMessagesArray = []
        // 並べ替え番号の初期化
        this.seqs = JSON.parse(JSON.stringify(constants.ERROR_MESSAGES_SEQ_FOR_ORDER))
        // エラー情報を表示する
        commonUtil.showErrorMessages(constants.CHECK_FILE_NAME[0], leftEditorContent)

        // 1. Maps配下: pp confファイルのコピー対象データ行はハイライト表示
        // jsonファイル内のmapsオブジェクトをマッチ
        const mapsLineMatch = leftModel.findMatches(`"${constants.PP_CHECK_OBJECTS[0]}"`)
        let mapsStartLineNumber
        if (mapsLineMatch.length > 0) {
          const mapsLineMatchRange = mapsLineMatch[0].range
          mapsStartLineNumber = mapsLineMatchRange.startLineNumber
        }

        // jsonファイル内のmapsオブジェクトの終わりの]をマッチ
        let startPos = {lineNumber: mapsStartLineNumber, column: 1}
        const isRegexp = false
        let bracketLineMatch = leftModel.findNextMatch(constants.RIGHT_SQUARE_BRACKET, startPos, isRegexp, false, null, false)
        let bracketLineMatchRange = bracketLineMatch.range
        const mapsEndLineNumber = bracketLineMatchRange.startLineNumber

        let mutiLineContent = ''
        let mutiLineContentList = new Array()
        for (let i = mapsStartLineNumber + 1; i < mapsEndLineNumber; i++) {
          // 行のコンテンツを取得する
          let lineContent = leftModel.getLineContent(i)

          // 1つのバージョンに複数行が含まれる場合
          if (lineContent.indexOf(constants.LEFT_CURLY_BRACKET) >= 0
            && lineContent.indexOf(constants.RIGHT_CURLY_BRACKET) >= 0) {

            // 文字列の先頭と末尾のスペースを取り除く
            let lineContentRtrim = lineContent.replace(/^\s*|\s*$/g, '')
            if (lineContentRtrim.substr(-1) == constants.COMMA) {
              lineContentRtrim = lineContentRtrim.substring(0, lineContentRtrim.length - 1)
            }

            const lineJson = JSON.parse(lineContentRtrim)
            const lineVer = lineJson[constants.ITEMS_IN_MAPS[0]]
            const lineRelease = lineJson[constants.ITEMS_IN_MAPS[1]]

            // ver項目が存在しない
            if (typeof(lineVer) == 'undefined') {
              continue
            }

            // pp confファイルのコピー対象データ行を判断する
            const verFlagInProdConfMaps = this.lineCompareInMaps(lineContentRtrim)

            if (verFlagInProdConfMaps === 1) {
              // 同じverのエントリ差異
              this.ppDifferenceLines.push(lineContentRtrim)

              if (lineRelease >= nowTimestamp && lineRelease <= validTimestamp) {
                const decoratorOption = { range: new monaco.Range(i, 1, i, 1), options: {isWholeLine: true, className: constants.HIGHLIGHTED_COLORS[0]} }
                this.ppDecoratorOptions.push(decoratorOption)
                this.mapsHighlightedlines.push({version: lineVer, lineNumbers: [i]})
              }
            }

            if (lineRelease >= nowTimestamp && lineRelease <= validTimestamp && verFlagInProdConfMaps === 2) {
              const decoratorOption = { range: new monaco.Range(i, 1, i, 1),
                options: {isWholeLine: true, className: constants.HIGHLIGHTED_COLORS[1]} }
              this.ppDecoratorOptions.push(decoratorOption)
              this.mapsHighlightedlines.push({version: lineVer, lineNumbers: [i]})
            }
          } else {
            mutiLineContentList.push(i)
            mutiLineContent = `${mutiLineContent}${lineContent}`
          }

          // 複数行のデータをすべて取り出しているかどうかを判断する
          if (mutiLineContent.indexOf(constants.LEFT_CURLY_BRACKET) >= 0
            && mutiLineContent.indexOf(constants.RIGHT_CURLY_BRACKET) >= 0) {
            lineContent = mutiLineContent

            // 文字列の先頭と末尾のスペースを取り除く
            let lineContentRtrim = lineContent.replace(/^\s*|\s*$/g, '')
            if (lineContentRtrim.substr(-1) == constants.COMMA) {
              lineContentRtrim = lineContentRtrim.substring(0, lineContentRtrim.length - 1)
            }

            const lineJson = JSON.parse(lineContentRtrim)
            const lineVer = lineJson[constants.ITEMS_IN_MAPS[0]]
            const lineRelease = lineJson[constants.ITEMS_IN_MAPS[1]]

            // ver項目が存在しない
            if (typeof(lineVer) == 'undefined') {
              continue
            }

            // pp confファイルのコピー対象データ行を判断する
            const verFlagInProdConfMaps = this.lineCompareInMaps(lineContentRtrim)

            if (verFlagInProdConfMaps === 1) {
              // 同じverのエントリ差異
              this.ppDifferenceLines.push(lineContentRtrim)

              if (lineRelease >= nowTimestamp && lineRelease <= validTimestamp) {
                const decoratorOption = { range: new monaco.Range(Math.min(...mutiLineContentList), 1, Math.max(...mutiLineContentList), 1),
                  options: {isWholeLine: true, className: constants.HIGHLIGHTED_COLORS[0]} }
                this.ppDecoratorOptions.push(decoratorOption)
                this.mapsHighlightedlines.push({version: lineVer, lineNumbers: mutiLineContentList})
              }
            }

            if (lineRelease >= nowTimestamp && lineRelease <= validTimestamp && verFlagInProdConfMaps === 2) {
              const decoratorOption = { range: new monaco.Range(Math.min(...mutiLineContentList), 1, Math.max(...mutiLineContentList), 1),
                options: {isWholeLine: true, className: constants.HIGHLIGHTED_COLORS[1]} }
              this.ppDecoratorOptions.push(decoratorOption)
              this.mapsHighlightedlines.push({version: lineVer, lineNumbers: mutiLineContentList})
            }

            mutiLineContent = ''
            mutiLineContentList = new Array()
          }
        }

        // 2. test_entry配下: pp confファイルのコピー対象データ行はハイライト表示
        const testEntryLineMatch = leftModel.findMatches(`"${constants.PP_CHECK_OBJECTS[1]}"`)
        let testEntryStartLineNumber
        if (testEntryLineMatch.length > 0) {
          const testEntryLineMatchRange = testEntryLineMatch[0].range
          testEntryStartLineNumber = testEntryLineMatchRange.startLineNumber
        }

        // jsonファイル内のmapsオブジェクトの終わりの]をマッチ
        startPos = {lineNumber: testEntryStartLineNumber, column: 1}
        bracketLineMatch = leftModel.findNextMatch(constants.RIGHT_SQUARE_BRACKET, startPos, isRegexp, false, null, false)
        bracketLineMatchRange = bracketLineMatch.range
        const testEntryEndLineNumber = bracketLineMatchRange.startLineNumber

        mutiLineContent = ''
        mutiLineContentList = new Array()
        for (let i = testEntryStartLineNumber + 1; i <= testEntryEndLineNumber; i++) {
          // 行のコンテンツを取得する
          let lineContent = leftModel.getLineContent(i)

          // 1つのバージョンに複数行が含まれる場合
          if (lineContent.indexOf(constants.LEFT_CURLY_BRACKET) >= 0
            && lineContent.indexOf(constants.RIGHT_CURLY_BRACKET) >= 0) {

            // 文字列の先頭と末尾のスペースを取り除く
            let lineContentRtrim = lineContent.replace(/^\s*|\s*$/g, '')
            if (lineContentRtrim.substr(-1) == constants.COMMA) {
              lineContentRtrim = lineContentRtrim.substring(0, lineContentRtrim.length - 1)
            }

            const lineJson = JSON.parse(lineContentRtrim)
            const lineVer = lineJson[constants.ITEMS_IN_MAPS[0]]
            const lineVersionName = lineJson[constants.ITEMS_IN_MAPS[2]]
            const lineRelease = lineJson[constants.ITEMS_IN_MAPS[1]]

            // ver項目が存在しない
            if (typeof(lineVer) == 'undefined') {
              continue
            }
            // ver項目が存在すること
            const lineVersion = lineVer.slice(-5)

            //「"ver": "MMM8M0JP0905202", "name": "5202_6400_s5202"」から
            if (lineVersion < constants.TEST_ENTRY_BASE_VERSION
              || lineVersion === constants.TEST_ENTRY_BASE_VERSION && lineVersionName != constants.TEST_ENTRY_BASE_VERSION_NAME) {
              continue
            }

            // pp confファイルのコピー対象データ行を判断する
            const verFlagInProdConfMaps = this.lineCompareInDiffs(lineContentRtrim)

            if (verFlagInProdConfMaps === 1) {
              // 同じverのエントリ差異
              this.ppDifferenceLines.push(lineContentRtrim)

              if (lineRelease >= nowTimestamp && lineRelease <= validTimestamp) {
                const decoratorOption = { range: new monaco.Range(i, 1, i, 1), options: {isWholeLine: true, className: constants.HIGHLIGHTED_COLORS[0]} }
                this.ppDecoratorOptions.push(decoratorOption)
                this.testEntryHighlightedlines.push({version: lineVer, lineNumbers: [i]})
              }
            }

            if (lineRelease >= nowTimestamp && lineRelease <= validTimestamp && verFlagInProdConfMaps === 2) {
              const decoratorOption = { range: new monaco.Range(i, 1, i, 1), options: {isWholeLine: true, className: constants.HIGHLIGHTED_COLORS[1]} }
              this.ppDecoratorOptions.push(decoratorOption)
              this.testEntryHighlightedlines.push({version: lineVer, lineNumbers: [i]})
            }
          } else {
            mutiLineContentList.push(i)
            mutiLineContent = `${mutiLineContent}${lineContent}`
          }

          // 複数行のデータをすべて取り出しているかどうかを判断する
          if (mutiLineContent.indexOf(constants.LEFT_CURLY_BRACKET) >= 0
            && mutiLineContent.indexOf(constants.RIGHT_CURLY_BRACKET) >= 0) {
            lineContent = mutiLineContent

            // 文字列の先頭と末尾のスペースを取り除く
            let lineContentRtrim = lineContent.replace(/^\s*|\s*$/g, '')
            if (lineContentRtrim.substr(-1) == constants.COMMA) {
              lineContentRtrim = lineContentRtrim.substring(0, lineContentRtrim.length - 1)
            }

            const lineJson = JSON.parse(lineContentRtrim)
            const lineVer = lineJson[constants.ITEMS_IN_MAPS[0]]
            const lineVersionName = lineJson[constants.ITEMS_IN_MAPS[2]]
            const lineRelease = lineJson[constants.ITEMS_IN_MAPS[1]]

            // ver項目が存在しない
            if (typeof(lineVer) == 'undefined') {
              continue
            }
            // ver項目が存在すること
            const lineVersion = lineVer.slice(-5)

            //「"ver": "MMM8M0JP0905202", "name": "5202_6400_s5202"」から
            if (lineVersion < constants.TEST_ENTRY_BASE_VERSION
              || lineVersion === constants.TEST_ENTRY_BASE_VERSION && lineVersionName != constants.TEST_ENTRY_BASE_VERSION_NAME) {
              continue
            }

            // pp confファイルのコピー対象データ行を判断する
            const verFlagInProdConfMaps = this.lineCompareInDiffs(lineContentRtrim)

            if (verFlagInProdConfMaps === 1) {
              // 同じverのエントリ差異
              this.ppDifferenceLines.push(lineContentRtrim)

              if (lineRelease >= nowTimestamp && lineRelease <= validTimestamp) {
                const decoratorOption = { range: new monaco.Range(Math.min(...mutiLineContentList), 1, Math.max(...mutiLineContentList), 1),
                  options: {isWholeLine: true, className: constants.HIGHLIGHTED_COLORS[0]} }
                this.ppDecoratorOptions.push(decoratorOption)
                this.testEntryHighlightedlines.push({version: lineVer, lineNumbers: mutiLineContentList})
              }
            }

            if (lineRelease >= nowTimestamp && lineRelease <= validTimestamp && verFlagInProdConfMaps === 2) {
              const decoratorOption = { range: new monaco.Range(Math.min(...mutiLineContentList), 1, Math.max(...mutiLineContentList), 1),
                options: {isWholeLine: true, className: constants.HIGHLIGHTED_COLORS[1]} }
              this.ppDecoratorOptions.push(decoratorOption)
              this.testEntryHighlightedlines.push({version: lineVer, lineNumbers: mutiLineContentList})
            }

            mutiLineContent = ''
            mutiLineContentList = new Array()
          }
        }

        // 【警告】同じverのエントリ差異
        if (this.ppDifferenceLines.length > 0) {
          // 警告情報を表示する
          this.isShowNotSame = true
          // 警告情報
          const ppDifferenceLinesStr = this.ppDifferenceLines.join(constants.LINE_BREAK)
          this.sameVerOthersNotSameWarning
            = `${messages.DIFFERENCE_WARNING_MESSAGE}${constants.LINE_BREAK}${constants.LINE_BREAK}${ppDifferenceLinesStr}`
        }
      })
      .catch((error) => {
        Vue.$log.info('-----------------pp設定ファイル取得失敗の場合---------------------')
        Vue.$log.info(error)
        Vue.$log.info(error.response)

        isApiError = true

        if (typeof(error.response) == 'undefined') {
          if (!isPopupError) {
            let errorMessage = messages.CONF_FILE_ACCESS_ERROR.replace('[0]', error)

            this.$alert(`${errorMessage}`, '提示', {
              confirmButtonText: 'OK',
              dangerouslyUseHTMLString: true,
              type: 'error'
            }).then(() => {
            }).catch(() => {
            })
          }

        } else {
          // 設定ファイルが存在しない
          if (error.response.data.message == messages.API_CONF_FILE_NOT_EXIST_ERROR) {
            // エラーメッセージが表示されること
            commonUtil.showFileExistErrorMessages(constants.CHECK_FILE_NAME[0])

            return
          }

          let errorMessage = messages.CONF_FILE_ACCESS_ERROR.replace('[0]', error.response.data.message)
          if (error.response.data.error.length > 0) {
            errorMessage = messages.CONF_FILE_ACCESS_ERROR.replace('[0]', error.response.data.error)
          } else {
            this.$alert(`${errorMessage}`, '提示', {
              confirmButtonText: 'OK',
              dangerouslyUseHTMLString: true,
              type: 'error'
            }).then(() => {
            }).catch(() => {
            })
          }
        }

        return
      })

      // チェックステータス
      let isCheckError = false
      if (this.allErrorMessages[0].leftError.length > 0 || this.allErrorMessages[0].rightError.length > 0) {
        isCheckError = true
      }
      if (!isCheckError && !isApiError) {
        if (this.mapsHighlightedlines.length > 0 || this.testEntryHighlightedlines.length > 0) {
          // copyボタンが活性化にする
          this.btnCopyDisable = false

          // pp confファイルのコピー対象データ行はハイライト表示
          this.decorator = this.$refs.LeftMonacoEditor.monacoEditor.deltaDecorations(this.decorator, this.ppDecoratorOptions)
        } else {
        // コピー対象がない、エラーメッセージが表示されること
          Vue.$log.info('-----------------コピー対象がない---------------------')

          let noCopyDataError = messages.NO_COPY_DATA_ERROR.replace('[0]', constants.PP_CHECK_OBJECTS[0])

          // エラー情報のタイトル
          this.errorMessagesArray.push({'seq': this.seqs['release'], 'error': constants.CHECK_TYPE[6]})
          this.seqs['release'] += this.stepSize
          // エラー情報
          this.errorMessagesArray.push({'seq': this.seqs['release'], 'error': noCopyDataError})
          this.seqs['release'] += this.stepSize
          noCopyDataError = messages.NO_COPY_DATA_ERROR.replace('[0]', constants.PP_CHECK_OBJECTS[1])
          this.errorMessagesArray.push({'seq': this.seqs['release'], 'error': noCopyDataError})

          // エラー情報の並べ替え
          let errorMessages = commonUtil.objectArraySort(this.errorMessagesArray, 'seq', 'error')

          this.allErrorMessages = [{
            leftError: errorMessages.join(constants.LINE_BREAK),
            rightError: ''
          }]

          // エラーメッセージが表示されること
          this.styleType = 'display: block'
        }
      }
    },
    //selectボタン押下時
    async doSelect() {
      // gitlabリポジトリIDを取得する
      await this.getRepositoryId()

      if (this.projectId == 0) {
        // 失敗の場合: repositoryへのアクセスに失敗しました
        this.$alert(messages.REPOSITORY_ACCESS_ERROR, '提示', {
          confirmButtonText: 'OK',
          dangerouslyUseHTMLString: true,
          type: 'error'
        }).then(() => {
        }).catch(() => {
        })

        return
      }

      // repositoryのブランチ取得api
      const branchApiUrl = constants.BRANCH_API_URL.replace('[0]', this.projectId)
        .replace('[1]', constants.SEARCH_BRANCH_NAME_PREFIX)
        .replace('[2]', constants.DISPLAYED_NUMBERS_PER_PAGE)

      // データの初期化
      this.branchSelect.branches = []

      // gitlab apiサービスコール
      axios.get(branchApiUrl, {
        headers: {
          'Content-Type': constants.CONTENT_TYPE,
          'PRIVATE-TOKEN': constants.GITLAB_ACCESS_TOKEN
        }
      })
      .then((response) => {
        const branchResponse = response.data

        for (const branch of branchResponse) {
          if (branch.name.startsWith(constants.SEARCH_BRANCH_NAME_PREFIX)) {
            let branchObj = { branch: branch.name }
            this.branchSelect.branches.push(branchObj)
          }
        }

        if (this.branchSelect.branches.length == 0 ) {
          const branchErrorMessage = messages.BRANCH_NOT_EXIST_ERROR.replaceAll('[0]', constants.SEARCH_BRANCH_NAME_PREFIX)

          // repository内にreleased/*のブランチがないの場合
          this.$alert(branchErrorMessage, '提示', {
            confirmButtonText: 'OK',
            type: 'error'
          }).then(() => {
          }).catch(() => {
          })

          return
        }

        // repositoryのブランチをPOP画面で表示する
        this.branchSelect.visible = true
      })
      .catch((error) => {
        Vue.$log.info('-----------------repositoryのブランチ取得失敗の場合---------------------')
        Vue.$log.info(error.message)

        // 失敗の場合: repositoryへのアクセスに失敗しました
        this.$alert(messages.REPOSITORY_ACCESS_ERROR, '提示', {
          confirmButtonText: 'OK',
          dangerouslyUseHTMLString: true,
          type: 'error'
        }).then(() => {
        }).catch(() => {
        })
      })
    },
    // 確認画面へボタン押下時、画面遷移
    goToConfirm() {
      Vue.$log.info('Update.vue')

      this.newValue = this.$refs.RightMonacoEditor.monacoEditor.getValue()

      // 画面遷移
      this.$router.push({ name: this.$PROD_CONFIRM_COMPONENT_NAME, params: {
        rightEditorOldValue: this.oldValue,
        rightEditorNewValue: this.newValue,
        branchName: this.branch.name,
        ppFilePath: this.ppFile.name,
        prodFilePath: this.prodFile.name,
        projectId: this.projectId,
        ppDecoratorOptions: this.ppDecoratorOptions,
        prodDecoratorOptions: this.prodDecoratorOptions,
        isShowSizeOver: this.isShowSizeOver,
        isShowNotSame: this.isShowNotSame,
        sizeOverWarning: this.sizeOverWarning,
        btnPushDisable: this.btnPushDisable,
        sameVerOthersNotSameWarning: this.sameVerOthersNotSameWarning,
        leftEditorValue: this.$refs.LeftMonacoEditor.monacoEditor.getValue()} })
    },
    // copyボタン押下時
    merge() {
      // prodファイルのmaps配下にコピー
      if (this.mapsHighlightedlines.length > 0) {
        // maps配下の場合、prodファイルのmaps配下にコピー
        this.mapsToMaps()
      }
      // prodファイルのdiffs配下にコピー
      if (this.testEntryHighlightedlines.length > 0) {
        // test_entry配下の場合、prodファイルのdiffs配下にコピー
        this.testEntryToDiffs()
      }

      // prodファイルチェック: JSON形式チェック、ver重複チェック、ver順番チェック
      const rightEditorContent = this.$refs.RightMonacoEditor.monacoEditor.getValue()
      // copyボタン押下時
      const isFromCopyButton = true

      // エラー情報を表示する
      let isCheckError = commonUtil.showErrorMessages(constants.CHECK_FILE_NAME[1], rightEditorContent, isFromCopyButton)
      if (!isCheckError) {
        // prod confに変更されたデータ行はハイライト表示
        this.decorator = this.$refs.RightMonacoEditor.monacoEditor.deltaDecorations(this.decorator, this.prodDecoratorOptions)

        // copyボタンが非活性、確認ボタンが活性にする
        this.btnConfirmDisable = false
      }

      // copyボタンが非活性
      this.btnCopyDisable = true
    },
    // maps配下の場合、prodファイルのmaps配下にコピー
    mapsToMaps() {
      // 左側のエディタを取得する
      const leftEditor = this.$refs.LeftMonacoEditor.monacoEditor
      let leftModel = leftEditor.getModel()

      for (const mapsHighlightedline of this.mapsHighlightedlines) {
        // 行のコンテンツを取得する
        let oneVersionContent = ''
        for (const lineNumber of mapsHighlightedline.lineNumbers) {
          let lineContent = leftModel.getLineContent(lineNumber)

          // 文字列の先頭と末尾のスペースを取り除く
          lineContent = lineContent.replace(/^\s*|\s*$/g, '')

          if (oneVersionContent.length == 0) {
            oneVersionContent = `${oneVersionContent}${lineContent}`
          } else {
            oneVersionContent = `${oneVersionContent}${constants.LINE_BREAK}${lineContent}`
          }
        }

        // prod confにmaps内容変更
        this.lineMergeInMaps(mapsHighlightedline.version, oneVersionContent)
        oneVersionContent = ''
      }
    },
    // test_entry配下の場合、prodファイルのdiffs配下にコピー
    testEntryToDiffs() {
      // 左側のエディタを取得する
      const leftEditor = this.$refs.LeftMonacoEditor.monacoEditor
      let leftModel = leftEditor.getModel()

      for (const testEntryHighlightedline of this.testEntryHighlightedlines) {
        // 行のコンテンツを取得する
        let oneVersionContent = ''
        for (const lineNumber of testEntryHighlightedline.lineNumbers) {
          let lineContent = leftModel.getLineContent(lineNumber)

          // 文字列の先頭と末尾のスペースを取り除く
          lineContent = lineContent.replace(/^\s*|\s*$/g, '')

          if (oneVersionContent.length == 0) {
            oneVersionContent = `${oneVersionContent}${lineContent}`
          } else {
            oneVersionContent = `${oneVersionContent}${constants.LINE_BREAK}${lineContent}`
          }
        }

        // prod confにdiffs内容変更
        this.lineMergeInDiffs(testEntryHighlightedline.version, oneVersionContent)
        oneVersionContent = ''
      }
    },
    // pp confファイルのコピー対象データ行を判断する: leftLineContentは1行でも複数行でもよい
    lineCompareInMaps(leftLineContent) {
      // 0: すべてのエントリ値が同じ、1: ver同じでエントリ値が異なる、2: verが異なる
      let verFlagInProdConfMaps = 2

      // jsonオブジェクトを変換する
      const leftLineJson = JSON.parse(leftLineContent)
      // ver情報
      const leftLineVer = leftLineJson[constants.ITEMS_IN_MAPS[0]]

      // 右側のエディタを取得する
      let rightEditor = this.$refs.RightMonacoEditor.monacoEditor
      // 右側エディタのモデルを取得する
      let rightModel = rightEditor.getModel()

      // jsonファイル内のmapsオブジェクトをマッチ
      const mapsLineMatch = rightModel.findMatches(`"${constants.PROD_CHECK_OBJECTS[0]}"`)
      let mapsStartLineNumber
      if (mapsLineMatch.length > 0) {
        const mapsLineMatchRange = mapsLineMatch[0].range
        mapsStartLineNumber = mapsLineMatchRange.startLineNumber
      }

      // jsonファイル内のmapsオブジェクトの終わりの]をマッチ
      const startPos = {lineNumber: mapsStartLineNumber, column: 1}
      const isRegexp = false
      const bracketLineMatch = rightModel.findNextMatch(constants.RIGHT_SQUARE_BRACKET, startPos, isRegexp, false, null, false)
      const bracketLineMatchRange = bracketLineMatch.range
      const mapsEndLineNumber = bracketLineMatchRange.startLineNumber

      let mutiLineContent = ''
      let mutiLineContentList = new Array()
      for (let i = mapsStartLineNumber + 1; i < mapsEndLineNumber; i++) {
        // 行のコンテンツを取得する
        let lineContent = rightModel.getLineContent(i)

        if (lineContent.indexOf(constants.LEFT_CURLY_BRACKET) >= 0
          && lineContent.indexOf(constants.RIGHT_CURLY_BRACKET) >= 0) {
        // 1つのバージョンに単行が含まれる場合

          // 文字列の先頭と末尾のスペースを取り除く
          let lineContentRtrim = lineContent.replace(/^\s*|\s*$/g, '')
          if (lineContentRtrim.substr(-1) == constants.COMMA) {
            lineContentRtrim = lineContentRtrim.substring(0, lineContentRtrim.length - 1)
          }

          const lineJson = JSON.parse(lineContentRtrim)
          const lineVer = lineJson[constants.ITEMS_IN_MAPS[0]]

          if (lineContentRtrim === leftLineContent) {
            verFlagInProdConfMaps  = 0
            return verFlagInProdConfMaps
          } else if (leftLineVer === lineVer) {
            verFlagInProdConfMaps = 1
            return verFlagInProdConfMaps
          }
        } else {
        // 1つのバージョンに複数行が含まれる場合
          mutiLineContentList.push(i)
          mutiLineContent = `${mutiLineContent}${lineContent}`
        }

        // 複数行のデータをすべて取り出しているかどうかを判断する
        if (mutiLineContent.indexOf(constants.LEFT_CURLY_BRACKET) >= 0
          && mutiLineContent.indexOf(constants.RIGHT_CURLY_BRACKET) >= 0) {
          lineContent = mutiLineContent

          // 文字列の先頭と末尾のスペースを取り除く
          let lineContentRtrim = lineContent.replace(/^\s*|\s*$/g, '')
          if (lineContentRtrim.substr(-1) == constants.COMMA) {
            lineContentRtrim = lineContentRtrim.substring(0, lineContentRtrim.length - 1)
          }

          const lineJson = JSON.parse(lineContentRtrim)
          const lineVer = lineJson[constants.ITEMS_IN_MAPS[0]]

          if (lineContentRtrim === leftLineContent) {
            verFlagInProdConfMaps  = 0
            return verFlagInProdConfMaps
          } else if (leftLineVer === lineVer) {
            verFlagInProdConfMaps = 1
            return verFlagInProdConfMaps
          }

          mutiLineContent = ''
          mutiLineContentList = new Array()
        }
      }

      return verFlagInProdConfMaps
    },
    // prod confにmaps内容変更
    lineMergeInMaps(leftLineVer, oneVersionContent) {
      let oneVersionlines = oneVersionContent.split(constants.LINE_BREAK)
      const oneVersionContentLength = oneVersionlines.length

      // 右側のエディタを取得する
      let rightEditor = this.$refs.RightMonacoEditor.monacoEditor
      // 右側エディタのモデルを取得する
      let rightModel = rightEditor.getModel()

      // jsonファイル内のmapsオブジェクトをマッチ
      const mapsLineMatch = rightModel.findMatches(`"${constants.PROD_CHECK_OBJECTS[0]}"`)
      let mapsStartLineNumber
      if (mapsLineMatch.length > 0) {
        const mapsLineMatchRange = mapsLineMatch[0].range
        mapsStartLineNumber = mapsLineMatchRange.startLineNumber
      }

      // jsonファイル内のmapsオブジェクトの終わりの]をマッチ
      const startPos = {lineNumber: mapsStartLineNumber, column: 1}
      const isRegexp = false
      const bracketLineMatch = rightModel.findNextMatch(constants.RIGHT_SQUARE_BRACKET, startPos, isRegexp, false, null, false)
      const bracketLineMatchRange = bracketLineMatch.range
      const mapsEndLineNumber = bracketLineMatchRange.startLineNumber

      // prodファイルのmapsにある内容を基準に揃えて表示し、フレーム数を判定する
      let leftCurlyBracketPos = 0
      for (let i = 0; i <= mapsEndLineNumber - 1; i++) {
        const lineContent = rightModel.getLineContent(mapsEndLineNumber - 1 - i)

        if (lineContent.indexOf(constants.LEFT_CURLY_BRACKET) > 0) {
          leftCurlyBracketPos = lineContent.indexOf(constants.LEFT_CURLY_BRACKET)
          break
        }
      }
      // フレーム数を取得する
      const spacesNumber = leftCurlyBracketPos + 1
      const spacesInLineBeginSingleLine = new Array(spacesNumber).join(' ')
      const spacesInLineBeginMultiLines = new Array(spacesNumber + 2).join(' ')

      let mutiLineContent = ''
      let mutiLineContentList = new Array()
      for (let i = mapsStartLineNumber + 1; i < mapsEndLineNumber; i++) {
        // 行のコンテンツを取得する
        let lineContent = rightModel.getLineContent(i)

        // 初期化
        let lineContentCommaFlag = false

        if (lineContent.indexOf(constants.LEFT_CURLY_BRACKET) >= 0
          && lineContent.indexOf(constants.RIGHT_CURLY_BRACKET) >= 0) {
        // 1つのバージョンに単行が含まれる場合

          let lineContentRtrim = lineContent.replace(/(\s*$)/g, "")
          if (lineContentRtrim.substr(-1) == constants.COMMA) {
            lineContentCommaFlag = true
            lineContentRtrim = lineContentRtrim.substring(0, lineContentRtrim.length - 1)
          }

          const lineJson = JSON.parse(lineContentRtrim)
          const lineVer = lineJson[constants.ITEMS_IN_MAPS[0]]

          // 直接挿入されるコピー対象エントリ
          if (leftLineVer < lineVer) {
            // コピーデータ行は単行と複数行
            if (oneVersionContent.indexOf(constants.LINE_BREAK) < 0) {
            // 1つのバージョンに単行が含まれる場合
              oneVersionContent = `${constants.LINE_BREAK}${spacesInLineBeginSingleLine}${oneVersionContent}`
            } else {
            // 1つのバージョンに複数行が含まれる場合
              oneVersionContent = ''
              for (let oneVersionline of oneVersionlines) {
                if (oneVersionline.substring(0, 1) === constants.LEFT_CURLY_BRACKET
                  || oneVersionline.substring(0, 1) === constants.RIGHT_CURLY_BRACKET) {
                  oneVersionline =  `${spacesInLineBeginSingleLine}${oneVersionline}`
                } else {
                  oneVersionline =  `${spacesInLineBeginMultiLines}${oneVersionline}`
                }

                if (oneVersionContent.length == 0) {
                  oneVersionContent = `${oneVersionContent}${oneVersionline}`
                } else {
                  oneVersionContent = `${oneVersionContent}${constants.LINE_BREAK}${oneVersionline}`
                }
              }

              oneVersionContent = `${constants.LINE_BREAK}${oneVersionContent}`
            }

            // 直接挿入されるコピー対象エントリ
            const lineLength = rightModel.getLineLength(i - 1)
            // 直接挿入されるコピー対象の行番号
            const insertLineNumber = i
            // Rangeを定義する
            const insertRange = new monaco.Range(insertLineNumber - 1, lineLength + 1, insertLineNumber - 1, lineLength + 1)
            rightEditor.updateOptions({readOnly: false})
            // 指定した場所にコンテンツを追加
            rightEditor.executeEdits("", [
              {
                range: insertRange,
                text: oneVersionContent
              }
            ])
            rightEditor.updateOptions({readOnly: true})

            // 変更されたデータ行はハイライト表示
            const decoratorOption = { range: new monaco.Range(i, 1, i + oneVersionContentLength - 1, 1),
              options: {isWholeLine: true, className: constants.HIGHLIGHTED_COLORS[1]} }
            this.prodDecoratorOptions.push(decoratorOption)

            break
          }

          // 上書き対象(ver同じでエントリ値が異なる場合)
          if (leftLineVer === lineVer) {
            const lineLength = rightModel.getLineLength(i)

            let oneVersionContentRtrim = oneVersionContent.replace(/(\s*$)/g, "")
            if (i == mapsEndLineNumber - 1 && oneVersionContentRtrim.substr(-1) == constants.COMMA) {
              // 末尾カンマを消す
              oneVersionContentRtrim = oneVersionContentRtrim.substring(0, oneVersionContentRtrim.length - 1)
            }

            // コピーデータ行は単行と複数行
            if (oneVersionContent.indexOf(constants.LINE_BREAK) < 0) {
            // 1つのバージョンに単行が含まれる場合
              oneVersionContent = `${spacesInLineBeginSingleLine}${oneVersionContentRtrim}`
            } else {
            // 1つのバージョンに複数行が含まれる場合
              oneVersionContent = ''
              for (let oneVersionline of oneVersionlines) {
                if (oneVersionline.substring(0, 1) === constants.LEFT_CURLY_BRACKET
                  || oneVersionline.substring(0, 1) === constants.RIGHT_CURLY_BRACKET) {
                  oneVersionline =  `${spacesInLineBeginSingleLine}${oneVersionline}`
                } else {
                  oneVersionline =  `${spacesInLineBeginMultiLines}${oneVersionline}`
                }

                if (oneVersionContent.length == 0) {
                  oneVersionContent = `${oneVersionContent}${oneVersionline}`
                } else {
                  oneVersionContent = `${oneVersionContent}${constants.LINE_BREAK}${oneVersionline}`
                }
              }
            }

            // 上書き対象の行番号
            const insertLineNumber = i
            // Rangeを定義する、上書き対象
            const insertRange = new monaco.Range(insertLineNumber, 1, insertLineNumber, lineLength + 1)
            rightEditor.updateOptions({readOnly: false})
            // 指定した場所にコンテンツを上書き
            rightEditor.executeEdits("", [
              {
                range: insertRange,
                text: oneVersionContent
              }
            ])
            rightEditor.updateOptions({readOnly: true})

            // 変更されたデータ行はハイライト表示
            const decoratorOption = { range: new monaco.Range(i, 1, i + oneVersionContentLength - 1, 1),
              options: {isWholeLine: true, className: constants.HIGHLIGHTED_COLORS[0]} }
            this.prodDecoratorOptions.push(decoratorOption)

            break
          }

          // 直接挿入されるコピー対象エントリ(maps配下の末尾に追加)
          if (i == mapsEndLineNumber - 1) {
            const lineLength = rightModel.getLineLength(i)

            if (oneVersionContent.indexOf(constants.LINE_BREAK) < 0) {
            // 1つのバージョンに単行が含まれる場合
              // カンマ処理
              if (lineContentCommaFlag) {
                oneVersionContent = `${constants.LINE_BREAK}${spacesInLineBeginSingleLine}${oneVersionContent}`
              } else {
                oneVersionContent = `${constants.COMMA}${constants.LINE_BREAK}${spacesInLineBeginSingleLine}${oneVersionContent}`
              }
            } else {
            // 1つのバージョンに複数行が含まれる場合
              oneVersionContent = ''
              for (let oneVersionline of oneVersionlines) {
                if (oneVersionline.substring(0, 1) === constants.LEFT_CURLY_BRACKET
                  || oneVersionline.substring(0, 1) === constants.RIGHT_CURLY_BRACKET) {
                  oneVersionline =  `${spacesInLineBeginSingleLine}${oneVersionline}`
                } else {
                  oneVersionline =  `${spacesInLineBeginMultiLines}${oneVersionline}`
                }

                if (oneVersionContent.length == 0) {
                  oneVersionContent = `${oneVersionContent}${oneVersionline}`
                } else {
                  oneVersionContent = `${oneVersionContent}${constants.LINE_BREAK}${oneVersionline}`
                }
              }

              // カンマ処理
              if (lineContentCommaFlag) {
                oneVersionContent = `${constants.LINE_BREAK}${oneVersionContent}`
              } else {
                oneVersionContent = `${constants.COMMA}${constants.LINE_BREAK}${oneVersionContent}`
              }
            }

            // 挿入されるデータ行のカンマ処理
            let oneVersionContentRtrim = oneVersionContent.replace(/(\s*$)/g, "")
            if (oneVersionContentRtrim.substr(-1) == constants.COMMA) {
              oneVersionContentRtrim = oneVersionContentRtrim.substring(0, oneVersionContentRtrim.length - 1)
            }

            // 直接挿入されるコピー対象エントリ
            const insertLineNumber = i
            // Rangeを定義する
            const insertRange = new monaco.Range(insertLineNumber, lineLength + 1, insertLineNumber, lineLength + 1)
            rightEditor.updateOptions({readOnly: false})
            // 指定した場所にコンテンツを追加
            rightEditor.executeEdits("", [
              {
                range: insertRange,
                text: oneVersionContentRtrim
              }
            ])
            rightEditor.updateOptions({readOnly: true})

            // 変更されたデータ行はハイライト表示
            const decoratorOption = { range: new monaco.Range(i + 1, 1, i + oneVersionContentLength, 1),
              options: {isWholeLine: true, className: constants.HIGHLIGHTED_COLORS[1]} }
            this.prodDecoratorOptions.push(decoratorOption)

            break
          }
        } else {
        // 1つのバージョンに複数行が含まれる場合  
          mutiLineContentList.push(i)
          mutiLineContent = `${mutiLineContent}${lineContent}`
        }

        // 複数行のデータをすべて取り出しているかどうかを判断する
        if (mutiLineContent.indexOf(constants.LEFT_CURLY_BRACKET) >= 0
          && mutiLineContent.indexOf(constants.RIGHT_CURLY_BRACKET) >= 0) {

          lineContent = mutiLineContent

          // 複数行の行数
          const lineContentLines = mutiLineContentList.length

          let lineContentRtrim = lineContent.replace(/(\s*$)/g, "")
          if (lineContentRtrim.substr(-1) == constants.COMMA) {
            lineContentCommaFlag = true
            lineContentRtrim = lineContentRtrim.substring(0, lineContentRtrim.length - 1)
          }

          const lineJson = JSON.parse(lineContentRtrim)
          const lineVer = lineJson[constants.ITEMS_IN_MAPS[0]]

          // 直接挿入されるコピー対象エントリ
          if (leftLineVer < lineVer) {
            oneVersionContent = `${constants.LINE_BREAK}${spacesInLineBeginSingleLine}${oneVersionContent}`

            // 直接挿入されるコピー対象エントリ
            const lineLength = rightModel.getLineLength(i - 1)
            // 直接挿入されるコピー対象の行番号
            const insertLineNumber = i
            // Rangeを定義する
            const insertRange = new monaco.Range(insertLineNumber - 1, lineLength + 1, insertLineNumber - 1, lineLength + 1)
            rightEditor.updateOptions({readOnly: false})
            // 指定した場所にコンテンツを追加
            rightEditor.executeEdits("", [
              {
                range: insertRange,
                text: oneVersionContent
              }
            ])
            rightEditor.updateOptions({readOnly: true})

            // 変更されたデータ行はハイライト表示
            const decoratorOption = { range: new monaco.Range(i, 1, i + oneVersionContentLength - 1, 1),
              options: {isWholeLine: true, className: constants.HIGHLIGHTED_COLORS[1]} }
            this.prodDecoratorOptions.push(decoratorOption)

            break
          }

          // 上書き対象(ver同じでエントリ値が異なる場合)
          if (leftLineVer === lineVer) {
            const lineLength = rightModel.getLineLength(i)

            let oneVersionContentRtrim = oneVersionContent.replace(/(\s*$)/g, "")
            if (i == mapsEndLineNumber - 1 && oneVersionContentRtrim.substr(-1) == constants.COMMA) {
              // 末尾カンマを消す
              oneVersionContentRtrim = oneVersionContentRtrim.substring(0, oneVersionContentRtrim.length - 1)
            }

            // コピーデータ行は単行と複数行
            if (oneVersionContent.indexOf(constants.LINE_BREAK) < 0) {
            // 1つのバージョンに単行が含まれる場合
              oneVersionContent = `${spacesInLineBeginSingleLine}${oneVersionContentRtrim}`
            } else {
            // 1つのバージョンに複数行が含まれる場合
              oneVersionContent = ''
              for (let oneVersionline of oneVersionlines) {
                if (oneVersionline.substring(0, 1) === constants.LEFT_CURLY_BRACKET
                  || oneVersionline.substring(0, 1) === constants.RIGHT_CURLY_BRACKET) {
                  oneVersionline =  `${spacesInLineBeginSingleLine}${oneVersionline}`
                } else {
                  oneVersionline =  `${spacesInLineBeginMultiLines}${oneVersionline}`
                }

                if (oneVersionContent.length == 0) {
                  oneVersionContent = `${oneVersionContent}${oneVersionline}`
                } else {
                  oneVersionContent = `${oneVersionContent}${constants.LINE_BREAK}${oneVersionline}`
                }
              }
            }

            // 上書き対象の行番号
            const insertLineNumber = i
            // Rangeを定義する、上書き対象
            const insertRange = new monaco.Range(insertLineNumber - lineContentLines + 1, 1, insertLineNumber, lineLength + 1)
            rightEditor.updateOptions({readOnly: false})
            // 指定した場所にコンテンツを上書き
            rightEditor.executeEdits("", [
              {
                range: insertRange,
                text: oneVersionContent
              }
            ])
            rightEditor.updateOptions({readOnly: true})

            // 変更されたデータ行はハイライト表示
            const decoratorOption = { range: new monaco.Range(i - lineContentLines + 1, 1, i - lineContentLines + oneVersionContentLength, 1),
              options: {isWholeLine: true, className: constants.HIGHLIGHTED_COLORS[0]} }
            this.prodDecoratorOptions.push(decoratorOption)

            break
          }

          // 直接挿入されるコピー対象エントリ(maps配下の末尾に追加)
          if (i == mapsEndLineNumber - 1) {
            const lineLength = rightModel.getLineLength(i)

            if (oneVersionContent.indexOf(constants.LINE_BREAK) < 0) {
            // 1つのバージョンに単行が含まれる場合
              // カンマ処理
              if (lineContentCommaFlag) {
                oneVersionContent = `${constants.LINE_BREAK}${spacesInLineBeginSingleLine}${oneVersionContent}`
              } else {
                oneVersionContent = `${constants.COMMA}${constants.LINE_BREAK}${spacesInLineBeginSingleLine}${oneVersionContent}`
              }
            } else {
            // 1つのバージョンに複数行が含まれる場合
              oneVersionContent = ''
              for (let oneVersionline of oneVersionlines) {
                if (oneVersionline.substring(0, 1) === constants.LEFT_CURLY_BRACKET
                  || oneVersionline.substring(0, 1) === constants.RIGHT_CURLY_BRACKET) {
                  oneVersionline =  `${spacesInLineBeginSingleLine}${oneVersionline}`
                } else {
                  oneVersionline =  `${spacesInLineBeginMultiLines}${oneVersionline}`
                }

                if (oneVersionContent.length == 0) {
                  oneVersionContent = `${oneVersionContent}${oneVersionline}`
                } else {
                  oneVersionContent = `${oneVersionContent}${constants.LINE_BREAK}${oneVersionline}`
                }
              }

              // カンマ処理
              if (lineContentCommaFlag) {
                oneVersionContent = `${constants.LINE_BREAK}${oneVersionContent}`
              } else {
                oneVersionContent = `${constants.COMMA}${constants.LINE_BREAK}${oneVersionContent}`
              }
            }

            // 挿入されるデータ行のカンマ処理
            let oneVersionContentRtrim = oneVersionContent.replace(/(\s*$)/g, "")
            if (oneVersionContentRtrim.substr(-1) == constants.COMMA) {
              oneVersionContentRtrim = oneVersionContentRtrim.substring(0, oneVersionContentRtrim.length - 1)
            }

            // 直接挿入されるコピー対象エントリ
            const insertLineNumber = i
            // Rangeを定義する
            const insertRange = new monaco.Range(insertLineNumber, lineLength + 1, insertLineNumber, lineLength + 1)
            rightEditor.updateOptions({readOnly: false})
            // 指定した場所にコンテンツを追加
            rightEditor.executeEdits("", [
              {
                range: insertRange,
                text: oneVersionContentRtrim
              }
            ])
            rightEditor.updateOptions({readOnly: true})

            // 変更されたデータ行はハイライト表示
            const decoratorOption = { range: new monaco.Range(i + 1, 1, i + oneVersionContentLength, 1),
              options: {isWholeLine: true, className: constants.HIGHLIGHTED_COLORS[1]} }
            this.prodDecoratorOptions.push(decoratorOption)

            break
          }

          // 再度初期化
          mutiLineContent = ''
          mutiLineContentList = new Array()
        }
      }
    },
    // pp confファイルtest_entryのコピー対象データ行を判断する: leftLineContentは1行でも複数行でもよい
    lineCompareInDiffs(leftLineContent) {
      // 0: すべてのエントリ値が同じ、1: ver同じでエントリ値が異なる、2: verが異なる
      let verFlagInProdConfDiffs = 2

      const leftLineJson = JSON.parse(leftLineContent)
      const leftLineVer = leftLineJson[constants.ITEMS_IN_MAPS[0]]

      // 右側のエディタを取得する
      let rightEditor = this.$refs.RightMonacoEditor.monacoEditor
      // 右側エディタのモデルを取得する
      let rightModel = rightEditor.getModel()

      // jsonファイル内のdiffsオブジェクトをマッチ
      const diffsLineMatch = rightModel.findMatches(`"${constants.PROD_CHECK_OBJECTS[1]}"`)
      let diffsStartLineNumber
      if (diffsLineMatch.length > 0) {
        const diffsLineMatchRange = diffsLineMatch[0].range
        diffsStartLineNumber = diffsLineMatchRange.startLineNumber
      }

      // jsonファイル内のdiffsオブジェクトの終わりの]をマッチ
      const startPos = {lineNumber: diffsStartLineNumber, column: 1}
      const isRegexp = false
      const bracketLineMatch = rightModel.findNextMatch(constants.RIGHT_SQUARE_BRACKET, startPos, isRegexp, false, null, false)
      const bracketLineMatchRange = bracketLineMatch.range
      const diffsEndLineNumber = bracketLineMatchRange.startLineNumber

      let mutiLineContent = ''
      let mutiLineContentList = new Array()
      for (let i = diffsStartLineNumber + 1; i <= diffsEndLineNumber; i++) {
        // 行のコンテンツを取得する
        let lineContent = rightModel.getLineContent(i)

        // 1つのバージョンに複数行が含まれる場合
        if (lineContent.indexOf(constants.LEFT_CURLY_BRACKET) >= 0
          && lineContent.indexOf(constants.RIGHT_CURLY_BRACKET) >= 0) {
          // 文字列の先頭と末尾のスペースを取り除く
          let lineContentRtrim = lineContent.replace(/^\s*|\s*$/g, '')
          if (lineContentRtrim.substr(-1) == constants.COMMA) {
            lineContentRtrim = lineContentRtrim.substring(0, lineContentRtrim.length - 1)
          }

          const lineJson = JSON.parse(lineContentRtrim)
          const lineVer = lineJson[constants.ITEMS_IN_MAPS[0]]

          if (lineContentRtrim === leftLineContent) {
            verFlagInProdConfDiffs  = 0
            return verFlagInProdConfDiffs
          } else if (leftLineVer === lineVer) {
            verFlagInProdConfDiffs = 1
            return verFlagInProdConfDiffs
          }
        } else {
          mutiLineContentList.push(i)
          mutiLineContent = `${mutiLineContent}${lineContent}`
        }

        // 複数行のデータをすべて取り出しているかどうかを判断する
        if (mutiLineContent.indexOf(constants.LEFT_CURLY_BRACKET) >= 0
          && mutiLineContent.indexOf(constants.RIGHT_CURLY_BRACKET) >= 0) {
          lineContent = mutiLineContent

          // 文字列の先頭と末尾のスペースを取り除く
          let lineContentRtrim = lineContent.replace(/^\s*|\s*$/g, '')
          if (lineContentRtrim.substr(-1) == constants.COMMA) {
            lineContentRtrim = lineContentRtrim.substring(0, lineContentRtrim.length - 1)
          }

          const lineJson = JSON.parse(lineContentRtrim)
          const lineVer = lineJson[constants.ITEMS_IN_MAPS[0]]

          if (lineContentRtrim === leftLineContent) {
            verFlagInProdConfDiffs  = 0
            return verFlagInProdConfDiffs
          } else if (leftLineVer === lineVer) {
            verFlagInProdConfDiffs = 1
            return verFlagInProdConfDiffs
          }

          mutiLineContent = ''
          mutiLineContentList = new Array()
        }
      }

      return verFlagInProdConfDiffs
    },
    // prod confにdiffs内容変更
    lineMergeInDiffs(leftLineVer, oneVersionContent) {
      let oneVersionlines = oneVersionContent.split(constants.LINE_BREAK)
      const oneVersionContentLength = oneVersionlines.length

      // 右側のエディタを取得する
      let rightEditor = this.$refs.RightMonacoEditor.monacoEditor
      // 右側エディタのモデルを取得する
      let rightModel = rightEditor.getModel()

      // jsonファイル内のdiffsオブジェクトをマッチ
      const diffsLineMatch = rightModel.findMatches(`"${constants.PROD_CHECK_OBJECTS[1]}"`)
      let diffsStartLineNumber
      if (diffsLineMatch.length > 0) {
        const diffsLineMatchRange = diffsLineMatch[0].range
        diffsStartLineNumber = diffsLineMatchRange.startLineNumber
      }

      // jsonファイル内のdiffsオブジェクトの終わりの]をマッチ
      const startPos = {lineNumber: diffsStartLineNumber, column: 1}
      const isRegexp = false
      const bracketLineMatch = rightModel.findNextMatch(constants.RIGHT_SQUARE_BRACKET, startPos, isRegexp, false, null, false)
      const bracketLineMatchRange = bracketLineMatch.range
      const diffsEndLineNumber = bracketLineMatchRange.startLineNumber

      // prodファイルのdiffsにある内容を基準に揃えて表示し、フレーム数を判定する
      let leftCurlyBracketPos = 0
      for (let i = 0; i <= diffsEndLineNumber - 1; i++) {
        const lineContent = rightModel.getLineContent(diffsEndLineNumber - 1 - i)

        if (lineContent.indexOf(constants.LEFT_CURLY_BRACKET) > 0) {
          leftCurlyBracketPos = lineContent.indexOf(constants.LEFT_CURLY_BRACKET)
          break
        }
      }

      // フレーム数を取得する
      const spacesNumber = leftCurlyBracketPos + 1
      const spacesInLineBeginSingleLine = new Array(spacesNumber).join(' ')
      const spacesInLineBeginMultiLines = new Array(spacesNumber + 2).join(' ')

      let mutiLineContent = ''
      let mutiLineContentList = new Array()
      for (let i = diffsStartLineNumber + 1; i < diffsEndLineNumber; i++) {
        // 行のコンテンツを取得する
        let lineContent = rightModel.getLineContent(i)

        // 初期化
        let lineContentCommaFlag = false

        if (lineContent.indexOf(constants.LEFT_CURLY_BRACKET) >= 0
          && lineContent.indexOf(constants.RIGHT_CURLY_BRACKET) >= 0) {
        // 1つのバージョンに単行が含まれる場合    

          let lineContentRtrim = lineContent.replace(/(\s*$)/g, "")
          if (lineContentRtrim.substr(-1) == constants.COMMA) {
            lineContentCommaFlag = true
            lineContentRtrim = lineContentRtrim.substring(0, lineContentRtrim.length - 1)
          }

          const lineJson = JSON.parse(lineContentRtrim)
          const lineVer = lineJson[constants.ITEMS_IN_MAPS[0]]

          // 直接挿入されるコピー対象エントリ
          if (leftLineVer < lineVer) {
            // コピーデータ行は単行と複数行
            if (oneVersionContent.indexOf(constants.LINE_BREAK) < 0) {
            // 1つのバージョンに単行が含まれる場合
              oneVersionContent = `${constants.LINE_BREAK}${spacesInLineBeginSingleLine}${oneVersionContent}`
            } else {
            // 1つのバージョンに複数行が含まれる場合
              oneVersionContent = ''
              for (let oneVersionline of oneVersionlines) {
                if (oneVersionline.substring(0, 1) === constants.LEFT_CURLY_BRACKET
                  || oneVersionline.substring(0, 1) === constants.RIGHT_CURLY_BRACKET) {
                  oneVersionline =  `${spacesInLineBeginSingleLine}${oneVersionline}`
                } else {
                  oneVersionline =  `${spacesInLineBeginMultiLines}${oneVersionline}`
                }

                if (oneVersionContent.length == 0) {
                  oneVersionContent = `${oneVersionContent}${oneVersionline}`
                } else {
                  oneVersionContent = `${oneVersionContent}${constants.LINE_BREAK}${oneVersionline}`
                }
              }

              oneVersionContent = `${constants.LINE_BREAK}${oneVersionContent}`
            }

            // 直接挿入されるコピー対象エントリ
            const lineLength = rightModel.getLineLength(i - 1)
            // 直接挿入されるコピー対象の行番号
            const insertLineNumber = i
            // Rangeを定義する
            const insertRange = new monaco.Range(insertLineNumber - 1, lineLength + 1, insertLineNumber - 1, lineLength + 1)
            rightEditor.updateOptions({readOnly: false})
            // 指定した場所にコンテンツを追加
            rightEditor.executeEdits("", [
              {
                range: insertRange,
                text: oneVersionContent
              }
            ])
            rightEditor.updateOptions({readOnly: true})

            // 変更されたデータ行はハイライト表示
            const decoratorOption = { range: new monaco.Range(i, 1, i + oneVersionContentLength - 1, 1),
              options: {isWholeLine: true, className: constants.HIGHLIGHTED_COLORS[1]} }
            this.prodDecoratorOptions.push(decoratorOption)

            break
          }

          // 上書き対象(ver同じでエントリ値が異なる場合)
          if (leftLineVer === lineVer) {
            const lineLength = rightModel.getLineLength(i)

            let oneVersionContentRtrim = oneVersionContent.replace(/(\s*$)/g, "")
            if (i == diffsEndLineNumber - 1 && oneVersionContentRtrim.substr(-1) == constants.COMMA) {
              // 末尾カンマを消す
              oneVersionContentRtrim = oneVersionContentRtrim.substring(0, oneVersionContentRtrim.length - 1)
            }

            // コピーデータ行は単行と複数行
            if (oneVersionContent.indexOf(constants.LINE_BREAK) < 0) {
            // 1つのバージョンに単行が含まれる場合
              oneVersionContent = `${spacesInLineBeginSingleLine}${oneVersionContentRtrim}`
            } else {
            // 1つのバージョンに複数行が含まれる場合
              oneVersionContent = ''
              for (let oneVersionline of oneVersionlines) {
                if (oneVersionline.substring(0, 1) === constants.LEFT_CURLY_BRACKET
                  || oneVersionline.substring(0, 1) === constants.RIGHT_CURLY_BRACKET) {
                  oneVersionline =  `${spacesInLineBeginSingleLine}${oneVersionline}`
                } else {
                  oneVersionline =  `${spacesInLineBeginMultiLines}${oneVersionline}`
                }

                if (oneVersionContent.length == 0) {
                  oneVersionContent = `${oneVersionContent}${oneVersionline}`
                } else {
                  oneVersionContent = `${oneVersionContent}${constants.LINE_BREAK}${oneVersionline}`
                }
              }
            }

            // 上書き対象の行番号
            const insertLineNumber = i
            // Rangeを定義する、上書き対象
            const insertRange = new monaco.Range(insertLineNumber, 1, insertLineNumber, lineLength + 1)
            rightEditor.updateOptions({readOnly: false})
            // 指定した場所にコンテンツを上書き
            rightEditor.executeEdits("", [
              {
                range: insertRange,
                text: oneVersionContent
              }
            ])
            rightEditor.updateOptions({readOnly: true})

            // 変更されたデータ行はハイライト表示
            const decoratorOption = { range: new monaco.Range(i, 1, i + oneVersionContentLength - 1, 1),
              options: {isWholeLine: true, className: constants.HIGHLIGHTED_COLORS[0]} }
            this.prodDecoratorOptions.push(decoratorOption)

            break
          }

          // 直接挿入されるコピー対象エントリ(diffs配下の末尾に追加)
          if (i == diffsEndLineNumber - 1) {
            const lineLength = rightModel.getLineLength(i)

            if (oneVersionContent.indexOf(constants.LINE_BREAK) < 0) {
            // 1つのバージョンに単行が含まれる場合
              // カンマ処理
              if (lineContentCommaFlag) {
                oneVersionContent = `${constants.LINE_BREAK}${spacesInLineBeginSingleLine}${oneVersionContent}`
              } else {
                oneVersionContent = `${constants.COMMA}${constants.LINE_BREAK}${spacesInLineBeginSingleLine}${oneVersionContent}`
              }
            } else {
            // 1つのバージョンに複数行が含まれる場合
              oneVersionContent = ''
              for (let oneVersionline of oneVersionlines) {
                if (oneVersionline.substring(0, 1) === constants.LEFT_CURLY_BRACKET
                  || oneVersionline.substring(0, 1) === constants.RIGHT_CURLY_BRACKET) {
                  oneVersionline =  `${spacesInLineBeginSingleLine}${oneVersionline}`
                } else {
                  oneVersionline =  `${spacesInLineBeginMultiLines}${oneVersionline}`
                }

                if (oneVersionContent.length == 0) {
                  oneVersionContent = `${oneVersionContent}${oneVersionline}`
                } else {
                  oneVersionContent = `${oneVersionContent}${constants.LINE_BREAK}${oneVersionline}`
                }
              }

              // カンマ処理
              if (lineContentCommaFlag) {
                oneVersionContent = `${constants.LINE_BREAK}${oneVersionContent}`
              } else {
                oneVersionContent = `${constants.COMMA}${constants.LINE_BREAK}${oneVersionContent}`
              }
            }

            // 挿入されるデータ行のカンマ処理
            let oneVersionContentRtrim = oneVersionContent.replace(/(\s*$)/g, "")
            if (oneVersionContentRtrim.substr(-1) == constants.COMMA) {
              oneVersionContentRtrim = oneVersionContentRtrim.substring(0, oneVersionContentRtrim.length - 1)
            }

            // 直接挿入されるコピー対象エントリ
            const insertLineNumber = i
            // Rangeを定義する
            const insertRange = new monaco.Range(insertLineNumber, lineLength + 1, insertLineNumber, lineLength + 1)
            rightEditor.updateOptions({readOnly: false})
            // 指定した場所にコンテンツを追加
            rightEditor.executeEdits("", [
              {
                range: insertRange,
                text: oneVersionContentRtrim
              }
            ])
            rightEditor.updateOptions({readOnly: true})

            // 変更されたデータ行はハイライト表示
            const decoratorOption = { range: new monaco.Range(i + 1, 1, i + oneVersionContentLength, 1),
              options: {isWholeLine: true, className: constants.HIGHLIGHTED_COLORS[1]} }
            this.prodDecoratorOptions.push(decoratorOption)

            break
          }
        } else {
        // 1つのバージョンに複数行が含まれる場合
          mutiLineContentList.push(i)
          mutiLineContent = `${mutiLineContent}${lineContent}`
        }

        // 複数行のデータをすべて取り出しているかどうかを判断する
        if (mutiLineContent.indexOf(constants.LEFT_CURLY_BRACKET) >= 0
          && mutiLineContent.indexOf(constants.RIGHT_CURLY_BRACKET) >= 0) {

          lineContent = mutiLineContent

          // 複数行の行数
          const lineContentLines = mutiLineContentList.length

          let lineContentRtrim = lineContent.replace(/(\s*$)/g, "")
          if (lineContentRtrim.substr(-1) == constants.COMMA) {
            lineContentCommaFlag = true
            lineContentRtrim = lineContentRtrim.substring(0, lineContentRtrim.length - 1)
          }

          const lineJson = JSON.parse(lineContentRtrim)
          const lineVer = lineJson[constants.ITEMS_IN_MAPS[0]]

          // 直接挿入されるコピー対象エントリ
          if (leftLineVer < lineVer) {
            oneVersionContent = `${constants.LINE_BREAK}${spacesInLineBeginSingleLine}${oneVersionContent}`

            // 直接挿入されるコピー対象エントリ
            const lineLength = rightModel.getLineLength(i - 1)
            // 直接挿入されるコピー対象の行番号
            const insertLineNumber = i
            // Rangeを定義する
            const insertRange = new monaco.Range(insertLineNumber - 1, lineLength + 1, insertLineNumber - 1, lineLength + 1)
            rightEditor.updateOptions({readOnly: false})
            // 指定した場所にコンテンツを追加
            rightEditor.executeEdits("", [
              {
                range: insertRange,
                text: oneVersionContent
              }
            ])
            rightEditor.updateOptions({readOnly: true})

            // 変更されたデータ行はハイライト表示
            const decoratorOption = { range: new monaco.Range(i, 1, i + oneVersionContentLength - 1, 1),
              options: {isWholeLine: true, className: constants.HIGHLIGHTED_COLORS[1]} }
            this.prodDecoratorOptions.push(decoratorOption)

            break
          }

          // 上書き対象(ver同じでエントリ値が異なる場合)
          if (leftLineVer === lineVer) {
            const lineLength = rightModel.getLineLength(i)

            let oneVersionContentRtrim = oneVersionContent.replace(/(\s*$)/g, "")
            if (i == diffsEndLineNumber - 1 && oneVersionContentRtrim.substr(-1) == constants.COMMA) {
              // 末尾カンマを消す
              oneVersionContentRtrim = oneVersionContentRtrim.substring(0, oneVersionContentRtrim.length - 1)
            }

            // コピーデータ行は単行と複数行
            if (oneVersionContent.indexOf(constants.LINE_BREAK) < 0) {
            // 1つのバージョンに単行が含まれる場合
              oneVersionContent = `${spacesInLineBeginSingleLine}${oneVersionContentRtrim}`
            } else {
            // 1つのバージョンに複数行が含まれる場合
              oneVersionContent = ''
              for (let oneVersionline of oneVersionlines) {
                if (oneVersionline.substring(0, 1) === constants.LEFT_CURLY_BRACKET
                  || oneVersionline.substring(0, 1) === constants.RIGHT_CURLY_BRACKET) {
                  oneVersionline =  `${spacesInLineBeginSingleLine}${oneVersionline}`
                } else {
                  oneVersionline =  `${spacesInLineBeginMultiLines}${oneVersionline}`
                }

                if (oneVersionContent.length == 0) {
                  oneVersionContent = `${oneVersionContent}${oneVersionline}`
                } else {
                  oneVersionContent = `${oneVersionContent}${constants.LINE_BREAK}${oneVersionline}`
                }
              }
            }

            // 上書き対象の行番号
            const insertLineNumber = i
            // Rangeを定義する、上書き対象
            const insertRange = new monaco.Range(insertLineNumber - lineContentLines + 1, 1, insertLineNumber, lineLength + 1)
            rightEditor.updateOptions({readOnly: false})
            // 指定した場所にコンテンツを上書き
            rightEditor.executeEdits("", [
              {
                range: insertRange,
                text: oneVersionContent
              }
            ])
            rightEditor.updateOptions({readOnly: true})

            // 変更されたデータ行はハイライト表示
            const decoratorOption = { range: new monaco.Range(i - lineContentLines + 1, 1, i - lineContentLines + oneVersionContentLength, 1),
              options: {isWholeLine: true, className: constants.HIGHLIGHTED_COLORS[0]} }
            this.prodDecoratorOptions.push(decoratorOption)

            break
          }

          // 直接挿入されるコピー対象エントリ(maps配下の末尾に追加)
          if (i == diffsEndLineNumber - 1) {
            const lineLength = rightModel.getLineLength(i)

            if (oneVersionContent.indexOf(constants.LINE_BREAK) < 0) {
            // 1つのバージョンに単行が含まれる場合
              // カンマ処理
              if (lineContentCommaFlag) {
                oneVersionContent = `${constants.LINE_BREAK}${spacesInLineBeginSingleLine}${oneVersionContent}`
              } else {
                oneVersionContent = `${constants.COMMA}${constants.LINE_BREAK}${spacesInLineBeginSingleLine}${oneVersionContent}`
              }
            } else {
            // 1つのバージョンに複数行が含まれる場合
              oneVersionContent = ''
              for (let oneVersionline of oneVersionlines) {
                if (oneVersionline.substring(0, 1) === constants.LEFT_CURLY_BRACKET
                  || oneVersionline.substring(0, 1) === constants.RIGHT_CURLY_BRACKET) {
                  oneVersionline =  `${spacesInLineBeginSingleLine}${oneVersionline}`
                } else {
                  oneVersionline =  `${spacesInLineBeginMultiLines}${oneVersionline}`
                }

                if (oneVersionContent.length == 0) {
                  oneVersionContent = `${oneVersionContent}${oneVersionline}`
                } else {
                  oneVersionContent = `${oneVersionContent}${constants.LINE_BREAK}${oneVersionline}`
                }
              }

              // カンマ処理
              if (lineContentCommaFlag) {
                oneVersionContent = `${constants.LINE_BREAK}${oneVersionContent}`
              } else {
                oneVersionContent = `${constants.COMMA}${constants.LINE_BREAK}${oneVersionContent}`
              }
            }

            // 挿入されるデータ行のカンマ処理
            let oneVersionContentRtrim = oneVersionContent.replace(/(\s*$)/g, "")
            if (oneVersionContentRtrim.substr(-1) == constants.COMMA) {
              oneVersionContentRtrim = oneVersionContentRtrim.substring(0, oneVersionContentRtrim.length - 1)
            }

            // 直接挿入されるコピー対象エントリ
            const insertLineNumber = i
            // Rangeを定義する
            const insertRange = new monaco.Range(insertLineNumber, lineLength + 1, insertLineNumber, lineLength + 1)
            rightEditor.updateOptions({readOnly: false})
            // 指定した場所にコンテンツを追加
            rightEditor.executeEdits("", [
              {
                range: insertRange,
                text: oneVersionContentRtrim
              }
            ])
            rightEditor.updateOptions({readOnly: true})

            // 変更されたデータ行はハイライト表示
            const decoratorOption = { range: new monaco.Range(i + 1, 1, i + oneVersionContentLength, 1),
              options: {isWholeLine: true, className: constants.HIGHLIGHTED_COLORS[1]} }
            this.prodDecoratorOptions.push(decoratorOption)

            break
          }

          // 再度初期化
          mutiLineContent = ''
          mutiLineContentList = new Array()
        }
      }
    }
  }
}
</script>

<style>
.wrapper {
  display: grid;
  grid-template-columns: 47.3% 5.4% 47.3%;
}
#files-select {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  margin: 10px 0;
}
.label-repository-no-top {
  display: inline-block;
  margin-left: 2px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 16px;
  background-color: #ffbf00;
}
.label-repository-has-top {
  display: inline-block;
  margin-left: 2px;
  margin-top: 15px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 16px;
  background-color: #ffbf00;
}
.label-difference-warning {
  display: inline-block;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 16px;
  background-color: #ffbf00;
}
.label-warnings {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 16px;
  background-color: #ffbf00;
}
.label-branch-box {
  display: inline-block;
  width: 85px;
  margin-top: 0px;
  vertical-align: middle;
  text-align: center;
  line-height: 0px;
  background-color: #ffbf00;
}
.div-margin-top {
  margin-top: 55px;
}
.label-conf-box {
  display: inline-block;
  width: 85px;
  margin-top: 0px;
  vertical-align: middle;
  text-align: center;
  line-height: 0px;
  background-color: #d3d3d3
}
.label-warning-box {
  display: inline-block;
  width: 240px;
  margin-top: 0px;
  vertical-align: middle;
  text-align: center;
  line-height: 0px;
  background-color: #ffbf00;
}
.label-branch {
  display: inline-block;
  margin-top: 15px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 16px;
}
.div-prod-file {
  margin-left: 40px;
}
.label-prod-file {
  margin-left: 315px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 16px;
  background-color: #ffbf00;
}
.inline-block {
  display: inline-block;
  margin-left: 10px;
}
.el-button--primary.is-active,
.el-button--primary:active {
  background: #20B2AA;
  border-color: #20B2AA;
  color: #fff;
}
.el-button--primary {
  color: #FFF;
  background-color: #20B2AA;
  border-color: #20B2AA;
}
.el-button--primary.el-button--small, .el-button--warning.el-button--small, .el-button--success.el-button--small,  .el-button--info.el-button--small{
  font-size: 16px;
}
.input-file-path>.el-input__inner {
  display: inline;
  width: 400px;
  height: 31px;
  margin-left: 5px;
  margin-top: 7px;
  font-size: 16px;
}
.input-repository-path>.el-input__inner {
  display: inline;
  width: 514px;
  height: 31px;
  margin-left: 5px;
  margin-top: 7px;
  font-size: 16px;
}
.input-branch>.el-input__inner {
  display: inline;
  width: 400px;
  height: 31px;
  margin-left: 5px;
  margin-top: 7px;
  font-size: 16px;
}
#files-select .el-table .cell {
  white-space: pre-wrap;
  text-align : left;
  font-size: 12px;
  color:red;
  line-height: 1.5;
  padding-left: 0px;
}
#files-select .el-table__row td {
  vertical-align: top;
  max-height: 500px;
  overflow: auto;
}
#files-select .el-table__body tr.current-row > td {
  background-color: #add8e6 !important;
  color: blue;
}
.greenDecorator {
  background: #458B00;
  font-style: oblique;
}
.darkRedDecorator {
  background: #A0522D;
  font-style: oblique;
}
.sizeover-textarea-style>.el-textarea__inner {
  resize: none;
  width: 100%;
  -webkit-text-fill-color: red;
}
.difference-textarea-style>.el-textarea__inner {
  resize: none;
  width: 100%;
  -webkit-text-fill-color: red;
}
.custom-icon {
  font-size: 1rem;
}
</style>