<template>
  <div>
    <div id="files-select">
      <!-- repository -->
      <div>
        <div class="label-branch-box">
          <p class="label-branch">repository</p>
        </div>
        <el-input
          id="repository-path"
          v-model="repository.name"
          class="input-repository-path"
          style="display:inline"
          readonly>
        </el-input>
      </div>

      <!-- ブランチ -->
      <div>
        <div class="label-branch-box">
          <p class="label-branch">branch</p>
        </div>
        <el-input
          id="branch-name"
          v-model="branch.name"
          class="input-branch"
          style="display:inline"
          readonly>
        </el-input>
      </div>

      <!-- pp confファイル -->
      <div>
        <div class="label-conf-box">
          <p class="label-branch">pp conf</p>
        </div>
        <el-input
          id="pp-file-path"
          v-model="ppFile.name"
          class="input-pp-path"
          style="display:inline"
          readonly>
        </el-input>
      </div>
    </div>

    <!-- 更新前/更新後 -->
    <el-row>
      <el-col :span="11" :lg="12" type="flex" align="left">
        <div>
           <label class="label-update">更新前</label>
        </div>
      </el-col>
      <el-col :span="13" :lg="12" type="flex" align="left">
        <div>
          <label style="margin: 0px -20px" class="label-update">更新後</label>
        </div>
      </el-col>
    </el-row>

    <!-- テキストエディター -->
    <div>
      <monaco
        ref="MonacoEditor"
        :opts="opts"
        :isDiff="isDiff"
        :height="68"
        :width="100"
      ></monaco>
    </div>

    <el-row :gutter="15">
      <!-- 戻るボタン -->
      <el-col :span="12" :lg="12" type="flex" align="right">
        <div>
          <el-button
            style="margin: 10px 20px; width: 20%;"
            type="warning"
            size="small"
            @click="back"
            >戻る<i class="el-icon-back el-icon--right"></i></el-button>
        </div>
      </el-col>

      <!-- ダウンロードボタン -->
      <el-col :span="12" :lg="12" type="flex" align="left">
        <div>
          <el-button
            style="margin: 10px 0px"
            type="primary"
            size="small"
            @click="download"
            >ダウンロード<i class="el-icon-download el-icon--right"></i></el-button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Vue from "vue"
import { constants } from '@/common/constants'
import monaco from '@/components/MonacoDiffEditor'

Vue.prototype.$PP_CONFIRM_COMPONENT_NAME = 'PpConfirm'

export default {
  components: { monaco },
  data () {
    return {
      // monacoEditor配置項
      opts: {
        value: '',
        readOnly: false,
        language: 'json',
        theme: 'vs-dark',
        automaticLayout: true
      },
      isDiff: true,
      oldValue: '',
      newValue: '',
      float: 'float: none',

      // repository URL
      repository: {'name': constants.REPOSITORY_URL},
      // ブランチ名
      branch: {'name': constants.BRANCH_NAME},
      // pp conf
      ppFile: {'name': constants.PP_CONF_PATH}
    }
  },
  mounted () {
  },
  methods: {
    // pp confに変更後内容をダウンロード
    download() {
      // pp confに変更後内容
      this.newValue = this.$route.params.rightEditorNewValue

      // ダウンロード時のファイル名
      const downloadFileName = this.$route.params.downloadFileName
      // ハイパーリンク
      const link = document.createElement('a')
      // ダウンロードファイルの内容
      const file = new Blob([this.newValue], {type: 'application/json'})

      link.href = URL.createObjectURL(file)
      // ダウンロード時のファイル名
      link.download = downloadFileName
      link.style.display = 'none'
      // ダウンロード動作
      link.click()
    },
    // 戻るボタンを押下
    back() {
      Vue.$log.info('Confirm.vue')

      // 画面遷移
      this.$router.push({ name: this.$PP_UPDATE_COMPONENT_NAME, params: {
        rightEditorOldValue: this.$route.params.rightEditorOldValue,
        rightEditorNewValue: this.$route.params.rightEditorNewValue,
        ppDecoratorOptions: this.$route.params.ppDecoratorOptions,
        downloadFileName: this.$route.params.downloadFileName,
        isShowSizeOver: this.$route.params.isShowSizeOver,
        sizeOverWarning: this.$route.params.sizeOverWarning
      } })
    }
  }
}
</script>

<style>
#download {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: right;
  color: #2c3e50;
  margin: 10px 0;
}
.label-update {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 16px;
}
.label-conf-box {
  display: inline-block;
  width: 85px;
  margin-top: 0px;
  vertical-align: middle;
  text-align: center;
  line-height: 0px;
  background-color: #d3d3d3
}
.input-pp-path>.el-input__inner {
  display: inline;
  width: 400px;
  height: 31px;
  margin-left: 5px;
  margin-top: 7px;
  font-size: 16px;
}
</style>