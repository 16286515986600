import Vue from 'vue'
import VueRouter from 'vue-router'
import ProdUpdate from '../views/prod/Update.vue'
import ProdConfirm from '../views/prod/Confirm.vue'
import PpUpdate from '../views/pp/Update.vue'
import PpConfirm from '../views/pp/Confirm.vue'
import Cid from '../views/cid/Cid.vue'
import CidGetUpdate from '../views/cid/CidGetUpdate.vue'
import CidInit from '../views/cid/CidInit.vue'
import CidRestore from '../views/cid/CidRestore.vue'

Vue.use(VueRouter)

const routes = [
  // prod向け設定ファイル更新画面
  {
    path: '/prod/update',
    name: 'ProdUpdate',
    component: ProdUpdate,
    pathToRegexpOptions: { strict: true }
  },
  // prod向け設定ファイル確認画面
  {
    path: '/prod/update/confirm',
    name: 'ProdConfirm',
    component: ProdConfirm,
    pathToRegexpOptions: { strict: true }
  },
  // pp向け設定ファイル更新画面
  {
    path: '/pp/update',
    name: 'PpUpdate',
    component: PpUpdate,
    pathToRegexpOptions: { strict: true }
  },
  // pp向け設定ファイル確認画面
  {
    path: '/pp/update/confirm',
    name: 'PpConfirm',
    component: PpConfirm,
    pathToRegexpOptions: { strict: true }
  },
  // CID画面
  {
    path: '/cid/',
    name: 'Cid',
    component: Cid,
    pathToRegexpOptions: { strict: true },
    children: [
      // CID情報取得・更新機能
      {
        path: 'update',
        name: 'CidGetUpdate',
        component: CidGetUpdate,
        pathToRegexpOptions: { strict: true }
      },
      // CID初期化
      {
        path: 'init',
        name: 'CidInit',
        component: CidInit,
        pathToRegexpOptions: { strict: true }
      },
      // CID復旧
      {
        path: 'restore',
        name: 'CidRestore',
        component: CidRestore,
        pathToRegexpOptions: { strict: true }
      }
    ]
  },
]

// vue routerのプロパティ値を設定
const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes
})

export default router
