<template>
  <div>
    <div class="container">
      <!-- repository -->
      <div class="item-repository">
        <div class="label-branch-box">
          <p class="label-branch">repository</p>
        </div>
        <el-input
          id="repository-path"
          v-model="repository.name"
          class="input-repository-path"
          style="display:inline"
          readonly>
        </el-input>
      </div>

      <!-- ブランチ -->
      <div class="item-branch">
        <div class="label-branch-box">
          <p class="label-branch">branch</p>
        </div>
        <el-input
          id="branch-name"
          v-model="branch.name"
          class="input-branch"
          style="display:inline"
          readonly>
        </el-input>
      </div>

      <!-- pp confファイル -->
      <div class="item-pp-conf">
        <div class="label-conf-box">
          <p class="label-branch">pp conf</p>
        </div>
        <el-input
          id="pp-file-path"
          v-model="ppFile.name"
          class="input-file-path"
          style="display:inline"
          readonly>
        </el-input>
      </div>

      <!-- サイズオーバー警告情報が存在すること -->
      <div class="item-warning">
        <el-form>
          <el-row>
            <div v-show="isShowSizeOver">
              <el-col :span="24" style="height:35px;">
                <el-form-item >
                  <div class="label-warning-box">
                    <p class="label-branch">【警告】サイズオーバー</p>
                  </div>
                </el-form-item>
              </el-col>
            </div>
          </el-row>

          <el-row style="margin-top: 4px;">
            <div v-show="isShowSizeOver">
              <el-col :span="24">
                <el-form-item >
                  <el-input
                    type="textarea"
                    v-model="sizeOverWarning"
                    class="sizeover-textarea-style"
                    size="large"
                    :rows="4"
                    disabled
                    readonly></el-input>
                </el-form-item>
              </el-col>
            </div>
          </el-row>
        </el-form>
      </div>
    </div>

    <div id="files-select">
      <!-- チェックエラー情報 -->
      <el-table :data="allErrorMessages" :show-header="false" empty-text=" " :style="styleType">
        <el-table-column
          prop="leftError"
          min-width="100%"
          label="">
        </el-table-column>
      </el-table>
    </div>

    <!-- テキストエディター -->
    <div style="margin-top: 15px;">
      <MonacoEditor
        ref="MonacoEditor"
        :opts="opts"
        :isDiff="isDiff"
        :height="70"
        :width="100"
      ></MonacoEditor>
    </div>

    <el-row :gutter="15">
       <!-- 更新ボタン -->
      <el-col :span="12" :lg="12" type="flex" align="right">
        <div>
          <el-button
            style="margin: 10px 20px; width: 15%;"
            type="warning"
            size="small"
            :disabled="btnUpdateDisable"
            @click="updateDiffs"
            >更新<i class="el-icon-refresh el-icon--right"></i></el-button>
        </div>
      </el-col>
      <!-- 確認画面へボタン -->
      <el-col :span="12" :lg="12" type="flex" align="left">
        <div>
          <el-button
          style="margin: 10px 0px"
          type="success"
          size="small"
          :disabled="btnConfirmDisable"
          @click="goToConfirm"
          >確認画面へ</el-button>
        </div>
      </el-col>
    </el-row>

  </div>

</template>

<script>
import Vue from "vue"
import axios from 'axios'
import * as monaco from 'monaco-editor'
import MonacoEditor from '@/components/MonacoNoDiffEditor'
import { constants } from '@/common/constants'
import { messages } from '@/common/messages'
import commonUtil from '@/common/commonUtil'

Vue.prototype.$PP_UPDATE_COMPONENT_NAME = 'PpUpdate'

export default {
  components: { MonacoEditor },
  data () {
    return {
      // monacoEditor配置項
      decorator: [],
      isDiff: false,
      opts: {
        value: '',
        readOnly: true,
        language: 'json',
        theme: 'vs-dark',
        cursorStyle: 'block',
        contextmenu: false,
        scrollbar:{
          arrowSize: 15,
          horizontal: false,
          useShadows: false,
          vertical: true,
          verticalScrollbarSize: 15
        },
        minimap: {
          enabled: false
        },
        automaticLayout: true
      },
      oldValue: '',
      newValue: '',
      // エディタ
      editor: '',

      // repositoryのid
      projectId: 0,
      // repository URL
      repository: {'name': constants.REPOSITORY_URL},
      // ブランチ名
      branch: {'name': constants.BRANCH_NAME},
      // pp conf
      ppFile: {'name': constants.PP_CONF_PATH},


      // ----------------------ハイライト表示----------------------------
      // test_entry配下のハイライト表示番号
      testEntryHighlightedlines: [],
      // ppファイルのハイライトカラー
      ppDecoratorOptions: [],
      // ----------------------ハイライト表示----------------------------

      // ----------------------警告情報----------------------------------
      // サイズオーバー警告情報
      sizeOverWarning: '',
      // サイズオーバー警告情報表示フラグ
      isShowSizeOver: false,
      // ----------------------警告情報----------------------------------

      // ----------------------error------------------------------------
      errorMessagesArray: [],
      allErrorMessages: [],
      seqs: JSON.parse(JSON.stringify(constants.ERROR_MESSAGES_SEQ_FOR_ORDER)),
      stepSize: 0.001,
      // ppファイルが存在しない
      fileNoExist: false,
      // ----------------------error------------------------------------

      // ppファイルのステータス(commonUtilで使う)
      ppCheckStatus: false,
      // prodファイルのステータス(commonUtilで使う)、仮想的
      prodCheckStatus: true,

      // 更新ボタン状態
      btnUpdateDisable: true,
      // 確認画面へボタン状態
      btnConfirmDisable: true,

      // 確認画面でダウンロード時のファイル名
      downloadFileName: '',

      // error: css style
      styleType: 'display: none'
    }
  },
  created () {
    Vue.$log.info('Update.vue')

    const leftEditorErrors = []
    const rightEditorErrors = []

    this.allErrorMessages = [{
      leftError: leftEditorErrors.join(constants.LINE_BREAK),
      rightError: rightEditorErrors.join(constants.LINE_BREAK)
    }]
  },
  mounted () {
    Vue.$log.info('Update.vue')

    // エディタを取得する
    this.editor = this.$refs.MonacoEditor.monacoEditor

    commonUtil.sendThis(this)

    // 更新画面へ戻るときに更新内容を保持する
    if (Object.keys(this.$route.params).length > 0) {
      this.oldValue = this.$route.params.rightEditorOldValue
      this.newValue = this.$route.params.rightEditorNewValue
      this.downloadFileName = this.$route.params.downloadFileName

      this.editor.setValue(this.$route.params.rightEditorNewValue)

      // pp confファイルの更新対象データ行はハイライト表示
      this.decorator = this.editor.deltaDecorations(this.decorator,
        this.$route.params.ppDecoratorOptions)
      // 更新データ
      this.ppDecoratorOptions = this.$route.params.ppDecoratorOptions

      // 確認ボタンが活性になること
      this.btnConfirmDisable = false
      // 更新ボタンが非活性であること
      this.btnUpdateDisable = true

      // 警告情報
      this.isShowSizeOver = this.$route.params.isShowSizeOver
      this.sizeOverWarning = this.$route.params.sizeOverWarning

      // 選択したファイルの名前を取得する
      this.ppFile.name = constants.PP_CONF_PATH
    } else {
      // 初期化
      this.init()
    }
  },
  methods: {
    // gitlabリポジトリIDを取得する
    async getRepositoryId() {
      const repositoryArray = constants.REPOSITORY_URL.split('/')

      // リポジトリ名
      const repositoryName = repositoryArray[repositoryArray.length - 1]

      // gitlabリポジトリURL
      const projectApiUrl = constants.PROJECT_API_URL.replace('[0]', repositoryName)

      // gitlab apiサービスコール
      await axios.get(projectApiUrl, {
        headers: {
          'Content-Type': constants.CONTENT_TYPE,
          'PRIVATE-TOKEN': constants.GITLAB_ACCESS_TOKEN
        }
      })
      .then((response) => {
        const projects = response.data

        for (const project of projects) {
          // 使用したリポジトリ名とリポジトリURLで結果内を完全一致に検索する
          if (project.name === repositoryName && project.web_url === constants.REPOSITORY_URL) {
            // リポジトリIDを取得する
            this.projectId = project.id

            return
          }
        }
      })
      .catch((error) => {
        Vue.$log.info('-----------------gitlabリポジトリID取得失敗の場合---------------------')
        Vue.$log.info(error.message)
      })
    },
    // 初期化
    async init() {
      // pp confファイルパス 
      const ppConfPath = constants.PP_CONF_PATH.replaceAll('/', '%2F')

      const ppConfArray = constants.PP_CONF_PATH.split('/')
      //ダウンロード時のファイル名
      this.downloadFileName = ppConfArray[ppConfArray.length - 1]

      // gitlabリポジトリIDを取得する
      await this.getRepositoryId()

      if (this.projectId == 0) {
        // 失敗の場合: repositoryへのアクセスに失敗しました
        this.$alert(messages.REPOSITORY_ACCESS_ERROR, '提示', {
          confirmButtonText: 'OK',
          dangerouslyUseHTMLString: true,
          type: 'error'
        }).then(() => {
        }).catch(() => {
        })

        return
      }

      // gitlabにファイル内容取得apiのurl
      let ppApiUrl = constants.FILE_API_URL.replace('[0]', this.projectId)
        .replace('[1]', ppConfPath)
        .replace('[2]', this.branch.name)

      // gitlab apiサービスコール
      await axios.get(ppApiUrl, {
        headers: {
          'Content-Type': constants.CONTENT_TYPE,
          'PRIVATE-TOKEN': constants.GITLAB_ACCESS_TOKEN
        }
      })
      .then((response) => {
        // レスポンス内容解析
        const responseBase64Content = response.data.content
        const responseContent = new Buffer.from(responseBase64Content, 'base64')
        let editorContent = responseContent.toString()

        // エディタにデータを正常に表示する
        this.editor.setValue(editorContent)

        // エディタのモデルを取得する
        let model = this.editor.getModel()

        // サイズオーバー警告情報を設定する
        const type = 'update'
        commonUtil.setWarningMessages(editorContent, model, type)

        // エラー情報を表示する
        commonUtil.showErrorMessages(constants.CHECK_FILE_NAME[0], editorContent, false, constants.CHECK_FILE_NAME[0])

        // pp confファイルの更新対象データ行を取得
        this.getUpdateData(model)

        // チェックステータス
        let isCheckError = false
        if (this.allErrorMessages[0].leftError.length > 0) {
          isCheckError = true
        }
        if (!isCheckError) {
          if (this.testEntryHighlightedlines.length > 0) {
            // 更新ボタンが活性にする
            this.btnUpdateDisable = false

            // pp confファイルのコピー対象データ行はハイライト表示
            this.decorator = this.editor.deltaDecorations(this.decorator, this.ppDecoratorOptions)
          } else {
          // 更新対象がない、エラーメッセージが表示されること
            Vue.$log.info('-----------------更新対象がない---------------------')

            let noCopyDataError = messages.NO_UPDATE_DATA_ERROR.replace('[0]', constants.PP_CHECK_OBJECTS[1])

            // エラー情報のタイトル
            this.errorMessagesArray.push({'seq': this.seqs['release'], 'error': constants.CHECK_TYPE[9]})
            this.seqs['release'] += this.stepSize
            // エラー情報
            this.errorMessagesArray.push({'seq': this.seqs['release'], 'error': noCopyDataError})

            // エラー情報の並べ替え
            let errorMessages = commonUtil.objectArraySort(this.errorMessagesArray, 'seq', 'error')

            this.allErrorMessages = [{
              leftError: errorMessages.join(constants.LINE_BREAK),
              rightError: ''
            }]

            // エラーメッセージが表示されること
            this.styleType = 'display: block'
          }
        }
      })
      .catch((error) => {
        Vue.$log.info('-----------------pp設定ファイル取得失敗の場合---------------------')
        Vue.$log.info(error)
        Vue.$log.info(error.response)

        if (typeof(error.response) == 'undefined') {
          let errorMessage = messages.CONF_FILE_ACCESS_ERROR.replace('[0]', error)

          this.$alert(`${errorMessage}`, '提示', {
            confirmButtonText: 'OK',
            dangerouslyUseHTMLString: true,
            type: 'error'
          }).then(() => {
          }).catch(() => {
          })
        } else {
          // 設定ファイルが存在しない
          if (error.response.data.message == messages.API_CONF_FILE_NOT_EXIST_ERROR) {
            commonUtil.showFileExistErrorMessages(constants.CHECK_FILE_NAME[0])

            return
          }

          let errorMessage = messages.CONF_FILE_ACCESS_ERROR.replace('[0]', error.response.data.message)
          if (error.response.data.error.length > 0) {
            errorMessage = messages.CONF_FILE_ACCESS_ERROR.replace('[0]', error.response.data.error)
          }

          this.$alert(`${errorMessage}`, '提示', {
            confirmButtonText: 'OK',
            dangerouslyUseHTMLString: true,
            type: 'error'
          }).then(() => {
          }).catch(() => {
          })
        }

        return
      })
    },
    // 確認画面へボタン押下時、画面遷移
    goToConfirm() {
      this.newValue = this.editor.getValue()

      // 画面遷移
      this.$router.push({ name: this.$PP_CONFIRM_COMPONENT_NAME, params: {
        rightEditorOldValue: this.oldValue,
        rightEditorNewValue: this.newValue,
        ppDecoratorOptions: this.ppDecoratorOptions,
        downloadFileName: this.downloadFileName,
        isShowSizeOver: this.isShowSizeOver,
        sizeOverWarning: this.sizeOverWarning
      } })
    },
    // 更新ボタンを押下
    updateDiffs() {
      Vue.$log.info('updateDiffs')

      // エディタのモデルを取得する
      let model = this.editor.getModel()
      // エディタ更新前の値を保存する
      this.oldValue = this.editor.getValue()

      // jsonファイルのdiffs配下のマッチング: 開始行位置、終了行位置
      // 開始行位置
      let diffsStartLineNumber
      // 「diffs」をマッチ
      const diffsLineMatch = model.findMatches(`"${constants.PP_CHECK_OBJECTS[2]}"`)
      if (diffsLineMatch.length > 0) {
        const diffsLineMatchRange = diffsLineMatch[0].range
        diffsStartLineNumber = diffsLineMatchRange.startLineNumber
      }
      const isRegexp= false
      const startPos = {lineNumber: diffsStartLineNumber, column: 1}
      const bracketLineMatch = model.findNextMatch(constants.RIGHT_SQUARE_BRACKET, startPos, isRegexp, false, null, false)
      const bracketLineMatchRange = bracketLineMatch.range
      // 終了行位置
      let diffsEndLineNumber = bracketLineMatchRange.startLineNumber

      // ppファイルのdiffsにある内容を基準に揃えて表示し、フレーム数を判定する
      // 左中括弧が出る位置
      let leftCurlyBracketPos = 0
      for (let i = 0; i <= diffsEndLineNumber; i++) {
        const lineContent = model.getLineContent(diffsEndLineNumber - i)

        if (lineContent.indexOf(constants.LEFT_CURLY_BRACKET) > 0) {
          leftCurlyBracketPos = lineContent.indexOf(constants.LEFT_CURLY_BRACKET)
          break
        }
      }
      // スペース数を取得する
      const spacesNumber = leftCurlyBracketPos + 1
      // 1レコードデータ（単行）場合
      const spacesInLineBeginSingleLine = new Array(spacesNumber).join(' ')
      // 1レコードデータ（複数行）場合
      const spacesInLineBeginMultiLine = new Array(spacesNumber + 2).join(' ')

      // 1. diffs配下のエントリを削除
      let deleteRange
      if (diffsEndLineNumber == diffsStartLineNumber + 2) {
        const deleteLineNumber = diffsStartLineNumber + 1
        // Rangeを定義する
        deleteRange = new monaco.Range(deleteLineNumber, 1, deleteLineNumber + 1, 1)

        // エディタを編集可能に設定する
        this.editor.updateOptions({readOnly: false})
        // 指定行を削除する
        this.editor.executeEdits("", [
          {
            range: deleteRange,
            text: null,
            forceMoveMarkers: true
          }
        ])
        // エディタを編集不可に設定する
        this.editor.updateOptions({readOnly: true})
      }

      // 2. 更新対象データ行をdiffs配下の適切な位置にcopyされること
      // 更新対象データ行
      let insertLines = ''

      // test_entryの更新対象データ行の行番号を取得
      let matchLineNumbers = new Array()
      for (let testEntryHighlightedline of this.testEntryHighlightedlines) {
        for (let lineNumber of testEntryHighlightedline.lineNumbers) {
          matchLineNumbers.push(lineNumber)
        }
      }

      for (const matchLineNumber of matchLineNumbers) {
        // 文字列の先頭と末尾のスペースを取り除く
        const insertLineTrim = model.getLineContent(matchLineNumber).replace(/^\s*|\s*$/g, '')

        // 挿入行の内容を取得する(適切なスペース)
        let insertLine = `${spacesInLineBeginMultiLine}${insertLineTrim}`
        if (insertLineTrim.substr(0, 1) == constants.LEFT_CURLY_BRACKET
          || insertLineTrim.substr(0, 1) == constants.RIGHT_CURLY_BRACKET) {
          insertLine = `${spacesInLineBeginSingleLine}${insertLineTrim}`
        }

        // 行累積
        insertLines = `${insertLines}${insertLine}${constants.LINE_BREAK}`
      }

      // Rangeを定義する、挿入位置
      const insertRange = new monaco.Range(diffsStartLineNumber + 1, 1, diffsStartLineNumber + 1, 1)

      // エディタを編集可能に設定する
      this.editor.updateOptions({readOnly: false})
      // 内容を挿入する
      this.editor.executeEdits("", [
        {
          range: insertRange,
          text: insertLines
        }
      ])
      // エディタを編集不可に設定する
      this.editor.updateOptions({readOnly: true})

      // 3. エディターの内容を復元する
      // エディターの内容を取得する
      let editorNewValue = this.editor.getValue()
      // 内容を復元する
      this.editor.setValue(this.oldValue)
      // モデルを取得する
      model = this.editor.getModel()

      // 4. 更新後の内容をチェックする
      // エラー情報を表示する
      const checkResult = commonUtil.showErrorMessages(constants.CHECK_FILE_NAME[0], editorNewValue,
        true, constants.CHECK_FILE_NAME[0])

      // ppファイルにエラー情報がない
      if (!checkResult) {
        // 上記チェックOKの場合、更新確認メッセージを表示
        this.$confirm(messages.UPDATE_FOR_CONFIRM, '提示', {
          confirmButtonText: '確定',
          cancelButtonText: 'キャンセル',
          dangerouslyUseHTMLString: true,
          closeOnClickModal: false,
          type: 'warning'
        }).then(() => {
          // エディタにデータを正常に表示する
          this.editor.setValue(editorNewValue)

          const decoratorOption = { range: new monaco.Range(diffsStartLineNumber + 1, 1,
            diffsStartLineNumber + matchLineNumbers.length, 1), options: {
            isWholeLine: true, className: constants.HIGHLIGHTED_COLORS[0]} }
          this.ppDecoratorOptions.push(decoratorOption)

          // pp confファイルの更新対象データ行はハイライト表示
          this.decorator = this.editor.deltaDecorations(this.decorator, this.ppDecoratorOptions)

          // 確認ボタンが活性になること
          this.btnConfirmDisable = false
          // 更新ボタンが非活性であること
          this.btnUpdateDisable = true

          // 更新成功のヒントメッセージ
          this.$message({
            type: 'success',
            message: messages.UPDATE_STATUS_SUCCESS,
            duration: constants.DURATION
          })
        }).catch(() => {
          // 更新ボタンが活性になること
          this.btnUpdateDisable = false

          // 更新キャンセルのヒントメッセージ
          this.$message({
            type: 'info',
            message: messages.UPDATE_STATUS_CANCEL,
            duration: constants.DURATION
          })
        })
      }

      // 更新ボタンが非活性であること
      this.btnUpdateDisable = true
    },
    // pp confファイルの更新対象データ行を取得
    getUpdateData(model) {
      // test_entry配下: pp confファイルのコピー対象データ行はハイライト表示
      // test_entry項目マッチ
      const testEntryLineMatch = model.findMatches(`"${constants.PP_CHECK_OBJECTS[1]}"`)
      let testEntryStartLineNumber
      if (testEntryLineMatch.length > 0) {
        const testEntryLineMatchRange = testEntryLineMatch[0].range
        testEntryStartLineNumber = testEntryLineMatchRange.startLineNumber
      }

      // jsonファイルのmapsオブジェクトの終わりの]をマッチ
      const isRegexp = false
      const startPos = {lineNumber: testEntryStartLineNumber, column: 1}
      const bracketLineMatch = model.findNextMatch(constants.RIGHT_SQUARE_BRACKET, startPos, isRegexp, false, null, false)
      const bracketLineMatchRange = bracketLineMatch.range
      const testEntryEndLineNumber = bracketLineMatchRange.startLineNumber

      let mutiLineContent = ''
      let mutiLineContentList = new Array()
      for (let i = testEntryStartLineNumber + 1; i <= testEntryEndLineNumber; i++) {
        // 行内容を取得する
        let lineContent = model.getLineContent(i)

        // 1つのバージョンに複数行が含まれる場合
        if (lineContent.indexOf(constants.LEFT_CURLY_BRACKET) >= 0
          && lineContent.indexOf(constants.RIGHT_CURLY_BRACKET) >= 0) {

          // 文字列の先頭と末尾のスペースを取り除く
          let lineContentRtrim = lineContent.replace(/^\s*|\s*$/g, '')
          if (lineContentRtrim.substr(-1) == constants.COMMA) {
            lineContentRtrim = lineContentRtrim.substring(0, lineContentRtrim.length - 1)
          }

          const lineJson = JSON.parse(lineContentRtrim)
          const lineVer = lineJson[constants.ITEMS_IN_MAPS[0]]
          const lineVersionName = lineJson[constants.ITEMS_IN_MAPS[2]]

          // ver項目が存在しない
          if (typeof(lineVer) == 'undefined') {
            continue
          }
          // ver項目が存在すること
          const lineVersion = lineVer.slice(-5)

          //「"ver": "MMM8M0JP0905202", "name": "5202_6400_s5202"」から
          if (lineVersion < constants.TEST_ENTRY_BASE_VERSION
            || lineVersion === constants.TEST_ENTRY_BASE_VERSION && lineVersionName != constants.TEST_ENTRY_BASE_VERSION_NAME) {
            continue
          }

          const decoratorOption = { range: new monaco.Range(i, 1, i, 1),
            options: {isWholeLine: true, className: constants.HIGHLIGHTED_COLORS[0]} }
          this.ppDecoratorOptions.push(decoratorOption)
          this.testEntryHighlightedlines.push({version: lineVer, lineNumbers: [i]})
        } else {
          mutiLineContentList.push(i)
          mutiLineContent = `${mutiLineContent}${lineContent}`
        }

        // 複数行のデータをすべて取り出しているかどうかを判断する
        if (mutiLineContent.indexOf(constants.LEFT_CURLY_BRACKET) >= 0
          && mutiLineContent.indexOf(constants.RIGHT_CURLY_BRACKET) >= 0) {
          lineContent = mutiLineContent

          // 文字列の先頭と末尾のスペースを取り除く
          let lineContentRtrim = lineContent.replace(/^\s*|\s*$/g, '')
          if (lineContentRtrim.substr(-1) == constants.COMMA) {
            lineContentRtrim = lineContentRtrim.substring(0, lineContentRtrim.length - 1)
          }

          const lineJson = JSON.parse(lineContentRtrim)
          const lineVer = lineJson[constants.ITEMS_IN_MAPS[0]]
          const lineVersionName = lineJson[constants.ITEMS_IN_MAPS[2]]

          // ver項目が存在しない
          if (typeof(lineVer) == 'undefined') {
            continue
          }
          // ver項目が存在すること
          const lineVersion = lineVer.slice(-5)

          //「"ver": "MMM8M0JP0905202", "name": "5202_6400_s5202"」から
          if (lineVersion < constants.TEST_ENTRY_BASE_VERSION
            || lineVersion === constants.TEST_ENTRY_BASE_VERSION && lineVersionName != constants.TEST_ENTRY_BASE_VERSION_NAME) {
            continue
          }

          const decoratorOption = { range: new monaco.Range(Math.min(...mutiLineContentList), 1, Math.max(...mutiLineContentList), 1),
            options: {isWholeLine: true, className: constants.HIGHLIGHTED_COLORS[0]} }
          this.ppDecoratorOptions.push(decoratorOption)
          this.testEntryHighlightedlines.push({version: lineVer, lineNumbers: mutiLineContentList})

          mutiLineContent = ''
          mutiLineContentList = new Array()
        }
      }
    }
  }
}
</script>

<style>
.container {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 40px 40px 40px;
  gap: 2px 0px;
}
.item-repository {
  grid-row-start: 1;
  grid-row-end: 1;
  grid-column-start: 1;
  grid-column-end: 1;
}
.item-branch {
  grid-row-start: 2;
  grid-row-end: 2;
  grid-column-start: 1;
  grid-column-end: 1;
}
.item-pp-conf {
  grid-row-start: 3;
  grid-row-end: 3;
  grid-column-start: 1;
  grid-column-end: 1;
}
.item-warning {
  grid-row-start: 1;
  grid-row-end: 4;
  grid-column-start: 2;
  grid-column-end: 2;
}
.label-file {
  margin-left: 2px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size: 12px;
}
.label-conf-box {
  display: inline-block;
  width: 85px;
  margin-top: 0px;
  vertical-align: middle;
  text-align: center;
  line-height: 0px;
  background-color: #d3d3d3
}
.inline-block {
  display: inline-block;
  margin-left: 10px;
}
#file-select .el-table .cell {
  white-space: pre-wrap;
  text-align : left;
  font-size: 12px;
  color:red;
  line-height: 1.5;
  padding-left: 0px;
}
#file-select .el-table__row td {
  vertical-align: top;
  max-height: 500px;
  overflow: auto;
}
</style>