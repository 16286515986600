<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  components: {  },
  data () {
    return {
    }
  },
  created(){
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style>
</style>