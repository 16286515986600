<template>
  <el-dialog class="el-dialog-commit" width="600px" title="コミット内容入力" :show-close="false" :close-on-click-modal="false" :visible.sync="visible" :close-on-press-escape="false">
    <div style="width: 550px">
      <label class="label-commit-message">コミットメッセージ</label>
      <el-input type="textarea" v-model="commitMessage" class="textarea-style" size="large" :rows="5"></el-input>
    </div>

    <div slot="footer" class="btn-push-style">
      <el-button size="small" @click="cancel()" type="warning">キャンセル</el-button>
      <el-button size="small" @click="push()" type="primary">push<i class="el-icon-upload2 el-icon--right"></i></el-button>
    </div>
  </el-dialog>
</template>
<script>
import Vue from "vue"
import { constants } from '@/common/constants'
import { messages } from '@/common/messages'

export default {
  props: {
    branches: {
      type: Array,
      default: []
    },
    visible: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      // コミットメッセージ
      commitMessage: ''
    }
  },
  created () {
    Vue.$log.info('FilePush.vue')
  },
  methods: {
    cancel() {
      // confirm.vueでcancelCallbackメソッドを呼び出す
      this.$emit('cancelCallback')
    },
    // gitlab push動作
    push() {
      if (this.commitMessage.length === 0 || this.commitMessage.length > constants.MAXIMUM_NUMBER_OF_CHARACTERS) {
        let errorMessage = messages.PUSH_COMMIT_MESSAGE_LENGTH_ERROR
          .replace('[0]', constants.MAXIMUM_NUMBER_OF_CHARACTERS)

        this.$alert(errorMessage, '提示', {
          confirmButtonText: 'OK',
          type: 'warning'
        }).then(() => {
        }).catch(() => {
        })

        return
      }

      // confirm.vueでpushCallbackメソッドを呼び出す
      this.$emit('pushCallback', this.commitMessage)
    }
  }
}
</script>

<style>
.btn-push-style {
  margin-left: 10px;
  margin-top: 10px;
}
.label-commit-message {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size: 16px;
}
.el-dialog-commit .el-dialog__header {
  background: #1874CD;
  text-align: center;
  height: 24px;
}
.el-dialog-commit .el-dialog__title {
  color: white;
  font-size: 16px;
}
.el-dialog-commit .el-dialog {
  height: 40%;
}
.el-dialog-commit .el-dialog__body {
  position: absolute;
  left: 10px;
  top: 60px;
  padding: 0px;
}
.el-dialog-commit .el-dialog__footer {
  text-align: right;
  position: absolute;
  bottom: 20px;
  right: 20px;
}
</style>