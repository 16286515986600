<template>
  <el-dialog class="el-dialog-branch" width="600px" title="ブランチ選択" :show-close="false" :close-on-click-modal="false" :visible.sync="visible" :close-on-press-escape="false">
    <el-table ref="table" class="el-table-branch" :data="branches" highlight-current-row @row-click="rowClick" :show-header="false" :height="350" :width="572">
      <el-table-column prop="branch" label="branch" width="572"> </el-table-column>
    </el-table>
    <div class="btn-style">
      <el-button size="small" @click="cancel()"  type="warning">キャンセル</el-button>
      <el-button size="small" @click="confirm()" type="success" style="width:45%;">OK</el-button>
    </div>
  </el-dialog>
</template>
<script>
import Vue from "vue"
import { messages } from '@/common/messages'

export default {
  props: {
    branches: {
      type: Array,
      default: []
    },
    visible: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      selectedBranchName: ''
    }
  },
  created () {
    Vue.$log.info('SelectPopUp.vue')
  },
  methods: {
    // キャンセルボタンクリック
    cancel() {
      // ブランチ選択状態の初期化
      this.selectedBranchName = ''

      // update.vueでclosePopUpメソッドを呼び出す
      this.$emit('closePopUp')
    },
    // OKボタンクリック
    confirm() {
      if (this.selectedBranchName.length === 0) {
        // ブランチが選択されていません
        this.$alert(messages.BRANCH_SELECTION_STATUS_INVILID, '提示', {
          confirmButtonText: 'OK',
          type: 'warning'
        }).then(() => {
        }).catch(() => {
        })

        return
      }

      // update.vueでselectedBranchメソッドを呼び出す
      this.$emit('selectedBranch', this.selectedBranchName)
    },
    // ブランチ選択
    rowClick(row) {
      this.selectedBranchName = row.branch
    }
  }
}
</script>
<style>
.btn-style {
  margin-left: 340px;
  margin-top: 30px;
}
.el-dialog-branch .el-dialog__header {
  background: #1874CD;
  text-align: center;
  height: 24px;
}
.el-dialog-branch .el-dialog__title {
  color: white;
  font-size: 16px;
}
.el-dialog-branch .el-dialog {
  height: 500px;
}
.el-dialog-branch .el-dialog__body {
  position: absolute;
  left: 10px;
  top: 60px;
  padding: 0px;
}
.el-dialog-branch .el-dialog__footer {
  text-align: right;
  position: absolute;
  bottom: 20px;
  right: 20px;
}
#files-select .el-dialog-branch .el-table .cell {
  color: black !important;
}
.el-table-branch .el-table__body tr.current-row > td {
  background-color: #40E0D0 !important;
  color: #f19944;
}
</style>