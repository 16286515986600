import Vue from 'vue'
import App from './App.vue'
import router from './router'
import element from 'element-ui'
import locale from 'element-ui/lib/locale/lang/ja'
import 'element-ui/lib/theme-chalk/index.css'
import VueLogger from 'vuejs-logger'
import { importComponent } from './components/index'
import './main.css'

const options = {
  isEnabled: true,
  // required ['debug', 'info', 'warn', 'error', 'fatal']
  logLevel : 'debug',
  stringifyArguments : false,
  showLogLevel : true,
  showMethodName : true,
  separator: '|',
  showConsoleColors: true
}

Vue.config.productionTip = false

Vue.use(element, {locale})
Vue.use(VueLogger, options)

importComponent(Vue)
new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app')