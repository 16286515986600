<template>
  <div
    ref="container"
    class="monaco-editor"
    :style="`height: ${height}vh; width: ${width}%; ${float};`"
  ></div>
</template>

<script>
import * as monaco from 'monaco-editor'

export default {
  name: 'AcMonaco',
  props: {
    opts: {
      type: Object,
      default () {
        return {}
      }
    },
    height: {
      type: Number,
      default: 300
    },
    width: {
      type: Number,
      default: 30
    },
    float: {
      type: String,
      default: 'float: none'
    },
    isDiff: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      defaultOpts: {
        value: '',
        theme: 'vs-dark',
        roundedSelection: true,
        autoIndent: TextTrackCue
      },
      monacoEditor: {},
      oldValue: '',
      newValue: ''
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      // containerのコンテンツを初期化し、以前に作成したエディタを破棄する
      this.$refs.container.innerHTML = ''
      // エディタ構成を生成する
      let editorOptions = Object.assign(this.defaultOpts, this.opts)

      if (!this.isDiff) {
        // エディタインスタンスの初期化
        this.monacoEditor = monaco.editor.create(this.$refs.container, editorOptions)
        // エディタのコンテンツが変更されたときにトリガー
        this.monacoEditor.onDidChangeModelContent(() => {
          this.$emit('change', this.monacoEditor.getValue())
        })
      } else {
        this.oldValue = this.$route.params.rightEditorOldValue
        this.newValue = this.$route.params.rightEditorNewValue

        editorOptions.readOnly = true
        editorOptions.language = 'json'

        // diffエディタインスタンスの初期化
        this.monacoDiffInstance = monaco.editor.createDiffEditor(this.$refs['container'], editorOptions)
        this.monacoDiffInstance.setModel({
          original: monaco.editor.createModel(this.oldValue, editorOptions.language),
          modified: monaco.editor.createModel(this.newValue, editorOptions.language)
        })

      }
    },
    upDateDiff (val) {
      this.monacoDiffInstance.updateOptions({
        renderSideBySide: !val
      })
    },
    // 取得値を呼び出す
    getVal () {
      return this.monacoEditor.getValue()
    }
  }
}
</script>