import { constants } from '@/common/constants'
import { messages } from '@/common/messages'
import Vue from "vue"

let  commonUtil = {
}

var vm = null

commonUtil.sendThis = function(_this) {
  vm = _this
}

// オブジェクト配列の並べ替え
commonUtil.objectArraySort = function(objArray, orderColumn, showColumn) {
  let arr = new Array()
  const arrSort = objArray.sort(compare(orderColumn))

  function compare(orderColumn) {
    return function(objArray1, objArray2) {
      const orderColumnValue1 = objArray1[orderColumn]
      const orderColumnValue2 = objArray2[orderColumn]
      return orderColumnValue1 - orderColumnValue2
    }
  }

  for (let arrSortRow of arrSort) {
    if (arrSortRow[showColumn].substring(0, 1) === constants.LEFT_SQUARE_BRACKET) {
      arr.push(arrSortRow[showColumn])
    } else {
      arr.push(`  ${Math.round(arrSortRow[orderColumn]*1000 % 1000)}. ${arrSortRow[showColumn]}`)
    }
  }

  return arr
}

// json構造チェック: 戻り値missingItems: 項目に存在しない情報(missingItems配列が空の場合、項目がすべて存在する)
commonUtil.objectItemsCheck = function(jsonName, jsonObj) {
  let itemsCheck = constants.ITEMS_IN_MAPS
  if (jsonName === constants.PP_CHECK_OBJECTS[1] || jsonName === constants.PP_CHECK_OBJECTS[2]) {
    itemsCheck = constants.ITEMS_IN_TEST_ENTRY_AND_DIFFS
  }

  let missingItems = new Array()
  for (let i = 0; i < jsonObj.length; i++) {
    let version = ''
    // 項目存在チェック
    for (const itemCheck of itemsCheck) {
      // ver項目チェック
      if (itemCheck === itemsCheck[0]) {
        // 存在チェック
        if (itemCheck in jsonObj[i]) {
          // verが存在
          version = jsonObj[i][constants.ITEMS_IN_MAPS[0]]
          const ver = version.slice(-5)
          // nameが存在
          let versionName = ''
          if (constants.ITEMS_IN_TEST_ENTRY_AND_DIFFS[2] in jsonObj[i]) {
            versionName = jsonObj[i][constants.ITEMS_IN_TEST_ENTRY_AND_DIFFS[2]]
          }

          // pp向けconfigurations.jsonのtest_entry配下エントリ（「"ver": "MMM8M0JP0905202", "name": "5202_6400_s5202"」以降）
          if (jsonName === constants.PP_CHECK_OBJECTS[1]) {
            if (ver === constants.TEST_ENTRY_BASE_VERSION && versionName != constants.TEST_ENTRY_BASE_VERSION_NAME
              || ver < constants.TEST_ENTRY_BASE_VERSION) {
              break
            }
          }

          continue
        } else {
          // verが存在しない
          missingItems.push({version: version, itemName: itemCheck})
        }
      } else {
      // 非ver項目チェック
        // 存在チェック
        if (itemCheck in jsonObj[i]) {
          continue
        } else {
          // 非ver項目が存在しない
          missingItems.push({version: version, itemName: itemCheck})
        }
      }
    }
  }

  return missingItems
}

// json構造チェック: 余計な項目チェック
commonUtil.extraObjectItemsCheck = function(jsonName, jsonObj) {
  let itemsCheck = constants.ITEMS_IN_MAPS
  if (jsonName === constants.PP_CHECK_OBJECTS[1] || jsonName === constants.PP_CHECK_OBJECTS[2]) {
    itemsCheck = constants.ITEMS_IN_TEST_ENTRY_AND_DIFFS
  }

  let extraItems = new Array()
  for (let i = 0; i < jsonObj.length; i++) {
    let version = ''
    // 存在チェック
    if (constants.ITEMS_IN_MAPS[0] in jsonObj[i]) {
      // verが存在
      version = jsonObj[i][constants.ITEMS_IN_MAPS[0]]
      const ver = version.slice(-5)
      // nameが存在
      let versionName = ''
      if (constants.ITEMS_IN_TEST_ENTRY_AND_DIFFS[2] in jsonObj[i]) {
        versionName = jsonObj[i][constants.ITEMS_IN_TEST_ENTRY_AND_DIFFS[2]]
      }

      // pp向けconfigurations.jsonのtest_entry配下エントリ（「"ver": "MMM8M0JP0905202", "name": "5202_6400_s5202"」以降）
      if (jsonName === constants.PP_CHECK_OBJECTS[1]) {
        if (ver === constants.TEST_ENTRY_BASE_VERSION && versionName != constants.TEST_ENTRY_BASE_VERSION_NAME
          || ver < constants.TEST_ENTRY_BASE_VERSION) {
          continue
        }
      }
    }

    for (const itemKey in jsonObj[i]) {
      if (itemsCheck.indexOf(itemKey) == -1) {
        // 項目が余計な項目
        extraItems.push({version: version, itemName: itemKey})
      }
    }
  }

  return extraItems
}

// 各値チェック: 戻り値wrongItemValues:  項目の値にある不正確な情報(wrongItemValues配列が空の場合、項目各値が正しい)
commonUtil.itemValuesCheck = function(jsonName, jsonObj) {
  let itemsCheck = constants.ITEMS_IN_MAPS
  if (jsonName == constants.PP_CHECK_OBJECTS[1] || jsonName == constants.PP_CHECK_OBJECTS[2]) {
    itemsCheck = constants.ITEMS_IN_TEST_ENTRY_AND_DIFFS
  }

  // 現在のUnixタイムスタンプを取得する
  let nowTimestamp = new Date().getTime()

  let wrongItemValues = new Array()
  for (let i = 0; i < jsonObj.length; i++) {
    let versionName = ''
    let ver = ''

    // チェック
    for (const itemCheck of itemsCheck) {
      // ver項目
      versionName = jsonObj[i][constants.ITEMS_IN_MAPS[0]]
      if (typeof(versionName) == 'undefined') {
        continue
      }
      ver = versionName.slice(-5)

      // name項目
      let verName = jsonObj[i][constants.ITEMS_IN_MAPS[2]]
      if (typeof(verName) == 'undefined') {
        verName = ''
      }

      // pp向けconfigurations.jsonのtest_entry配下エントリ（「"ver": "MMM8M0JP0905202", "name": "5202_6400_s5202"」以降）
      if (jsonName === constants.PP_CHECK_OBJECTS[1]) {
        if (ver === constants.TEST_ENTRY_BASE_VERSION && verName != constants.TEST_ENTRY_BASE_VERSION_NAME
          || ver < constants.TEST_ENTRY_BASE_VERSION) {
          break
        }
      }

      switch (itemCheck) {
        // ver項目
        case itemsCheck[0]:
          // ver項目値
          versionName = jsonObj[i][constants.ITEMS_IN_MAPS[0]]

          if (typeof(versionName) != 'undefined') {
            ver = versionName.slice(-5)

            // ver項目チェック: 形式が「"半角数字2桁" + Q + "半角英数字7桁"」であること
            if (constants.RULES_IN_MAPS[0].test(versionName)) {
              continue
            } else {
              wrongItemValues.push({version: versionName, itemName: itemCheck})
            }
          }

          break
        // release
        case itemsCheck[1]:
          // 項目値
          var releaseValue = jsonObj[i][constants.ITEMS_IN_MAPS[1]]

          if (typeof(releaseValue) != 'undefined') {
            // release項目チェック:「time」のUNIX時間（ミリ秒）であること
            if (releaseValue.toString().length == 13 && Number.isInteger(releaseValue)) {
              continue
            } else {
              wrongItemValues.push({version: versionName, itemName: itemCheck})
            }
          }

          break
        // name
        case itemsCheck[2]:
          // 項目値
          var nameValue = jsonObj[i][constants.ITEMS_IN_MAPS[2]]

          if (typeof(nameValue) != 'undefined') {
            // upto項目値
            var uptoValue = jsonObj[i][constants.ITEMS_IN_TEST_ENTRY_AND_DIFFS[5]]
            var upto = ''
            if (typeof(uptoValue) != 'undefined') {
              upto = uptoValue.slice(-5)
            }

            // name項目チェック:
            //   maps: 
            //      「ver」の下5桁と同一であること
            //   test_entry/diffs:
            //      「"verの下5桁" + "_" + "uptoの下5桁"」であること（例："09200_09300"）
            if (jsonName === constants.PP_CHECK_OBJECTS[0]) {
              if (nameValue === ver || nameValue != ver && releaseValue < nowTimestamp) {
                continue
              } else {
                wrongItemValues.push({version: versionName, itemName: itemCheck})
              }
            } else {
              if (typeof(uptoValue) != 'undefined') {
                if (nameValue === `${ver}_${upto}` || nameValue != `${ver}_${upto}` && releaseValue < nowTimestamp) {
                  continue
                } else {
                  wrongItemValues.push({version: versionName, itemName: itemCheck})
                }
              }
            }
          }
          break
        // date
        case itemsCheck[3]:
          // 項目値
          var dateValue = jsonObj[i][constants.ITEMS_IN_MAPS[3]]

          if (typeof(dateValue) != 'undefined' && typeof(releaseValue) != 'undefined') {
            // date項目チェック: 「release」の年月と同一であること。形式は「yyyyMM」（例："202112"）
            var releaseDate = new Date(releaseValue)
            var releaseMonth = releaseDate.getMonth() + 1
            if (releaseMonth < 10) {
              releaseMonth = `0${releaseMonth}`
            }
            var releaseYearMonth = `${releaseDate.getFullYear()}${releaseMonth}`
            if (dateValue == releaseYearMonth) {
              continue
            } else {
              wrongItemValues.push({version: versionName, itemName: itemCheck})
            }
          }

          break
        // time項目
        case itemsCheck[4]:
          // 項目値
          var timeValue = jsonObj[i][constants.ITEMS_IN_MAPS[4]]

          if (typeof(timeValue) != 'undefined') {
            // time項目チェック: 形式が「yyyy-MM-ddTHH:mm:ss.SSS」（例："2021-12-20T10:00:00.000Z"）であること
            if (!constants.RULES_IN_MAPS[1].test(timeValue)) {
              wrongItemValues.push({version: versionName, itemName: itemCheck})
            }

            try {
              new Date(timeValue)

              continue
            } catch (error) {
              wrongItemValues.push({version: versionName, itemName: itemCheck})
            }
          }

          break
        // diff項目/upto項目
        case itemsCheck[5]:
          if (jsonName == constants.PP_CHECK_OBJECTS[0]) {
          // diff項目
            // 項目値
            var diffValue = jsonObj[i][constants.ITEMS_IN_MAPS[5]]

            if (typeof(diffValue) != 'undefined') {
              // diff項目チェック: 0または1であること
              if (diffValue == 0 || diffValue == 1) {
                continue
              } else {
                wrongItemValues.push({version: versionName, itemName: itemCheck})
              }
            }
          } else {
          // upto項目
            // 項目値
            uptoValue = jsonObj[i][constants.ITEMS_IN_TEST_ENTRY_AND_DIFFS[5]]

            if (typeof(uptoValue) != 'undefined') {
              // upto項目チェック: 形式が「"半角数字2桁" + Q + "半角英数字7桁"」であること
              if (constants.RULES_IN_MAPS[0].test(uptoValue)) {
                continue
              } else {
                wrongItemValues.push({version: versionName, itemName: itemCheck})
              }
            }
          }

          break
        // size項目
        case itemsCheck[6]:
          if (jsonName != constants.PP_CHECK_OBJECTS[0]) {
          // test_entry/diffs配下

            // 項目値
            var sizeValue = jsonObj[i][constants.ITEMS_IN_TEST_ENTRY_AND_DIFFS[6]]

            if (typeof(sizeValue) != 'undefined') {
              if (Number.isInteger(sizeValue) && sizeValue > 0) {
                continue
              } else {
                wrongItemValues.push({version: versionName, itemName: itemCheck})
              }
            }
          }

          break
      }
    }
  }

  return wrongItemValues
}

// リリース対象が入っていない場合に備えたチェック
commonUtil.hasCopyDataCheck = function(jsonName, jsonObj) {
  // 初期化
  let isHasCopyData = false

  // 現在のUnixタイムスタンプを取得する
  const nowTimestamp = new Date().getTime()

  // release日付有効期限: releaseの値（UNIX時間）が「現在日時の2週間後まで」の範囲内
  const validTimestamp = nowTimestamp + constants.RELEASE_PLUS_DAYS * constants.MILLISECOND_OF_ONE_DAY

  for (let i = 0; i < jsonObj.length; i++) {
    // 項目値
    const releaseValue = jsonObj[i][constants.ITEMS_IN_MAPS[1]]

    if (jsonName === constants.PP_CHECK_OBJECTS[0]) {
    // リリース対象が入っていない場合に備えたチェック（maps）
      // diffの値が1
      const diffValue = jsonObj[i][constants.ITEMS_IN_MAPS[5]]

      if (releaseValue >= nowTimestamp && releaseValue <= validTimestamp && diffValue == 1) {
        isHasCopyData = true

        return isHasCopyData
      } else {
        continue
      }
    } else {
    // リリース対象が入っていない場合に備えたチェック（test_entry）

      // ver項目
      const versionName = jsonObj[i][constants.ITEMS_IN_MAPS[0]]
      if (typeof(versionName) == 'undefined') {
        continue
      }
      const ver = versionName.slice(-5)

      // name項目
      const nameValue = jsonObj[i][constants.ITEMS_IN_MAPS[2]]

      // releaseの値（UNIX時間）が「現在日時の2週間後まで」の範囲内
      if (releaseValue >= nowTimestamp && releaseValue <= validTimestamp
        && (ver === constants.TEST_ENTRY_BASE_VERSION && nameValue === constants.TEST_ENTRY_BASE_VERSION_NAME
        || ver > constants.TEST_ENTRY_BASE_VERSION)) {
        isHasCopyData = true

        return isHasCopyData
      } else {
        continue
      }
    }
  }

  return isHasCopyData
}

// ver重複チェック: true: バージョンが重複しない、false: オブジェクトが存在しない、versions[j]: 重複したバージョン番号が出る
commonUtil.doubleCheck = function() {
  let doubleVersions = new Array()

  if (arguments.length == 0) {
    return false
  }

  let versions = new Array()
  for (let i = 0; i < arguments.length; i++) {
    const ver = arguments[i][constants.ITEMS_IN_MAPS[0]]
    if (typeof(ver) != 'undefined') {
      if (!JSON.stringify(ver).match(constants.TEST_ENTRY_BASE_VERSION)) {
        versions.push(ver)
      }
    }
  }

  for (let j = 0; j < versions.length; j++) {
    if (versions.indexOf(versions[j]) != j) {
      doubleVersions.push(versions[j])
    }
  }

  doubleVersions = Array.from(new Set(doubleVersions)).sort()
  if (doubleVersions.length > 0) {
    return doubleVersions
  }

  return true
}

// ver順番チェック: true: バージョンの順番が正しい、false: オブジェクトが存在しない、versions[j]: 順番が間違ったバージョン番号が出る
commonUtil.orderCheck = function() {
  if (arguments.length == 0) {
    return false
  }

  let startCheckIndex = 0
  for (let i = 0; i < arguments.length; i++) {
    const ver = arguments[i][constants.ITEMS_IN_MAPS[0]]
    if (typeof(ver) != 'undefined') {
      if (!JSON.stringify(ver).match(constants.TEST_ENTRY_BASE_VERSION)) {
        startCheckIndex = i
        break
      }
    }
  }

  let versions = new Array()
  for (let i = startCheckIndex; i < arguments.length; i++) {
    versions.push(arguments[i][constants.ITEMS_IN_MAPS[0]])
  }

  for (let j = 0; j < versions.length - 1; j++) {
    if (versions[j] > versions[j + 1]) {
      return versions[j]
    }
  }

  return true
}

// 一意性チェック
commonUtil.uniqCheck = function() {
  if (arguments.length === 1) {
    return true
  }

  return false
}

// エラー情報を設定する
commonUtil.setErrorMessages = function(jsonName, jsonObj, isFromCopyButton=false, envName = constants.CHECK_FILE_NAME[1]) {
  // 区分はppとprod
  let jsonNameWithPrefix = jsonName
  jsonName = jsonName.replace(`${constants.CHECK_FILE_NAME[0]}_`, '').replace(`${constants.CHECK_FILE_NAME[1]}_`, '')

  // 2.オブジェクト存在チェック
  if (jsonObj == null) {
    const objectExistError = messages.OBJECTS_NO_EXISTS_ERROR.replace('[0]', jsonName)

    const result = vm.errorMessagesArray.find((errorRow) => errorRow.error === constants.CHECK_TYPE[2])
    if (typeof(result) == 'undefined') {
      vm.errorMessagesArray.push({'seq': vm.seqs['objects'], 'error': constants.CHECK_TYPE[2]})
      vm.seqs['objects'] += vm.stepSize
      vm.errorMessagesArray.push({'seq': vm.seqs['objects'], 'error': objectExistError})
      vm.seqs['objects'] += vm.stepSize
    } else {
      vm.errorMessagesArray.push({'seq': vm.seqs['objects'], 'error': objectExistError})
      vm.seqs['objects'] += vm.stepSize
    }
  } else {
    // 3.json構造チェック: 項目に存在しない情報(
    const missingItems = this.objectItemsCheck.apply(this, [jsonName, jsonObj])
    if (!isFromCopyButton) {
      for (const missingItem of missingItems) {
        let itemNamesError
        if (jsonName == constants.PP_CHECK_OBJECTS[0]) {
          itemNamesError = messages.MAPS_ITEMS_MISSING_ERROR.replace('[0]', missingItem.itemName)
            .replace('[1]', missingItem.version)
        } else {
          itemNamesError = messages.TEST_ENTRY_AND_DIFFS_ITEMS_MISSING_ERROR.replace('[0]', jsonName)
            .replace('[1]', missingItem.itemName).replace('[2]', missingItem.version)
        }

        const result = vm.errorMessagesArray.find((errorRow) => errorRow.error === constants.CHECK_TYPE[4])
        if (typeof(result) == 'undefined') {
          // エラー情報のタイトル
          vm.errorMessagesArray.push({'seq': vm.seqs['itemNames'], 'error': constants.CHECK_TYPE[4]})
          vm.seqs['itemNames'] += vm.stepSize
          // エラー情報
          vm.errorMessagesArray.push({'seq': vm.seqs['itemNames'], 'error': itemNamesError})
          vm.seqs['itemNames'] += vm.stepSize
        } else {
          // エラー情報(タイトルを含む)
          vm.errorMessagesArray.push({'seq': vm.seqs['itemNames'], 'error': itemNamesError})
          vm.seqs['itemNames'] += vm.stepSize
        }
      }
    }

    // 3.json構造チェック: 余計な項目チェック
    const extraItems = this.extraObjectItemsCheck.apply(this, [jsonName, jsonObj])
    if (!isFromCopyButton) {
      for (const extraItem of extraItems) {
        let itemNamesError
        if (jsonName == constants.PP_CHECK_OBJECTS[0]) {
          itemNamesError = messages.MAPS_ITEMS_EXTRA_ERROR.replace('[0]', extraItem.itemName)
            .replace('[1]', extraItem.version)
        } else {
          itemNamesError = messages.TEST_ENTRY_AND_DIFFS_ITEMS_EXTRA_ERROR.replace('[0]', jsonName)
            .replace('[1]', extraItem.itemName).replace('[2]', extraItem.version)
        }

        const result = vm.errorMessagesArray.find((errorRow) => errorRow.error === constants.CHECK_TYPE[4])
        if (typeof(result) == 'undefined') {
          // エラー情報のタイトル
          vm.errorMessagesArray.push({'seq': vm.seqs['itemNames'], 'error': constants.CHECK_TYPE[4]})
          vm.seqs['itemNames'] += vm.stepSize
          // エラー情報
          vm.errorMessagesArray.push({'seq': vm.seqs['itemNames'], 'error': itemNamesError})
          vm.seqs['itemNames'] += vm.stepSize
        } else {
          // エラー情報(タイトルを含む)
          vm.errorMessagesArray.push({'seq': vm.seqs['itemNames'], 'error': itemNamesError})
          vm.seqs['itemNames'] += vm.stepSize
        }
      }
    }

    // 4.各値チェック
    if (!isFromCopyButton && jsonNameWithPrefix != `${constants.CHECK_FILE_NAME[0]}_${constants.PP_CHECK_OBJECTS[2]}`) {
      const wrongItemValues = this.itemValuesCheck.apply(this, [jsonName, jsonObj])

      for (const wrongItemValue of wrongItemValues) {
        let itemValuesError
        if (jsonName == constants.PP_CHECK_OBJECTS[0]) {
        // maps
          itemValuesError = messages.MAPS_ITEM_VALUES_WRONG_ERROR.replace('[0]', wrongItemValue.itemName)
            .replace('[1]', wrongItemValue.version)
        } else {
        // test_entryとdiffs
          itemValuesError = messages.TEST_ENTRY_AND_DIFFS_ITEM_VALUES_WRONG_ERROR.replace('[0]', wrongItemValue.itemName)
            .replace('[1]', wrongItemValue.version).replace('[3]', jsonName)
        }

        const result = vm.errorMessagesArray.find((errorRow) => errorRow.error === constants.CHECK_TYPE[5])
        if (typeof(result) == 'undefined') {
          // エラー情報のタイトル
          vm.errorMessagesArray.push({'seq': vm.seqs['itemValues'], 'error': constants.CHECK_TYPE[5]})
          vm.seqs['itemValues'] += vm.stepSize
          // エラー情報
          vm.errorMessagesArray.push({'seq': vm.seqs['itemValues'], 'error': itemValuesError})
          vm.seqs['itemValues'] += vm.stepSize
        } else {
          // エラー情報(タイトルを含む)
          vm.errorMessagesArray.push({'seq': vm.seqs['itemValues'], 'error': itemValuesError})
          vm.seqs['itemValues'] += vm.stepSize
        }
      }
    }


    // 5.重複チェック
    const doubleCheckResult = this.doubleCheck.apply(this, jsonObj)
    if (doubleCheckResult != true && doubleCheckResult != false && jsonName != constants.PP_CHECK_OBJECTS[1]) {
      const mapsDoubleError = messages.DOUBLE_CHECK_ERROR.replace('[0]', jsonName)
        .replace('[1]', doubleCheckResult.join(constants.PAUSE_MARK))

      const result = vm.errorMessagesArray.find((errorRow) => errorRow.error === constants.CHECK_TYPE[0])

      if (typeof(result) == 'undefined') {
        // エラー情報のタイトル
        vm.errorMessagesArray.push({'seq': vm.seqs['double'], 'error': constants.CHECK_TYPE[0]})
        vm.seqs['double'] += vm.stepSize
        // エラー情報
        vm.errorMessagesArray.push({'seq': vm.seqs['double'], 'error': mapsDoubleError})
        vm.seqs['double'] += vm.stepSize
      } else {
        // エラー情報(タイトルを含む)
        vm.errorMessagesArray.push({'seq': vm.seqs['double'], 'error': mapsDoubleError})
        vm.seqs['double'] += vm.stepSize
      }
    }

    // 6.順番チェック
    const orderCheckResult = this.orderCheck.apply(this, jsonObj)
    if (orderCheckResult != true && orderCheckResult != false && jsonName != constants.PP_CHECK_OBJECTS[1]) {
      const mapsOrderError = messages.ORDER_CHECK_ERROR.replace('[0]', jsonName).replace('[1]', orderCheckResult)

      const result = vm.errorMessagesArray.find((errorRow) => errorRow.error === constants.CHECK_TYPE[1])
      if (typeof(result) == 'undefined') {
        // エラー情報のタイトル
        vm.errorMessagesArray.push({'seq': vm.seqs['order'], 'error': constants.CHECK_TYPE[1]})
        vm.seqs['order'] += vm.stepSize
        // エラー情報
        vm.errorMessagesArray.push({'seq': vm.seqs['order'], 'error': mapsOrderError})
        vm.seqs['order'] += vm.stepSize
      } else {
        // エラー情報(タイトルを含む)
        vm.errorMessagesArray.push({'seq': vm.seqs['order'], 'error': mapsOrderError})
        vm.seqs['order'] += vm.stepSize
      }
    }

    // 7. リリース対象が入っていない場合に備えたチェック: pp conf
    if (envName == constants.CHECK_FILE_NAME[1] && !isFromCopyButton
      && (jsonNameWithPrefix == `${constants.CHECK_FILE_NAME[0]}_${constants.PP_CHECK_OBJECTS[0]}`
      || jsonNameWithPrefix == `${constants.CHECK_FILE_NAME[0]}_${constants.PP_CHECK_OBJECTS[1]}`)) {

      const isHasCopyData = this.hasCopyDataCheck.apply(this, [jsonName, jsonObj])
      if (!isHasCopyData) {
        const noCopyDataError = messages.NO_COPY_DATA_ERROR.replace('[0]', jsonName)

        const result = vm.errorMessagesArray.find((errorRow) => errorRow.error === constants.CHECK_TYPE[6])
        if (typeof(result) == 'undefined') {
          // エラー情報のタイトル
          vm.errorMessagesArray.push({'seq': vm.seqs['release'], 'error': constants.CHECK_TYPE[6]})
          vm.seqs['release'] += vm.stepSize
          // エラー情報
          vm.errorMessagesArray.push({'seq': vm.seqs['release'], 'error': noCopyDataError})
          vm.seqs['release'] += vm.stepSize
        } else {
          // エラー情報(タイトルを含む)
          vm.errorMessagesArray.push({'seq': vm.seqs['release'], 'error': noCopyDataError})
          vm.seqs['release'] += vm.stepSize
        }
      }
    }

    // 8. 個別エラー: diffs配下データ件数チェック
    if (envName == constants.CHECK_FILE_NAME[0] && !isFromCopyButton && jsonName == constants.PP_CHECK_OBJECTS[2]) {
      const uniqCheckResult = this.uniqCheck.apply(this, jsonObj)
      if (uniqCheckResult === false) {
        vm.errorMessagesArray.push({'seq': vm.seqs['uniq'], 'error': constants.CHECK_TYPE[8]})
        vm.seqs['uniq'] += vm.stepSize
        vm.errorMessagesArray.push({'seq': vm.seqs['uniq'], 'error': messages.DIFFS_NO_UNIQ_ERROR})
        vm.seqs['uniq'] += vm.stepSize
      }
    }
  }
}

// エラー情報を表示する
commonUtil.showErrorMessages = function(fileName, jsonString, isFromCopyButton = false, envName = constants.CHECK_FILE_NAME[1],
  displayMessage = true) {

  Vue.$log.info('-----------------commonUtil.showErrorMessages start---------------------')
  Vue.$log.info(fileName)

  // 1.JSON形式チェック
  let json = ''
  try {
    json = JSON.parse(jsonString)
  } catch (error) {
    Vue.$log.info(error)

    const jsonFileError = messages.INVALID_JSON_CHECK_ERROR.replace('[0]', fileName)

    vm.errorMessagesArray.push({'seq': vm.seqs['jsonFormat'], 'error': constants.CHECK_TYPE[3]})
    vm.seqs['jsonFormat'] += vm.stepSize
    vm.errorMessagesArray.push({'seq': vm.seqs['jsonFormat'], 'error': jsonFileError})
    vm.seqs['jsonFormat'] += vm.stepSize

    let errorMessages = commonUtil.objectArraySort(vm.errorMessagesArray, 'seq', 'error')
    if (fileName === constants.CHECK_FILE_NAME[0]) {
      vm.allErrorMessages = [{
        leftError: errorMessages.join(constants.LINE_BREAK),
        rightError: vm.allErrorMessages[0].rightError
      }]
    } else {
      vm.allErrorMessages = [{
        leftError: vm.allErrorMessages[0].leftError,
        rightError: errorMessages.join(constants.LINE_BREAK)
      }]
    }
    vm.styleType = 'display: block'

    Vue.$log.info('-----------------commonUtil.showErrorMessages end---------------------')
    return true
  }

  let mapJson
  let testEntryJson
  let diffJson
  if (fileName === constants.CHECK_FILE_NAME[0]) {
  // ppファイルの場合
  // 2.オブジェクト存在チェック
    // mapsオブジェクト存在チェック
    if (constants.PP_CHECK_OBJECTS[0] in json) {
      // ppファイルのチェック: チェック対象はmaps
      mapJson = json[constants.PP_CHECK_OBJECTS[0]]
    } else {
      mapJson = null
    }
    this.setErrorMessages(`${constants.CHECK_FILE_NAME[0]}_${constants.PP_CHECK_OBJECTS[0]}`, mapJson, isFromCopyButton, envName)

    // test_entryオブジェクト存在チェック
    if (constants.TEST_ENTRY_FATHER_NODE in json && constants.PP_CHECK_OBJECTS[1] in json[constants.TEST_ENTRY_FATHER_NODE]) {
      // ppファイルのチェック: チェック対象はtest_entry
      testEntryJson = json[constants.TEST_ENTRY_FATHER_NODE][constants.PP_CHECK_OBJECTS[1]]
    } else {
      testEntryJson = null
    }
    this.setErrorMessages(`${constants.CHECK_FILE_NAME[0]}_${constants.PP_CHECK_OBJECTS[1]}`, testEntryJson, isFromCopyButton, envName)

    // diffsオブジェクト存在チェック
    if (constants.PP_CHECK_OBJECTS[2] in json) {
      // ppファイルのチェック: チェック対象はdiffs
      diffJson = json[constants.PP_CHECK_OBJECTS[2]]
    } else {
      diffJson = null
    }
    this.setErrorMessages(`${constants.CHECK_FILE_NAME[0]}_${constants.PP_CHECK_OBJECTS[2]}`, diffJson, isFromCopyButton, envName)
  } else {
  // prodファイルの場合
  // 2.オブジェクト存在チェック
    // prodファイルのチェック: チェック対象はmaps、diffs
    // mapsオブジェクト存在チェック
    if (constants.PROD_CHECK_OBJECTS[0] in json) {
      // prodファイルのチェック: チェック対象はmaps
      mapJson = json[constants.PROD_CHECK_OBJECTS[0]]
    } else {
      mapJson = null
    }
    this.setErrorMessages(`${constants.CHECK_FILE_NAME[1]}_${constants.PROD_CHECK_OBJECTS[0]}`, mapJson, isFromCopyButton)

    // diffsオブジェクト存在チェック
    if (constants.PROD_CHECK_OBJECTS[1] in json) {
      // prodファイルのチェック: チェック対象はtest_entry
      diffJson = json[constants.PROD_CHECK_OBJECTS[1]]
    } else {
      diffJson = null
    }
    this.setErrorMessages(`${constants.CHECK_FILE_NAME[1]}_${constants.PROD_CHECK_OBJECTS[1]}`, diffJson, isFromCopyButton)
  }

  // ppファイルとprodファイルのエラー表示
  let hasErrorMessages
  if (fileName === constants.CHECK_FILE_NAME[0]) {
    // ppファイル
    hasErrorMessages = vm.errorMessagesArray.length > 0 || vm.fileNoExist || !vm.prodCheckStatus
  } else {
    // prodファイル
    hasErrorMessages = vm.errorMessagesArray.length > 0 || vm.fileNoExist
  }

  if (hasErrorMessages) {
    let errorMessages = commonUtil.objectArraySort(vm.errorMessagesArray, 'seq', 'error')

    for (let pos=0; pos < errorMessages.length; pos++) {
      if (pos > 0 && errorMessages[pos].substring(0, 1) === constants.LEFT_SQUARE_BRACKET) {
        errorMessages.splice(pos, 0, '')
        pos++
      }
    }

    if (fileName === constants.CHECK_FILE_NAME[0]) {
    // pp conf

      let leftErrorMessages = ''
      if (vm.errorMessagesArray[1].error.indexOf(constants.CHECK_FILE_NAME[1]) != 0) {
        leftErrorMessages = errorMessages.join(constants.LINE_BREAK)
      }
      vm.allErrorMessages = [{
        leftError: leftErrorMessages,
        rightError: vm.allErrorMessages[0].rightError
      }]
    } else {
    // prod conf

      let rightErrorMessages = ''
      if (vm.errorMessagesArray[1].error.indexOf(constants.CHECK_FILE_NAME[0]) != 0) {
        rightErrorMessages = errorMessages.join(constants.LINE_BREAK)
      }

      vm.allErrorMessages = [{
        leftError: vm.allErrorMessages[0].leftError,
        rightError: rightErrorMessages
      }]
    }

    // エラーメッセージ表示
    vm.styleType = 'display: block'

    Vue.$log.info('-----------------commonUtil.showErrorMessages end---------------------')

    return true
  } else {
    // 過去に表示されたエラー情報を消去する
    vm.allErrorMessages = [{
      leftError: '',
      rightError: ''
    }]

    // エラーメッセージ非表示
    vm.styleType = 'display: none'
    Vue.$log.info('[commonUtil.showErrorMessages]--------display: none----------')

    if (fileName === constants.CHECK_FILE_NAME[0]) {
      // ppファイル
      vm.ppCheckStatus = true
    } else {
      // prodファイル
      vm.prodCheckStatus = true
    }

    // メッセージプロンプト
    if (displayMessage) {
      vm.$message({
        dangerouslyUseHTMLString: true,
        message: messages.CHECK_JSON_STATUS_SUCCESS.replace('[0]', fileName),
        type: 'info',
        duration: constants.DURATION
      })
    }

    Vue.$log.info('-----------------commonUtil.showErrorMessages end---------------------')

    return false
  }
}

// サイズオーバー警告情報を設定する
commonUtil.setWarningMessages = function(editorContent, model, type='copy') {
  // サイズオーバー警告情報を取得する
  let {sizeOverValues, sizeOverValuesScientific}
    = this.showWarningMessages(constants.CHECK_FILE_NAME[0], editorContent, model)

  if (typeof(sizeOverValues) != 'undefined' && sizeOverValues.length > 0
    || typeof(sizeOverValuesScientific) != 'undefined' && sizeOverValuesScientific.length > 0) {

    // サイズオーバー警告情報を表示する
    vm.isShowSizeOver = true

    let sizeOverValuesString = ''
    if (typeof(sizeOverValues) != 'undefined' && sizeOverValues.length > 0) {
      sizeOverValuesString = JSON.stringify(sizeOverValues)
      sizeOverValuesString = sizeOverValuesString.replace(/\[|]/g, '')

      if (typeof(sizeOverValuesScientific) != 'undefined' && sizeOverValuesScientific.length > 0) {
        sizeOverValuesString = `${sizeOverValuesString}${constants.LINE_BREAK}${sizeOverValuesScientific.join(constants.LINE_BREAK)}`
      }
    } else if (typeof(sizeOverValuesScientific) != 'undefined' && sizeOverValuesScientific.length > 0) {
      sizeOverValuesString = `${sizeOverValuesString}${sizeOverValuesScientific.join(constants.LINE_BREAK)}`
    }

    let warnMessage = messages.SIZE_OVER_WARNING_MESSAGE.replace('[0]', constants.SIZE_OVER)
    if (type == 'update') {
      warnMessage = messages.SIZE_OVER_WARNING_MESSAGE_UPDATE.replace('[0]', constants.SIZE_OVER)
    }

    vm.sizeOverWarning
      = `${warnMessage}${constants.LINE_BREAK}${constants.LINE_BREAK}${sizeOverValuesString}`
  }
}

// サイズオーバー警告情報を表示する
commonUtil.showWarningMessages = function(fileName, jsonString, leftModel) {
  let sizeOverValues = new Array()
  let sizeOverValuesScientific = new Array()

  // 1.JSON形式チェック
  let json = ''
  try {
    json = JSON.parse(jsonString)
  } catch (error) {
    return {sizeOverValues, sizeOverValuesScientific}
  }

  let testEntryJson
  // test_entryオブジェクト存在チェック
  if (constants.TEST_ENTRY_FATHER_NODE in json && constants.PP_CHECK_OBJECTS[1] in json[constants.TEST_ENTRY_FATHER_NODE]) {
    // ppファイルのチェック: チェック対象はtest_entry
    testEntryJson = json[constants.TEST_ENTRY_FATHER_NODE][constants.PP_CHECK_OBJECTS[1]]
  } else {
    testEntryJson = []
  }

  for (let i = 0; i < testEntryJson.length; i++) {
    // pp向けconfigurations.jsonのtest_entry配下エントリ（「"ver": "MMM8M0JP0905202", "name": "5202_6400_s5202"」以降）
    const versionName = testEntryJson[i][constants.ITEMS_IN_MAPS[0]]
    if (typeof(versionName) != 'undefined') {
      const ver = versionName.slice(-5)

      // name項目
      let verName = testEntryJson[i][constants.ITEMS_IN_MAPS[2]]
      if (typeof(verName) == 'undefined') {
        verName = ''
      }

      if (ver === constants.TEST_ENTRY_BASE_VERSION && verName != constants.TEST_ENTRY_BASE_VERSION_NAME
        || ver < constants.TEST_ENTRY_BASE_VERSION) {
        continue
      }

      // size項目値
      const sizeValue = testEntryJson[i][constants.ITEMS_IN_TEST_ENTRY_AND_DIFFS[6]]

      // size項目チェック:  30000000以下であること
      if (typeof(sizeValue) == 'undefined' || !Number.isInteger(sizeValue) || sizeValue < constants.SIZE_OVER) {
        continue
      } else if (commonUtil.isScientificNotation(sizeValue)) {
      // size項目値は指数表記の場合

        // マッチング行
        const testEntryLineMatch = leftModel.findMatches(`"${constants.PP_CHECK_OBJECTS[1]}"`)
        let testEntryStartLineNumber
        if (testEntryLineMatch.length > 0) {
          const testEntryLineMatchRange = testEntryLineMatch[0].range
          testEntryStartLineNumber = testEntryLineMatchRange.startLineNumber
        }

        // jsonファイル内のmapsオブジェクトの終わりの]をマッチ
        const startPos = {lineNumber: testEntryStartLineNumber, column: 1}
        const isRegexp = false
        const bracketLineMatch = leftModel.findNextMatch(constants.RIGHT_SQUARE_BRACKET, startPos, isRegexp, false, null, false)
        const bracketLineMatchRange = bracketLineMatch.range
        const testEntryEndLineNumber = bracketLineMatchRange.startLineNumber

        let mutiLineContent = ''
        let mutiLineContentList = new Array()
        for (let j = testEntryStartLineNumber + 1; j < testEntryEndLineNumber; j++) {
          // 行のコンテンツを取得する
          let lineContent = leftModel.getLineContent(j)

          // 1つのバージョンに複数行が含まれる場合
          if (lineContent.indexOf(constants.LEFT_CURLY_BRACKET) >= 0
            && lineContent.indexOf(constants.RIGHT_CURLY_BRACKET) >= 0) {

            // 文字列の先頭と末尾のスペースを取り除く
            let lineContentRtrim = lineContent.replace(/^\s*|\s*$/g, '')
            if (lineContentRtrim.substr(-1) == constants.COMMA) {
              lineContentRtrim = lineContentRtrim.substring(0, lineContentRtrim.length - 1)
            }

            const lineJson = JSON.parse(lineContentRtrim)
            const lineVer = lineJson[constants.ITEMS_IN_MAPS[0]]
            const lineVersionName = lineJson[constants.ITEMS_IN_MAPS[2]]

            // ver項目が存在しないまたはバージョンは同じではない
            if (typeof(lineVer) == 'undefined' || versionName != lineVer) {
              continue
            }

            // ver項目が存在すること
            const lineVersion = lineVer.slice(-5)

            //「"ver": "MMM8M0JP0905202", "name": "5202_6400_s5202"」から
            if (lineVersion < constants.TEST_ENTRY_BASE_VERSION
              || lineVersion === constants.TEST_ENTRY_BASE_VERSION && lineVersionName != constants.TEST_ENTRY_BASE_VERSION_NAME) {
              continue
            }

            // size項目値
            const sizeValue = lineJson[constants.ITEMS_IN_TEST_ENTRY_AND_DIFFS[6]]

            // size項目チェック:  30000000以下であること
            if (typeof(sizeValue) == 'undefined' || sizeValue < constants.SIZE_OVER) {
              continue
            } else {
              sizeOverValuesScientific.push(lineContentRtrim)
            }
          } else {
            mutiLineContentList.push(j)
            mutiLineContent = `${mutiLineContent}${lineContent}`
          }

          // 複数行のデータをすべて取り出しているかどうかを判断する
          if (mutiLineContent.indexOf(constants.LEFT_CURLY_BRACKET) >= 0
            && mutiLineContent.indexOf(constants.RIGHT_CURLY_BRACKET) >= 0) {
            lineContent = mutiLineContent

            // 文字列の先頭と末尾のスペースを取り除く
            let lineContentRtrim = lineContent.replace(/^\s*|\s*$/g, '')
            if (lineContentRtrim.substr(-1) == constants.COMMA) {
              lineContentRtrim = lineContentRtrim.substring(0, lineContentRtrim.length - 1)
            }

            const lineJson = JSON.parse(lineContentRtrim)
            const lineVer = lineJson[constants.ITEMS_IN_MAPS[0]]
            const lineVersionName = lineJson[constants.ITEMS_IN_MAPS[2]]

            // ver項目が存在しないまたはバージョンは同じではない
            if (typeof(lineVer) == 'undefined' || versionName != lineVer) {
              continue
            }
            // ver項目が存在すること
            const lineVersion = lineVer.slice(-5)

            //「"ver": "MMM8M0JP0905202", "name": "5202_6400_s5202"」から
            if (lineVersion < constants.TEST_ENTRY_BASE_VERSION
              || lineVersion === constants.TEST_ENTRY_BASE_VERSION && lineVersionName != constants.TEST_ENTRY_BASE_VERSION_NAME) {
              continue
            }

            // size項目値
            const sizeValue = lineJson[constants.ITEMS_IN_TEST_ENTRY_AND_DIFFS[6]]

            // size項目チェック:  30000000以下であること
            if (typeof(sizeValue) == 'undefined' || sizeValue < constants.SIZE_OVER) {
              continue
            } else {
              sizeOverValuesScientific.push(lineContentRtrim)
            }

            mutiLineContent = ''
            mutiLineContentList = new Array()
          }
        }
      } else {
      // size項目値は指数表記でない場合

        sizeOverValues.push(testEntryJson[i])
      }
    } else {
    // ver項目がない場合、以下略
    }
  }

  return {sizeOverValues, sizeOverValuesScientific}
}

// ファイルエラー情報を設定する
commonUtil.showFileExistErrorMessages = function(fileName) {
  Vue.$log.info('-----------------commonUtil.showFileExistErrorMessages start---------------------')

  // 初期化
  vm.errorMessagesArray = []
  const fileInitSeq = constants.ERROR_MESSAGES_SEQ_FOR_ORDER['file']
  vm.seqs['file'] = fileInitSeq
  vm.fileNoExist = true

  // ファイルエラー情報
  const fileExistError = messages.CONF_FILE_NOT_EXIST_ERROR.replace('[0]', fileName)

  const result = vm.errorMessagesArray.find((errorRow) => errorRow.error === constants.CHECK_TYPE[7])
  if (typeof(result) == 'undefined') {
    vm.errorMessagesArray.push({'seq': vm.seqs['file'], 'error': constants.CHECK_TYPE[7]})
    vm.seqs['file'] += vm.stepSize
    vm.errorMessagesArray.push({'seq': vm.seqs['file'], 'error': fileExistError})
    vm.seqs['file'] += vm.stepSize
  } else {
    vm.errorMessagesArray.push({'seq': vm.seqs['file'], 'error': fileExistError})
    vm.seqs['file'] += vm.stepSize
  }

  if (vm.errorMessagesArray.length > 0) {
    // エラー情報の並べ替え
    let errorMessages = commonUtil.objectArraySort(vm.errorMessagesArray, 'seq', 'error')

    if (fileName === constants.CHECK_FILE_NAME[0]) {
    // pp conf
      vm.allErrorMessages = [{
        leftError: errorMessages.join(constants.LINE_BREAK),
        rightError: vm.allErrorMessages[0].rightError,
      }]
    } else {
    // prod conf
      vm.allErrorMessages = [{
        leftError: vm.allErrorMessages[0].leftError,
        rightError: errorMessages.join(constants.LINE_BREAK)
      }]
    }
  }

  vm.styleType = 'display: block'

  if (vm.errorMessagesArray.length > 0) {
    const prodFileExistError = messages.CONF_FILE_NOT_EXIST_ERROR.replace('[0]', constants.CHECK_FILE_NAME[1])

    // prodファイルが存在しない場合、ppファイルのチェックにエラーがあった
    vm.errorMessagesArray = commonUtil.arrRemoveJson(vm.errorMessagesArray, 'error', constants.CHECK_TYPE[7])
    vm.errorMessagesArray = commonUtil.arrRemoveJson(vm.errorMessagesArray, 'error', prodFileExistError)
    vm.seqs['file'] = fileInitSeq
  }

  Vue.$log.info('-----------------commonUtil.showFileExistErrorMessages end---------------------')
}

// オブジェクトの配列内のあるオブジェクトを削除する: 
//  arr: オブジェクトの配列
//  attr: 属性名
//  value: 属性値、唯一値
commonUtil.arrRemoveJson = function(arr, attr, value) {
  if (!arr || arr.length == 0) {
    return ''
  }

  let newArr = arr.filter(function (item) {
    return item[attr] != value
  })

  return newArr
}

// 数値が指数表記かどうかを判断する
commonUtil.isScientificNotation = function(num) {
  let isScientificNumber = false

  if (num == null || num == '') {
    return isScientificNumber
  } else {
    isScientificNumber = /^(\d+(?:\.\d+)?)(e)([-+]?\d+)$/.test(num)
  }

  return isScientificNumber
}

export default commonUtil