export const constants = {
  // 共通
  // フラッシュメッセージ表示の時間の長さ(ミリ秒)
  DURATION: 1000,
  // 左中括弧
  LEFT_CURLY_BRACKET: '{',
  // 右中括弧
  RIGHT_CURLY_BRACKET: '}',
  // 左大括弧
  LEFT_SQUARE_BRACKET: '[',
  // 右大括弧
  RIGHT_SQUARE_BRACKET: ']',
  // 改行记号
  LINE_BREAK: '\n',
  // カンマ
  COMMA: ',',
  // 読点
  PAUSE_MARK: '、',
  // httpリソースのメディア種別content-type
  CONTENT_TYPE: 'application/json',

  // prodツール
  // -----------------------------gitlab api----------------------------------------
  // gitlabアクセストークン
  GITLAB_ACCESS_TOKEN: '6QpReKFs_U8U2wqQoVxY',
  // gitlabリポジトリURL
  REPOSITORY_URL: 'https://gitlab.micdev.jp/m7g_server/map_update_api',
  // pp向け設定ファイルパス
  PP_CONF_PATH: 'src/s3/pp/configurations.json',
  // prod向け設定ファイルパス
  PROD_CONF_PATH: 'src/s3/prod/configurations.json',
  // gitlabリポジトリ取得apiのURL
  PROJECT_API_URL: 'https://gitlab.micdev.jp/api/v4/projects/?search=[0]',
  // gitlabリポジトリブランチ取得apiのURL
  BRANCH_API_URL: 'https://gitlab.micdev.jp/api/v4/projects/[0]/repository/branches?search=[1]&per_page=[2]',
  // gitlabリポジトリファイル取得apiのURL
  FILE_API_URL: 'https://gitlab.micdev.jp/api/v4/projects/[0]/repository/files/[1]?ref=[2]',
  // gitlabリポジトリファイルプッシュapiのURL
  PUSH_FILE_API_URL: 'https://gitlab.micdev.jp/api/v4/projects/[0]/repository/commits',
  // ブランチ選択リストにおけるブランチ名の先頭の文字列
  SEARCH_BRANCH_NAME_PREFIX: 'release/',
  // pp向け設定ファイル更新ツールのブランチ名
  BRANCH_NAME: 'develop',
  // 1ページの表示数(BRANCH_API_URLで使う)
  DISPLAYED_NUMBERS_PER_PAGE: 100,
  // -----------------------------gitlab api----------------------------------------

  // -----------------------------チェック-------------------------------------------
  // チェックファイル
  CHECK_FILE_NAME: ['pp', 'prod', 'dev'],
  // 比較のバージョン番号
  TEST_ENTRY_BASE_VERSION: '05202',
  // 比較のバージョン番号名
  TEST_ENTRY_BASE_VERSION_NAME: '5202_6400_s5202',
  // チェック種類
  CHECK_TYPE: ['[ver重複チェック]', '[ver順番チェック]', '[オブジェクト存在チェック]', '[json形式チェック]',
    '[json構造チェック]', '[各値チェック]', '[copy対象チェック]', '[設定ファイルチェック]', '[diffs配下データ件数チェック]', '[更新対象チェック]'],
  // test_entry配下の親ノード
  TEST_ENTRY_FATHER_NODE: '//diffsの説明',
  // pp confチェック対象
  PP_CHECK_OBJECTS: ['maps', 'test_entry', 'diffs'],
  // prod confチェック対象
  PROD_CHECK_OBJECTS: ['maps', 'diffs'],
  // maps配下チェック項目
  ITEMS_IN_MAPS: ['ver', 'release', 'name', 'date', 'time', 'diff'],
  // test_entry配下とdiffs配下チェック項目
  ITEMS_IN_TEST_ENTRY_AND_DIFFS: ['ver', 'release', 'name', 'date', 'time', 'upto', 'size'],
  // 各値チェックの正規表現
  RULES_IN_MAPS: [/^\d{2}Q\w{7}$/, /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}.[0-9]{3}Z$/],
  // エラー情報は順序付けの数値を表示する
  ERROR_MESSAGES_SEQ_FOR_ORDER: {
    'jsonFormat': 1,
    'objects': 2,
    'itemNames': 3,
    'itemValues': 4,
    'double': 5,
    'order': 6,
    'release': 7,
    'file': 8,
    'uniq': 9
  },
  // release日付有効期限
  RELEASE_PLUS_DAYS: 14,
  // 1日の時間変換のミリ秒数
  MILLISECOND_OF_ONE_DAY: 86400000,
  // サイズオーバー
  SIZE_OVER: 30000000,
  // コミットメッセージに入力できる最大文字数
  MAXIMUM_NUMBER_OF_CHARACTERS: 72,
  // pp confファイルのコピー対象データ行のハイライトカラー
  HIGHLIGHTED_COLORS: ['greenDecorator', 'darkRedDecorator'],
  // -----------------------------チェック-------------------------------------------

  // CIDツール
  INIT_TYPE: ['CID', 'DL'],
  CID_TYPE: ['binary', 'encoded'],
  // チェック種類
  CID_CHECK_TYPE: ['[編集項目チェック]'],
  // チェック対象
  CID_CHECK_OBJ: ['CidProrerty', 'LatestMapGuaranteeHistory'],
  // 各値チェックの正規表現
  CID_REGULAR_RULES: [
    /* 形式が「"半角数字2桁" + Q + "半角英数字7桁"」 例）21Q100000A */
    /^\d{2}Q\w{7}$/,
    /* 形式が「yyyy-MM-dd HH:mm:ss」 */
    /^[0-9]{4}-[0-9]{2}-[0-9]{2} [0-9]{2}:[0-9]{2}:[0-9]{2}$/,
    /* 半角数値、範囲は0と1のみ */
    /^(0|1)$/,
    /* ５桁の半角数値 */
    /^[0-9]{5}$/,
    /* 半角数値 */
    /^[0-9]+$/
  ],
  CID_PRORERTY: {
    'update_time': true,
    'all_update_term': true,
    'create_time': true,
    'initial_map_ver': true,
    'ozk_map_ver': true,
    'management_info': true,
    'service_term': true,
    'start_date': true,
    'run100k': true,
    'hash_salt': true,
    'diff_update_term': true,
    'dealer': true
  },
  LATEST_MAP_GUARANTEE_HISTORY: {
    'create_time': true,
    'fs_applied': true,
    'hash_salt': true,
    'latest_map_ver': true,
  },
  // 背景色
  bgColor: ['green', 'yellow'],
  // 1時間の時間変換のミリ秒数
  MILLISECOND_OF_ONE_HOUR: 3600000,
  // 対象CIDに入力できる最大文字数
  CID_MAXIMUM_NUMBER_OF_CHARACTERS: 128,
  // 開発環境のテーブル
  DEV_DB_TABLE_NAMES: [
    'm7g-map-update-prerequisite-dev-CidProperty-IQM2J09X8ARI',
    'm7g-map-update-dev-LatestMapGuaranteeHistory'
  ],
  // 検証環境のテーブル
  PP_DB_TABLE_NAMES: [
    'm7g-map-update-prerequisite-pp-CidProperty-NNOAKEJ9JF6M',
    'm7g-map-update-pp-LatestMapGuaranteeHistory'
  ]
}