<template>
  <el-row>
    <el-col :span="24" :lg="24">
      <el-form :model="query" ref="query" :show-message="true" :hide-required-asterisk="true">
        <!-- 対象環境 -->
        <el-row :gutter="10" class="mt">
          <el-col :span="24">
            <el-form-item>
              <el-col :span="6" class="center-text">
                <div class="label-style">
                  対象環境
                </div>
              </el-col>
              <el-col :span="18">
                <el-radio-group v-model="query.environment" :disabled="disabled" @change="changeValue">
                  <el-radio label="dev">「TRY/DEV」（開発環境）</el-radio>
                  <el-radio label="pp">「TEST/PP」（検証環境）</el-radio>
                </el-radio-group>
              </el-col>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- 対象CID -->
        <el-row :gutter="10" class="mt">
          <el-col :span="24">
            <el-form-item prop="cid" ref="cidRef">
              <el-col :span="6" class="center-text">
                <span slot="label"></span>
                <div class="label-cid-style">
                  対象CID
                </div>
              </el-col>

              <el-col :span="3">
                <el-radio-group v-model="query.cidType" :disabled="disabled" @change="changeValue">
                  <div>
                    <el-radio label="binary">バイナリ</el-radio>
                  </div>
                  <div class="radio-top">
                    <el-radio label="encoded">エンコード済</el-radio>
                  </div>
                </el-radio-group>
              </el-col>

              <el-col :span="8">
                <div class="input-binary-top">
                  <el-input v-model="cidBinary" size="large" :BgColor="cidBgColor.binary" :validate-event="false" :readonly="readonlyBinary"></el-input>
                </div>
              </el-col>
              <el-col :span="1">
                <div class="input-binary-top">
                  <el-button size="small" type="primary" @click="search('query')" style="width:185%;">取得</el-button>
                </div>
              </el-col>

              <el-col :span="8">
                <div class="input-encoded-top">
                  <el-input v-model="query.cid" size="large" :BgColor="cidBgColor.encoded" :validate-event="false" :readonly="readonlyEncoded"></el-input>
                </div>
              </el-col>
              <el-col :span="1">
                <div class="input-encoded-top">
                  <el-button size="small" type="info" @click="clear('query')">クリア</el-button>
                </div>
              </el-col>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <el-row gutter="10">
          <el-col :span="23" :offset="1"> -->
        <el-row :gutter="10" class="mt">
          <el-col :span="24">
            <el-form-item prop="cid" ref="cidRef">
              <el-col :span="3" class="center-text">
                <span slot="label"></span>
                  <el-button size="small" type="warning" :disabled="btnInitDisable" @click="init('query')" >初期化</el-button>
              </el-col>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <!-- 編集項目チェックエラー -->
      <div id="div-error-messages">
        <!-- チェックエラー情報 -->
        <el-table :data="allErrorMessages" :show-header="false" empty-text=" " :style="styleType">
          <el-table-column
            prop="leftError"
            min-width="100%"
            label="">
          </el-table-column>
        </el-table>
      </div>
      <!-- CidProrerty情報 -->
      <div v-show="isShowCidProrerty">
        <el-row :gutter="10" class="mt-cid-info">
          <el-col :span="6">
            <div class="title-style ">
              <input type="checkbox" id="CidProrerty" value="CidProrerty" v-model="checkedTable" />
                <label for="CidProrerty" style="color:black">■CidProrerty</label>
            </div>
          </el-col>
        </el-row>

        <el-form :model="cidProrertyInfo">
          <el-row :gutter="10" class="mt">
            <el-col :span="24">
              <el-form-item>
                <el-col :span="6" class="center-text">
                  <div class="result-label-style">
                    cid<br>SDカード識別ID
                  </div>
                </el-col>
                <el-col :span="15">
                  <el-input v-model="cidProrertyInfo.cid" size="large" readonly></el-input>
                </el-col>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="10" class="mt">
            <el-col :span="24">
              <el-form-item>
                <el-col :span="6" class="center-text">
                  <div class="result-label-style">
                    all_update_term<br>全地図更新期限 (v2.1)
                  </div>
                </el-col>
                <el-col :span="15">
                  <el-input v-model="cidProrertyInfo.all_update_term" size="large" :BgColor="cidProrertyAttr.all_update_term.bgColor" :readonly="cidProrertyAttr.all_update_term.readonly ? false:'readonly'"></el-input>
                </el-col>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="10" class="mt">
            <el-col :span="24">
              <el-form-item>
                <el-col :span="6" class="center-text">
                  <div class="result-label-style">
                    create_time<br>登録日時
                  </div>
                </el-col>
                <el-col :span="15">
                  <el-input v-model="cidProrertyInfo.create_time" size="large" :BgColor="cidProrertyAttr.create_time.bgColor" :readonly="cidProrertyAttr.create_time.readonly ? false:'readonly'"></el-input>
                </el-col>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="10" class="mt">
            <el-col :span="24">
              <el-form-item>
                <el-col :span="6" class="center-text">
                  <div class="result-label-style">
                    diff_update_term<br>差分地図地図更新期限 (v2.1)
                  </div>
                </el-col>
                <el-col :span="15">
                  <el-input v-model="cidProrertyInfo.diff_update_term" size="large" :BgColor="cidProrertyAttr.diff_update_term.bgColor" :readonly="cidProrertyAttr.diff_update_term.readonly ? false:'readonly'"></el-input>
                </el-col>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="10" class="mt">
            <el-col :span="24">
              <el-form-item>
                <el-col :span="6" class="center-text">
                  <div class="result-label-style">
                    hash_salt<br>ハッシュソルト
                  </div>
                </el-col>
                <el-col :span="15">
                  <el-input v-model="cidProrertyInfo.hash_salt" size="large" :BgColor="cidProrertyAttr.hash_salt.bgColor" :readonly="cidProrertyAttr.hash_salt.readonly ? false:'readonly'"></el-input>
                </el-col>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="10" class="mt">
            <el-col :span="24">
              <el-form-item>
                <el-col :span="6" class="center-text">
                  <div class="result-label-style">
                    initial_map_ver<br>初期マップバージョン
                  </div>
                </el-col>
                <el-col :span="15">
                  <el-input v-model="cidProrertyInfo.initial_map_ver" size="large" :BgColor="cidProrertyAttr.initial_map_ver.bgColor" :readonly="cidProrertyAttr.initial_map_ver.readonly ? false:'readonly'"></el-input>
                </el-col>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="10" class="mt">
            <el-col :span="24">
              <el-form-item>
                <el-col :span="6" class="center-text">
                  <div class="result-label-style">
                    management_info<br>管理情報
                  </div>
                </el-col>
                <el-col :span="15">
                  <el-input type="textarea" v-model="cidProrertyInfo.management_info" class="textarea-style" size="large" :rows="2"
                    :BgColor="cidProrertyAttr.management_info.bgColor" :readonly="cidProrertyAttr.management_info.readonly ? false:'readonly'"></el-input>
                </el-col>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="10" class="mt">
            <el-col :span="24">
              <el-form-item>
                <el-col :span="6" class="center-text">
                  <div class="result-label-style">
                    run100k<br>VINロックフラグ
                  </div>
                </el-col>
                <el-col :span="15">
                  <el-input v-model="cidProrertyInfo.run100k" size="large" :BgColor="cidProrertyAttr.run100k.bgColor" :readonly="cidProrertyAttr.run100k.readonly ? false:'readonly'"></el-input>
                </el-col>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="10" class="mt">
            <el-col :span="24">
              <el-form-item>
                <el-col :span="6" class="center-text">
                  <div class="result-label-style">
                    start_date<br>利用開始日 (v2.1)
                  </div>
                </el-col>
                <el-col :span="15">
                  <el-input v-model="cidProrertyInfo.start_date" size="large" :BgColor="cidProrertyAttr.start_date.bgColor" :readonly="cidProrertyAttr.start_date.readonly ? false:'readonly'"></el-input>
                </el-col>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="10" class="mt">
            <el-col :span="24">
              <el-form-item>
                <el-col :span="6" class="center-text">
                  <div class="result-label-style">
                    update_time<br>更新日時
                  </div>
                </el-col>
                <el-col :span="15">
                  <el-input v-model="cidProrertyInfo.update_time" size="large" :BgColor="cidProrertyAttr.update_time.bgColor"  :readonly="cidProrertyAttr.update_time.readonly ? false:'readonly'"></el-input>
                </el-col>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <!-- LatestMapGuaranteeHistory情報 -->
      <div v-show="isShowMapHistory" class="div-style">
        <el-row :gutter="10" class="mt-cid-info">
          <el-col :span="6">
            <div class="title-style ">
              <input type="checkbox" id="LatestMapGuaranteeHistory" value="LatestMapGuaranteeHistory" v-model="checkedTable" checked="true"/>
                <label for="LatestMapGuaranteeHistory" style="color:black">■LatestMapGuaranteeHistory</label>
            </div>
          </el-col>
        </el-row>

        <el-form :model="mapHistoryInfo">
          <el-row :gutter="10" class="mt">
            <el-col :span="24">
              <el-form-item>
                <el-col :span="6" class="center-text">
                  <div class="result-label-style">
                    cid<br>SDカード識別ID
                  </div>
                </el-col>
                <el-col :span="15">
                  <el-input v-model="mapHistoryInfo.cid" size="large" readonly></el-input>
                </el-col>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="10" class="mt">
            <el-col :span="24">
              <el-form-item>
                <el-col :span="6" class="center-text">
                  <div class="result-label-style">
                    create_time<br>登録日時
                  </div>
                </el-col>
                <el-col :span="15">
                  <el-input v-model="mapHistoryInfo.create_time" size="large" :BgColor="mapHistoryAttr.create_time.bgColor" :readonly="mapHistoryAttr.create_time.readonly ? false:'readonly'"></el-input>
                </el-col>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="10" class="mt">
            <el-col :span="24">
              <el-form-item>
                <el-col :span="6" class="center-text">
                  <div class="result-label-style">
                    fs_applied<br>SD復旧フラグ
                  </div>
                </el-col>
                <el-col :span="15">
                  <el-input v-model="mapHistoryInfo.fs_applied" size="large" :BgColor="mapHistoryAttr.fs_applied.bgColor" :readonly="mapHistoryAttr.fs_applied.readonly ? false:'readonly'"></el-input>
                </el-col>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="10" class="mt">
            <el-col :span="24">
              <el-form-item>
                <el-col :span="6" class="center-text">
                  <div class="result-label-style">
                    hash_salt<br>ハッシュソルト
                  </div>
                </el-col>
                <el-col :span="15">
                  <el-input v-model="mapHistoryInfo.hash_salt" size="large" :BgColor="mapHistoryAttr.hash_salt.bgColor" :readonly="mapHistoryAttr.hash_salt.readonly ? false:'readonly'"></el-input>
                </el-col>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="10" class="mt">
            <el-col :span="24">
              <el-form-item>
                <el-col :span="6" class="center-text">
                  <div class="result-label-style">
                    latest_map_ver<br>実行バージョン
                  </div>
                </el-col>
                <el-col :span="15">
                  <el-input v-model="mapHistoryInfo.latest_map_ver" size="large" :BgColor="mapHistoryAttr.latest_map_ver.bgColor" :readonly="mapHistoryAttr.latest_map_ver.readonly ? false:'readonly'"></el-input>
                </el-col>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import Vue from "vue"
import axios from 'axios'
import { messages } from '@/common/messages'
import { constants } from '@/common/constants'

export default {
  data () {
    return {
      // CidProrerty情報が表示されない
      isShowCidProrerty: false,
      // LatestMapGuaranteeHistory情報が表示されない
      isShowMapHistory: false,
      // バイナリは書き込み可能
      readonlyBinary: false,
      // エンコード済は書き込み不可
      readonlyEncoded: true,
      // 更新ボタン：非活性
      btnInitDisable: true,
      // 対象環境と対象CIDラジオボタンが活性であること
      disabled: false,
      // 対象CID背景色
      cidBgColor: {
        binary: '',
        encoded: 'gray'
      },
      // 検索条件
      query: {
        environment: constants.CHECK_FILE_NAME[2],
        cidType: constants.CID_TYPE[0],
        cid: ''
      },
      cidBinary: '',
      // CidProrerty情報
      cidProrertyInfo: {
        cid: '',
        all_update_term: '',
        create_time: '',
        diff_update_term: '',
        hash_salt: '',
        initial_map_ver: '',
        management_info: '',
        run100k: '',
        start_date: '',
        update_time: ''
      },
      // LatestMapGuaranteeHistory情報
      mapHistoryInfo: {
        cid: '',
        create_time: '',
        fs_applied: '',
        hash_salt: '',
        latest_map_ver: ''
      },
      // 初期化: readonly属性と背景色
      cidProrertyAttr: {},
      mapHistoryAttr: {},
      // CID情報取得API呼び出しで返される結果
      responseData: {
        cidProperty: {},
        latestMapGuaranteeHistory: {},
      },
      // エラー
      allErrorMessages: [],
      // エラー: css style
      styleType: 'display: none',
      checkedTable: []
    }
  },
  watch: {
    //初期化するテーブル名
    checkedTable(newValue, oldValue) {
      if (newValue.length > 0) {
        this.btnInitDisable = false
      } else {
        this.btnInitDisable = true
      }
    }
  },
  created () {
    Vue.$log.info('Update.vue')

    // 編集の制限、背景色設定
    this.propertyInit()
  },
  methods: {
    // 編集の制限、背景色設定
    propertyInit() {
      // CidProrerty詳細情報：編集の制限、背景色
      for (let editItem in constants.CID_PRORERTY) {
        let itemProperty = {}
        //各項目をReonOnlyにする
        itemProperty['readonly'] = false
        if (constants.CID_PRORERTY[editItem]) {
          itemProperty['bgColor'] = constants.bgColor[0]
        } else {
          itemProperty['bgColor'] = ''
        }
        this.cidProrertyAttr[editItem] = itemProperty
      }

      // LatestMapGuaranteeHistory詳細情報：編集の制限、背景色
      for (let editItem in constants.LATEST_MAP_GUARANTEE_HISTORY) {
        let itemProperty = {}
        //各項目をReonOnlyにする
        itemProperty['readonly'] = false
        if (constants.LATEST_MAP_GUARANTEE_HISTORY[editItem]) {
          itemProperty['bgColor'] = constants.bgColor[0]
        } else {
          itemProperty['bgColor'] = ''
        }
        this.mapHistoryAttr[editItem] = itemProperty
      }

    },
    // 使用して文字列が16進数かどうかを判別
    isHexStr(hexStr) {
      const hexRegex = /^[A-Fa-f0-9]+$/

      return hexRegex.test(hexStr)
    },
    // 使用して文字列がBase64かどうかを判別
    isBase64Str(base64Str) {
      const base64Regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/

      return base64Regex.test(base64Str)
    },
    // 16進文字列からBase64符号化された文字列に変換する
    hexTobase64String(hexStr) {
      let hexStrNoSpaces = hexStr.replaceAll(' ', '')

      // Base64符号化された文字列をチェック
      const isHexFlag = this.isHexStr(hexStrNoSpaces)
      if (isHexFlag) {
        try {
          const base64Str = Buffer.from(hexStrNoSpaces, 'hex').toString('base64')

          // 画面表示
          this.query.cid = base64Str

          // エンコード成功
          return true
        } catch (error) {
          Vue.$log.info('-----------------エンコード失敗の場合---------------------')
          Vue.$log.info(error)
        }
      }

      // エンコード失敗
      return false
    },
    // 対象環境と対象CIDラジオボタンを切り替え
    changeValue() {
      // CidProperty情報が表示されない
      this.isShowCidProrerty = false

      // LatestMapGuaranteeHistory情報が表示されない
      this.isShowMapHistory = false

      // エンコード済が空欄にする
      this.query.cid = ''
      // バイナリが空欄にする
      this.cidBinary = ''

      // エラーメッセージが表示されない
      this.clearCheckError()

      // 更新ボタン：非活性
      this.btnInitDisable = true

      if (this.query.cidType == constants.CID_TYPE[0]) {
        // バイナリは書き込み可能
        this.readonlyBinary = false
        // エンコード済は書き込み不可
        this.readonlyEncoded = true

        // 背景色を設定する
        this.cidBgColor.binary = ''
        this.cidBgColor.encoded = 'gray'
      } else {
        // バイナリは書き込み不可
        this.readonlyBinary = true
        // エンコード済は書き込み可能
        this.readonlyEncoded = false

        // 背景色を設定する
        this.cidBgColor.binary = 'gray'
        this.cidBgColor.encoded = ''
      }
    },
    // 取得ボタン押下時
    search() {
      // エラーメッセージが表示されない
      this.clearCheckError()
      // チェックボックスをリセットする
      this.checkedTable = []
      // 更新ボタン：非活性
      this.btnInitDisable = true

      // CID文字数が128文字を超える場合
      if (this.query.cid.length > constants.CID_MAXIMUM_NUMBER_OF_CHARACTERS
        || this.cidBinary.length > constants.CID_MAXIMUM_NUMBER_OF_CHARACTERS) {
          // エラー
          this.$alert(messages.CID_VALUE_LENGTH_OVER_ERROR, '提示', {
            confirmButtonText: 'OK',
            type: 'error'
          }).then(() => {
          }).catch(() => {
          })

          return
      }

      // 入力パラメータチェック
      if (this.query.cidType == constants.CID_TYPE[0]) {
        // エンコード済が空欄にする
        this.query.cid = ''

        // バイナリが空欄の場合
        if (this.cidBinary.length === 0) {
          // エラー
          this.$alert(messages.CID_VALUE_BLANK_ERROR, '提示', {
            confirmButtonText: 'OK',
            type: 'error'
          }).then(() => {
          }).catch(() => {
          })

          return
        }

        const isSuccess = this.hexTobase64String(this.cidBinary)
        if (!isSuccess) {
          // エラー
          this.$alert(messages.STRING_IS_NOT_HEX_ERROR, '提示', {
            confirmButtonText: 'OK',
            type: 'error'
          }).then(() => {
          }).catch(() => {
          })

          return
        }
      } else {
        // エンコード済が空欄の場合
        if (this.query.cid.length === 0) {
          // エラー
          this.$alert(messages.CID_VALUE_BLANK_ERROR, '提示', {
            confirmButtonText: 'OK',
            type: 'error'
          }).then(() => {
          }).catch(() => {
          })

          return
        }

        // Base64符号化された文字列をチェック
        const isBase64StrFlag = this.isBase64Str(this.query.cid)
        if (!isBase64StrFlag) {
          // エラー
          this.$alert(messages.STRING_IS_NOT_BASE64_ERROR, '提示', {
            confirmButtonText: 'OK',
            type: 'warning'
          }).then(() => {
          }).catch(() => {
          })

          return
        }
      }

      let querySearch = JSON.parse(JSON.stringify(this.query))
      // apiサービスコール
      axios.post(`${process.env.VUE_APP_BASE_URL}cid/search`, querySearch)
      .then((response) => {
        if (response.data.common.code === 'I10001') {
          // 成功の場合
          if (Object.keys(response.data.data).length === 0
            || Object.keys(response.data.data).length === 1 && Object.keys(response.data.data).indexOf('downloadDeadline') > -1) {
            // 検索条件に一致するデータが見つかりません
            this.$alert(messages.CID_SEARCH_NO_DATA_ERROR, '提示', {
              confirmButtonText: 'OK',
              type: 'error'
            }).then(() => {
              // this.clear()
            }).catch(() => {
            })
          } else {
            // 編集の制限、背景色設定
            this.propertyInit()

            if (typeof(response.data.data.cidProperty) != 'undefined') {
              // CidProperty情報を正常に表示する
              this.cidProrertyInfo = response.data.data.cidProperty

              // CidProperty情報が表示する
              this.isShowCidProrerty = true
              this.checkedTable.push('CidProrerty')

            } else {
              // CidProperty情報が表示されない
              this.isShowCidProrerty = false
            }

            if (typeof(response.data.data.latestMapGuaranteeHistory) != 'undefined') {
              // LatestMapGuaranteeHistory情報を正常に表示する
              this.mapHistoryInfo = response.data.data.latestMapGuaranteeHistory

              // LatestMapGuaranteeHistory情報が表示する
              this.isShowMapHistory = true
              this.checkedTable.push('LatestMapGuaranteeHistory')
            } else {
              // LatestMapGuaranteeHistory情報が表示されない
              this.isShowMapHistory = false
            }

            // responseデータ保存
            this.responseData = JSON.parse(JSON.stringify(response.data.data))

            // 対象環境と対象CIDラジオボタンが非活性であること
            this.disabled = true

            // バイナリは書き込み不可
            this.readonlyBinary = true,
            // エンコード済は書き込み不可
            this.readonlyEncoded = true
          }
        } else {
          // 失敗の場合
          this.$alert(`${response.data.common.message.join('<br/>')}(error code: ${response.data.common.code})`, '提示', {
            confirmButtonText: 'OK',
            dangerouslyUseHTMLString: true,
            type: 'error'
          }).then(() => {
          }).catch(() => {
          })
        }
      })
      .catch( (error) => {
        this.$alert(`${error.response.data.common.message.join('<br/>')}(error code: ${error.response.data.common.code})`, '提示', {
          confirmButtonText: 'OK',
          dangerouslyUseHTMLString: true,
          type: 'error'
        }).then(() => {
        }).catch(() => {
        })
      })
    },
    // クリアボタン押下時
    clear(query) {
      this.$confirm(messages.CLEAR_FOR_CONFIRM, '提示', {
        confirmButtonText: 'はい',
        cancelButtonText: 'いいえ',
        type: 'warning'
      }).then(() => {
        // CidProperty情報が表示されない
        this.isShowCidProrerty = false

        // LatestMapGuaranteeHistory情報が表示されない
        this.isShowMapHistory = false

        // バイナリは書き込み可能
        this.readonlyBinary = false
        // エンコード済は書き込み可能
        this.readonlyEncoded = true

        // 対象環境と対象CIDラジオボタンが活性であること
        this.disabled = false

        // 更新ボタン：非活性
        this.btnInitDisable = true

        // 背景色を設定する
        this.cidBgColor.binary = ''
        this.cidBgColor.encoded = 'gray'

        // 検索条件を設定する
        this.query = {
          environment: constants.CHECK_FILE_NAME[2],
          cidType: constants.CID_TYPE[0],
          cid: ''
        }
        this.cidBinary = ''

        // エラーメッセージが表示されない
        this.clearCheckError()

        // チェックボックスをリセットする
        this.checkedTable = []

        this.$message({
          type: 'info',
          message: messages.CLEAR_STATUS_SUCCESS,
          duration: constants.DURATION
        })

        this.$refs[query].resetFields()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: messages.CLEAR_STATUS_CANCEL,
          duration: constants.DURATION
        })
      })
    },
    // 初期化ボタン押下時
    init() {
      // エラーメッセージが表示されない
      this.clearCheckError()

      // テーブル名
      let dbTables = JSON.parse(JSON.stringify(constants.DEV_DB_TABLE_NAMES))
      if (this.query.environment == constants.CHECK_FILE_NAME[0]) {
        dbTables = JSON.parse(JSON.stringify(constants.PP_DB_TABLE_NAMES))
      }

      // 検索条件と更新後項目値
      let requestBody = {
        environment: this.query.environment,
        cid: this.query.cid,
        tables: this.checkedTable.toString()
      }

      // empty項を削除
      dbTables = dbTables.filter((d) => d)
      console.log("dbTables", dbTables)

      this.$confirm('<b>「'+requestBody.cid+'</b>」を'+messages.INIT_FOR_CONFIRM, '提示', {
        confirmButtonText: 'はい',
        cancelButtonText: 'いいえ',
        dangerouslyUseHTMLString: true,
        type: 'warning'
      }).then(() => {
        // apiサービスコール
        axios.delete(`${process.env.VUE_APP_BASE_URL}cid/init`, { data:requestBody } )
        .then( (response) => {
          console.log(this.checkedTable)
          if (response.data.common.code === 'I10001') {
            let deletedTables = []
            if (this.checkedTable.includes('CidProrerty')) {
              deletedTables.push(dbTables[0])
              var index = this.checkedTable.indexOf('CidProrerty') // Remove Selected Table
              if (index > -1) { //if found
                this.checkedTable.splice(index, 1)
                this.isShowCidProrerty = false
                // Reset
                Object.keys(this.cidProrertyInfo).forEach(key => {
                  this.cidProrertyInfo[key] = ''
                })
              }
            }
            if (this.checkedTable.includes('LatestMapGuaranteeHistory')) {
              deletedTables.push(dbTables[1])
              var index = this.checkedTable.indexOf('LatestMapGuaranteeHistory') // Remove Selected Table
              if (index > -1) { //if found
                this.checkedTable.splice(index, 1)
                this.isShowMapHistory = false
                // Reset
                Object.keys(this.mapHistoryInfo).forEach(key => {
                  this.mapHistoryInfo[key] = ''
                })
              }
            }
            const updateMessage = messages.DELETE_CID_SUCCESS.replace('[0]', deletedTables.join(constants.PAUSE_MARK))
            // 成功の場合
            this.$alert(updateMessage, '提示', {
              confirmButtonText: 'OK',
              type: 'success'
            }).then(() => {
            }).catch(() => {
            })
          } else {
            // 失敗の場合
            this.$alert(`${response.data.common.message.join('<br/>')}(error code: ${response.data.common.code})`, '提示', {
              confirmButtonText: 'OK',
              dangerouslyUseHTMLString: true,
              type: 'error'
            }).then(() => {
            }).catch(() => {
            })
          }
        })
        .catch((error) => {
          Vue.$log.info('-----------------DB更新処理失敗の場合---------------------')
          Vue.$log.info(error)

          this.$alert(`${error.response.data.common.message.join('<br/>')}(error code: ${error.response.data.common.code})`, '提示', {
            confirmButtonText: 'OK',
            dangerouslyUseHTMLString: true,
            type: 'error'
          }).then(() => {
          }).catch(() => {
          })
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: messages.INIT_STATUS_CANCEL,
          duration: constants.DURATION
        })
      })
    },
    clearCheckError() {
      this.allErrorMessages = [{
        leftError: '',
        rightError: ''
      }]

      // エラーメッセージが表示されない
      this.styleType = 'display: none'
    }
  }
}
</script>

<style>
#div-error-messages {
  margin: 0px 36px;
  width: 84.2%;
}
#div-error-messages .el-table .cell {
  white-space: pre-wrap;
  text-align : left;
  font-size: 12px;
  color:red;
  line-height: 1.5;
  padding-left: 0px;
}
.mt-cid-info {
  margin-top: 0px;
}
.radio-top {
  margin-top: 40px;
}
.input-binary-top {
  margin-top: -12px;
}
.input-encoded-top {
  margin-top: 12px;
}
.label-cid-style {
  max-width: 240px;
  min-width: 100px;
  color: white;
  margin: 0 auto;
  height: 80px;
  line-height: 5.5em;
  background-color: teal;
}
.btn-update-left {
  text-align: left;
}
.btn-update-center {
  text-align: center;
}
.div-style {
  margin-top: 40px;
}
.el-input__inner[BgColor="yellow"] {
  background-color: #ffff00;
}
.el-input__inner[BgColor="green"] {
  background-color: #e2efda;
}
.el-input__inner[BgColor="gray"] {
  background-color: #d9d9d9;
}
.el-textarea__inner[BgColor="yellow"] {
  background-color: #ffff00;
}
.el-textarea__inner[BgColor="green"] {
  background-color: #e2efda;
}
</style>